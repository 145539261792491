import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { adminGetAllPO, franchiseGetAllPO } from "../../actions/POActions";

const ViewPOAdmin = (role) => {
  const { POLoading, PO } = useSelector((state) => state.purchaseOrder);
  const { storesFranchise } = useSelector((state) => state.stores);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!PO.length > 0) {
      if (role.role === "franchiseowner") {
        //TODO create lambda for franchise POs
        // dispatch(franchiseGetAllPO(storesFranchise))
      } else {
        dispatch(adminGetAllPO());
      }
    }
  }, []);

  return (
    <div>
      {POLoading && (
        <LoadingScreen>
          <CircularProgress size={100} />
        </LoadingScreen>
      )}
      {PO.map((po) => (
        <h1 key={po.poNumber}>{po.poNumber}</h1>
      ))}
    </div>
  );
};

const LoadingScreen = styled.div`
  width: 100%;
  height: 50vh;
  background: rgba(256, 256, 256, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;


export default ViewPOAdmin;
