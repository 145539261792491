import { useState, useEffect } from "react";
import { createPDFfromChallan } from "../../utils/pdfDownloadUtils";
import { Button } from "@mui/material";
import ReactExport from "react-data-export";
import { FaFileExcel } from "react-icons/fa";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import CardTable from "../CardTable";
import { getSortedProducts } from "../../utils/challanUtils";
import { useSelector, useDispatch } from "react-redux";
import { getChallanByStoreId } from "../../actions/challanAction";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportProducts = ({ products }) => {
  const [prods, setProds] = useState([]);

  useEffect(() => {
    const data = getSortedProducts(products);
    setProds(data[0]);
  }, [products]);

  return (
    <div>
      <ExcelFile
        filename={`List Of Products`}
        element={
          <Button
            style={{
              fontSize: "smaller",
              background: "#1976d2",
              color: "white",
            }}
            startIcon={<FaFileExcel />}
          >
            EXPORT PRODUCTS
          </Button>
        }
      >
        <ExcelSheet data={prods} name="All Products">
          {prods.length > 0 &&
            Object.keys(prods[0]).map((item, index) => (
              <ExcelColumn key={index} label={item} value={item} />
            ))}
        </ExcelSheet>
      </ExcelFile>
    </div>
  );
};

const ViewChallan = () => {
  const { storeChallans, challanLoading } = useSelector(
    (state) => state.challan
  );
  const [challanData, setChallanData] = useState([]);
  const dispatch = useDispatch();
  const downloadPDF = (challan) => {
    const pdf = createPDFfromChallan(challan);
  };

  useEffect(() => {
    if (!storeChallans.length > 0) dispatch(getChallanByStoreId());
  }, []);

  useEffect(() => {
    if (!challanLoading && storeChallans.length > 0) {
      const data = storeChallans.map((challan) => ({
        Generated_On: new Date(challan.addedAt).toLocaleDateString(),
        Challan_ID: challan.challanNumber,
        Vendor: challan.vendor,
        Eway_Bill: challan.ewayBillURL ? (
          <Anchor href={challan.ewayBillURL} target="_blank">
            View Eway Bill
          </Anchor>
        ) : (
          "Not Updated"
        ),
        Download_PDF: (
          <Button
            style={{
              fontSize: "smaller",
              color: "white",
              background: "#f50057",
            }}
            onClick={() => downloadPDF(challan)}
          >
            Download PDF
          </Button>
        ),
        Download_Excel:
          challan.productDetail.length > 0 ? (
            <ExportProducts products={challan.productDetail} />
          ) : (
            "Yo"
          ),
      }));

      setChallanData(data);
    }
  }, [challanLoading, storeChallans]);

  return (
    <>
      <div style={{ marginTop: "50px" }}>
        {challanLoading && (
          <LoadingScreen>
            <CircularProgress size={200} />
          </LoadingScreen>
        )}
        <h2>Challans Generated : </h2>
        {challanData.length > 0 && <CardTable data={challanData} />}
      </div>
    </>
  );
};

const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(256, 256, 256, 0.7);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Anchor = styled.a`
  text-decoration: underline;
  color: dodgerblue;
  cursor: pointer;
`;

export default ViewChallan;
