import React from "react";

const deleteGstBills = () => {
    return <div>deleteGstBills</div>;
};

export default deleteGstBills;
// import { useState, useEffect, useContext } from "react";
// import styled from "styled-components";
// import Layout from "../../components/Layout";
// import Breadcrump from "../../components/Breadcrump";
// import AuthContext from "../../context/AuthContext";
// import { Button } from "@mui/material";
// import GstReportUpdate from "../../components/reports/GstReportUpdate";

// import "react-calendar/dist/Calendar.css";

// const deleteGstBills = ({ user }) => {
//   const { setUser } = useContext(AuthContext);

//   const [selectedDate, setSelectedDate] = useState(new Date());

//   useEffect(() => {
//     if (user) setUser(user);
//   }, []);

//   const widgets = [];

//   return (
//     <Layout title="export customer info | LITTLEBOX">
//       <Breadcrump path="/Reports/update bills" widgets={widgets} />
//       <GstReportUpdate />
//     </Layout>
//   );
// };

// const Heading = styled.h1`
//   margin: 20px 0;
//   text-align: center;
// `;

// const CalenderContainer = styled.div`
//   width: 90%;
//   max-width: 400px;
//   margin: 0 auto;
// `;

// const ButtonContainer = styled.div`
//   display: flex;
//   max-width: 550px;
//   margin: 50px auto;
//   flex-wrap: wrap;
//   justify-content: space-between;
// `;

// export async function getServerSideProps(ctx) {
//   const API_URL = `${process.env.API_URL}/auth/user`;

//   // Check cookie
//   if (ctx.req.headers.cookie) {
//     const token = ctx.req.headers.cookie.split("=")[1];

//     const response = await fetch(API_URL, {
//       method: "GET",
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     const user = await response.json();

//     if (response.ok) {
//       if (user.role !== "administrator") {
//         return {
//           redirect: {
//             destination: "/",
//             permanent: false,
//           },
//         };
//       }
//       return { props: { user } };
//     }
//   }

//   // Else
//   return {
//     redirect: {
//       destination: "/login",
//       permanent: false,
//     },
//   };
// }

// export default deleteGstBills;
