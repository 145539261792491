import Layout from "../../../Layout";
import Breadcrump from "../../../Breadcrump";
import EveryDayStockReport from "../../../reports/EveryDayStockReport";
import { useSelector } from "react-redux";
import RedirectLogin from "../../../RedirectLogin";

const EverydayStock = () => {
    const auth = useSelector((state) => state.auth);

    const widgets = [];
    return (
        <>
            {!!auth ? (
                <Layout title="Store Value Report | LITTLEBOX">
                    <Breadcrump
                        path="/Reports/Inventory/Store Value"
                        widgets={widgets}
                    />

                    <EveryDayStockReport />
                </Layout>
            ) : (
                <RedirectLogin />
            )}
        </>
    );
};

export default EverydayStock;
