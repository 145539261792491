import { useState, useEffect } from "react";
import styled from "styled-components";
import {
  TextField,
  MenuItem,
  CircularProgress,
  Button,
} from "@mui/material";
import {
  FaSortAlphaDown,
  FaSortAlphaUpAlt,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import CatalogueTableRow from "./CatalogueTableRow";

const CatalogueTable = ({ loading, products }) => {
  const [tableProducts, setTableProducts] = useState([]);
  const [productType, setProductType] = useState("");
  const [asc, setAsc] = useState(true);
  const [searchedId, setSearchedId] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    changeOptions(productType);
  }, [productType, products]);

  const changeOptions = (type) => {
    const productsByType = products.filter((p) => p.type === type);

    createTableData(productsByType);
  };

  const fetcher = () =>
    tableProducts.slice(pageIndex * 10, (pageIndex + 1) * 10);

  useEffect(() => {
    setData(fetcher());
  }, [tableProducts, pageIndex, products]);

  const types = [
    {
      value: "",
      label: "None",
    },
    {
      value: "clothing",
      label: "Clothing",
    },
    {
      value: "footwear",
      label: "Footwear",
    },
    {
      value: "imported",
      label: "Imported",
    },
    {
      value: "accessories",
      label: "Accessories",
    },
  ];

  const handleSearchId = (e) => {
    e.preventDefault();
    const text = e.target.value;
    setSearchedId(text);
    if (text.length > 1) {
      const likeProducts = products
        .filter((p) => p.type === productType)
        .filter((p) => p.id.toString().match(text.toUpperCase()));

      setTableProducts(likeProducts);
    } else {
      // Get products by type
      changeOptions();
    }
  };

  const sortById = () => {
    const sorted = tableProducts.sort((a, b) => Number(a.id) - Number(b.id));
    if (!asc) {
      sorted.reverse();
    }
    setTableProducts(sorted);
    setAsc(!asc);
  };

  const createTableData = (productsByType) => {
    let index = 0;
    const tableData = productsByType.reduce((acc, item) => {
      const row = acc.find((p) => p.id === item.id);
      if (row) {
        row[item.size] = item.barcode;
      } else {
        const newProduct = {
          index: index,
          id: item.id,
          url: item.url,
          name: item.name,
          costPrice: item.costPrice,
          mrp: item.mrp,
          [item.size]: item.barcode,
        };
        acc.push(newProduct);
        index++;
      }
      return acc;
    }, []);
    setTableProducts(tableData);
  };

  const printTableHeader = () => {
    const [costPrice, mrp, name, url, id, index, ...sizes] = Object.keys(
      data[0]
    )
      .sort()
      .reverse();

    return (
      <tr>
        <td>
          <CountSpan>{tableProducts.length}</CountSpan>Item
        </td>
        <td>Image</td>
        <TD>
          <SortButton onClick={sortById}>Id</SortButton>
          {asc ? <FaSortAlphaDown /> : <FaSortAlphaUpAlt />}
        </TD>
        <td>Name</td>
        <td>Cost Price</td>
        <td>M.R.P.</td>
        {(productType === "clothing" ? sizes : sizes.reverse()).map((i, k) => (
          <td key={k}>Size: {i.toString()}</td>
        ))}
      </tr>
    );
  };

  return (
    <div>
      <h1>Available {productType}</h1>
      <br />
      <br />
      <Row>
        <Col>
          <TextField
            select
            label="Select Product Type"
            value={productType}
            onChange={(e) => {
              setProductType(e.target.value);
              setPageIndex(0);
            }}
            variant="outlined"
            className="select"
          >
            {types.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Col>
      </Row>
      {!!productType && (
        <Row>
          <Col>
            <TextField
              label="Search Ids"
              type="text"
              variant="outlined"
              size="small"
              value={searchedId}
              onChange={(e) => handleSearchId(e)}
            />
          </Col>
        </Row>
      )}
      <Container>
        <TableBox>
          {loading && (
            <LoadingScreen>
              <CircularProgress size={100} />
            </LoadingScreen>
          )}
          {data.length > 0 && (
            <Table>
              <Thead>{printTableHeader()}</Thead>
              {!loading && (
                <tbody>
                  {!loading &&
                    data.map((item) => (
                      <CatalogueTableRow
                        key={item.index}
                        item={item}
                        productType={productType}
                      />
                    ))}
                </tbody>
              )}
            </Table>
          )}
        </TableBox>
        {data.length > 0 && (
          <PaginationArrows>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={pageIndex < 1}
              startIcon={<FaAngleLeft />}
              onClick={() => setPageIndex(pageIndex - 1)}
            >
              Previous
            </Button>
            <span>
              Page {pageIndex + 1} of {Math.ceil(tableProducts.length / 10)}
            </span>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={pageIndex > Math.floor(tableProducts.length / 10) - 1}
              endIcon={<FaAngleRight />}
              onClick={() => setPageIndex(pageIndex + 1)}
            >
              Next
            </Button>
          </PaginationArrows>
        )}
      </Container>
    </div>
  );
};

const Container = styled.div`
  margin-top: 50px;
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
`;

const Col = styled.div`
  width: 100%;
  & .select.MuiFormControl-root {
    min-width: 206px;
    max-height: 50px;
  }
  & .select .MuiOutlinedInput-input {
    padding: 10.5px 14px;
  }
  & .select .MuiSelect-nativeInput {
    bottom: 10.5px;
  }
  & .select .MuiInputLabel-outlined {
    transform: translate(14px, 14px) scale(1);
  }
  & .select .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
`;

const Table = styled.table`
  border-spacing: 0px;
  & td {
    padding: 5px 0 5px 5px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-align: left;
  }
`;

const Thead = styled.thead`
  & > tr > * {
    padding: 8px 0 8px 5px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-align: left;
    background: #ccc;
  }
`;

const TD = styled.td`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  cursor: pointer;
`;

const TableBox = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 10px #ddd;

  & > * {
    flex: 1;
    font-size: 1.05rem;
  }
`;
const SortButton = styled.button`
  height: 40px;
  flex: 1;
  font-size: 1rem;
  background: transparent;
  cursor: pointer;
  border: none;
`;

const CountSpan = styled.span`
  padding: 5px 8px;
  height: 30px;
  border-radius: 50%;
  background: #73c24c;
  font-size: 0.8rem;
  font-weight: bold;
  color: #fefefe;
  margin-right: 10px;
`;

const LoadingScreen = styled.div`
  width: 100%;
  height: 50vh;
  background: rgba(256, 256, 256, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PaginationArrows = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
`;

export default CatalogueTable;
