import { useState } from "react"
import { TextField } from '@mui/material';

const EditProductRowSizeBox = ({ item, stock, editCount }) => {
    const [count, setCount] = useState(stock)

    return (
        <form onSubmit={(e) => editCount(e, item, count)} style={{ maxWidth: "100px" }}>
            {count === undefined ? (
                <TextField
                    label="count"
                    value={"NA"}
                    type="text"
                    disabled
                    variant="outlined"
                    size="small"
                    fullWidth
                />
            ) : (
                <TextField
                    label="count"
                    value={count}
                    type="text"
                    variant="outlined"
                    size="small"
                    onChange={(e) => setCount(e.target.value)}
                    fullWidth
                />
            )}
        </form>
    )
}

export default EditProductRowSizeBox
