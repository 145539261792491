import { getAwsAuthorization } from "../utils/Signature";
import { toast } from "react-toastify";
import { formatCurrency } from "../utils";

export const showExpenseLoading = () => {
  return {
    type: "SHOW_EXPENSE_LOADING",
  };
};

export const hideExpenseLoading = () => {
  return {
    type: "HIDE_EXPENSE_LOADING",
  };
};

export const getyesterdayclosingbalance = () => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSgetyesterdaydailycashbystore";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const { storeId } = state.auth.action.data;
  const payload = {
    store_id: storeId,
  };
  const authorizationHeaders = getAwsAuthorization(
    AccessKeyId,
    SecretKey,
    SessionToken,
    payload,
    URL
  );
  dispatch(showExpenseLoading());
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        ...authorizationHeaders,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    dispatch(hideExpenseLoading());
    if (data.statusCode === 200) {
      dispatch({
        type: "SHOW_YESTERDAY_BALANCE",
        payload: data.body?.closing || "0.00",
      });
    } else {
      toast.error("Error", {
        theme: "colored",
      });
    }
  } catch (err) {
    dispatch(hideExpenseLoading());

    // toast.error("Something Went Wrong With Connecting To Database", {
    //   theme: "colored",
    // });
  }
};

//not done
export const getExpensesBystoreId = () => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSgetexpensesbystoreid";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const { storeId } = state.auth.action.data;
  let payload = {
    company: "SSS",
    storeId: storeId,
    LastEvaluatedKey: null,
  };
  dispatch(showExpenseLoading());
  let fetching = true;
  let allExpenses = [];
  try {
    while (fetching) {
      let authorizationHeaders = getAwsAuthorization(
        AccessKeyId,
        SecretKey,
        SessionToken,
        payload,
        URL
      );
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();

      if (data.statusCode === 200) {
        const expenesResponse = data.data;
        allExpenses.push(...expenesResponse);
        payload.LastEvaluatedKey = data.LastEvaluatedKey;
        if (!payload.LastEvaluatedKey) {
          fetching = false;
        }
      } else {
        allExpenses = [];
        fetching = false;
        toast.error("Error", {
          theme: "colored",
        });
      }
    }
    dispatch(hideExpenseLoading());
    const todayExpenses = allExpenses.filter(
      (e) =>
        new Date(e.createdAt).toLocaleDateString() ===
        new Date().toLocaleDateString()
    );
    dispatch({
      type: "SHOW_EXPENSES",
      payload: allExpenses,
    });
    dispatch({
      type: "SHOW_TODAY_EXPENSES",
      payload: todayExpenses,
    });
  } catch (err) {
    dispatch(hideExpenseLoading());

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

//not done
export const getStoreDailyCash = () => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSgetstoredailycash";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const { storeId } = state.auth.action.data;
  let payload = {
    storeId: storeId,
    LastEvaluatedKey: null,
  };
  dispatch(showExpenseLoading());
  let fetching = true;
  let allDailyCash = [];
  try {
    while (fetching) {
      let authorizationHeaders = getAwsAuthorization(
        AccessKeyId,
        SecretKey,
        SessionToken,
        payload,
        URL
      );
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.statusCode === 200) {
        const dailyCashResponse = data.data;
        allDailyCash.push(...dailyCashResponse);
        payload.LastEvaluatedKey = data.LastEvaluatedKey;
        if (!payload.LastEvaluatedKey) {
          fetching = false;
        }
      } else {
        allDailyCash = [];
        fetching = false;
        toast.error("Error", {
          theme: "colored",
        });
      }
    }
    dispatch(hideExpenseLoading());
    dispatch({
      type: "SHOW_STORE_DAILY_CASH",
      payload: allDailyCash,
    });
  } catch (err) {
    dispatch(hideExpenseLoading());
    console.log("error", err);
    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const postDailyCash =
  (cashSale, closing) => async (dispatch, getState) => {
    const URL =
      "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSpostdailycash";
    const state = getState();
    const { AccessKeyId, SecretKey, SessionToken } =
      state.auth.action.credentials;
    const { openingBalance, todayExpenses, storeDailyCash } = state.expenses;
    const { storeId } = state.auth.action.data;
    const payload = {
      storeId,
      opening: openingBalance,
      closing,
      cashSale,
      advanceCash: "0.00",
      staff: formatCurrency(
        todayExpenses
          .filter((e) => e.type === "staff")
          .reduce((sum, i) => parseFloat(i.amount) + sum, 0)
      ),
      stationary: formatCurrency(
        todayExpenses
          .filter((e) => e.type === "stationary")
          .reduce((sum, i) => parseFloat(i.amount) + sum, 0)
      ),
      housekeeping: formatCurrency(
        todayExpenses
          .filter((e) => e.type === "housekeeping")
          .reduce((sum, i) => parseFloat(i.amount) + sum, 0)
      ),
      transport: formatCurrency(
        todayExpenses
          .filter((e) => e.type === "transport")
          .reduce((sum, i) => parseFloat(i.amount) + sum, 0)
      ),
      wifi: formatCurrency(
        todayExpenses
          .filter((e) => e.type === "wifi")
          .reduce((sum, i) => parseFloat(i.amount) + sum, 0)
      ),
      electricity: formatCurrency(
        todayExpenses
          .filter((e) => e.type === "electricity")
          .reduce((sum, i) => parseFloat(i.amount) + sum, 0)
      ),
      maintanance: formatCurrency(
        todayExpenses
          .filter((e) => e.type === "maintanance")
          .reduce((sum, i) => parseFloat(i.amount) + sum, 0)
      ),
      marketing: formatCurrency(
        todayExpenses
          .filter((e) => e.type === "marketing")
          .reduce((sum, i) => parseFloat(i.amount) + sum, 0)
      ),
      deposit: formatCurrency(
        todayExpenses
          .filter((e) => e.type === "deposit")
          .reduce((sum, i) => parseFloat(i.amount) + sum, 0)
      ),
    };
    const authorizationHeaders = getAwsAuthorization(
      AccessKeyId,
      SecretKey,
      SessionToken,
      payload,
      URL
    );
    dispatch(showExpenseLoading());
    try {
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      dispatch(hideExpenseLoading());
      if (data.statusCode === 200) {
        const updatedDailyCash = storeDailyCash;
        updatedDailyCash.push(data.data);
        dispatch({
          type: "SHOW_STORE_DAILY_CASH",
          payload: updatedDailyCash,
        });
        toast("Balance saved successfully");
      } else {
        toast.error("Could not post balance", {
          theme: "colored",
        });
      }
    } catch (err) {
      dispatch(hideExpenseLoading());

      toast.error("Something went wrong with connecting to database", {
        theme: "colored",
      });
    }
  };
// not done
export const postExpense = (data) => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSpostexpense";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const { storeId } = state.auth.action.data;
  const { expenses } = state.expenses;
  const payload = {
    storeId,
    ...data,
  };

  const authorizationHeaders = getAwsAuthorization(
    AccessKeyId,
    SecretKey,
    SessionToken,
    payload,
    URL
  );
  dispatch(showExpenseLoading());
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        ...authorizationHeaders,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    dispatch(hideExpenseLoading());
    if (data.statusCode === 200) {
      const newExpensesArray = expenses;
      newExpensesArray.push(data.data);
      dispatch({
        type: "SHOW_EXPENSES",
        payload: newExpensesArray,
      });
      toast("Expense saved successfully");
    } else {
      toast.error("Could not post expense", {
        theme: "colored",
      });
    }
  } catch (err) {
    dispatch(hideExpenseLoading());

    toast.error("Something went wrong with connecting to database", {
      theme: "colored",
    });
  }
};
