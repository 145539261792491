import { useSelector } from "react-redux";
import Layout from "../../../Layout";
import Breadcrump from "../../../Breadcrump";
import PWSalesReport from "../../../reports/PWSalesReport";
import RedirectLogin from "../../../RedirectLogin";

const PWSaleReport = () => {
    const auth = useSelector((state) => state.auth);
    const widgets = [];

    return (
        <>
            {!!auth ? (
                <Layout title="Today's Sales Report | LITTLEBOX">
                    <Breadcrump
                        path="/Reports/Sales/Product Wise Sale Report"
                        widgets={widgets}
                    />
                    <PWSalesReport />
                </Layout>
            ) : (
                <RedirectLogin />
            )}
        </>
    );
};

export default PWSaleReport;
