export const storeIdNumber = [
  "None",
  "Haus Khas",
  "Bora Service",
  "None",
  "Goa",
  "Manipur",
  "Rajasthan",
  "Kamla Nagar",
  "Moradabad",
  "Shillong",
];
