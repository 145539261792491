const decimalPlaces = 2;

export const formatCurrency = (value) => {
  return Number(
    Math.round(parseFloat(value + "e" + decimalPlaces)) + "e-" + decimalPlaces
  ).toFixed(decimalPlaces);
};

export const calculateCombo = (products, categories) => {
  if (products.length > 0) {
    // Clear out all discounts

    let workingList = products.map((p) => {
      if (p.comboDiscounted) {
        // get initial amounts by removing combo plans
        const initialTotal = parseFloat(p.mrp) * p.count;
        return {
          ...p,
          comboDiscounted: false,
          discountAmt: formatCurrency(0),
          total: formatCurrency(initialTotal),
        };
      }
      return p;
    });

    categories.forEach((c) => {
      const eligibleItems = workingList.filter((p) =>
        p.comboCategories.includes(c.categoryId)
      );
      const restItems = workingList.filter(
        (p) => !p.comboCategories.includes(c.categoryId)
      );
      const totalItems = eligibleItems.reduce(
        (acc, current) => current.count + acc,
        0
      );
      if (totalItems >= c.count) {
        const eligibleCount = Math.floor(totalItems / c.count) * c.count;
        let count = 0;
        const newProductList = eligibleItems.map((p) => {
          if (count >= eligibleCount) {
            // Early exit if discount finished
            return p;
          }

          let discount = 0;
          for (let i = 0; i < p.count; i++) {
            if (count < eligibleCount) {
              // Give the discount
              discount += p.mrp - parseFloat(c.price) / c.count;
              count++;
            }
          }
          return {
            ...p,
            comboDiscounted: true,
            discountAmt: formatCurrency(parseFloat(p.discountAmt) + discount),
            total: formatCurrency(parseFloat(p.total) - discount),
          };
        });

        // setBilledProducts(newProductList)
        workingList = [...newProductList, ...restItems];
      }
    });
    return workingList.sort((a, b) => a.addedAt - b.addedAt);
  } else {
    return products;
  }
};

export const reduceProducts = (products) =>
  products.reduce((acc, item) => {
    const row = acc.find((p) => p.id === item.productId);
    if (row) {
      row[item.size] = item.stock
      row.total = row.total + item.stock
    } else {
      const newProduct = {
        id: item.productId,
        url: item.url,
        categories: item.categories,
        name: item.name,
        mrp: item.mrp,
        [item.size]: item.stock,
        total: item.stock,
      };
      acc.push(newProduct);
    }
    return acc;
  }, [])
    .sort((a, b) => Number(a.total) - Number(b.total));

export const getCountForPOBySize = (size) => {
  const counts = {
    34: 0,
    35: 3,
    36: 5,
    37: 5,
    38: 4,
    39: 3,
    40: 2,
    41: 2,
    42: 0,
    XS: 5,
    S: 5,
    M: 5,
    L: 3,
    XL: 2,
    FR: 0,
  };
  return counts[size];
};
