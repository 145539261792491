import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  Button,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";
import { FaSave } from "react-icons/fa";
import { toast } from "react-toastify";

import { storeIdNumber } from "../../utils/storeIdutils";
import {
  addToInventory,
  addToFranchiseInventory,
  adminGetInventory,
  getCatalogues,
  updateInventoryStock,
  updateFranchiseInventoryStock,
} from "../../actions/inventoryActions";

const AddProductsForm = ({ existing }) => {
  const auth = useSelector((state) => state.auth);
  const { inventory, catalogue, inventoryLoading, savedProducts } = useSelector(
    (state) => state.inventory
  );
  const [store, setStore] = useState("");
  const [loading, setLoading] = useState(false);
  const [productId, setProductId] = useState("");
  const [searchedProducts, setSearchedProducts] = useState(null);
  const [po, setPo] = useState("");
  const [posted, setPosted] = useState([]);
  const [renewal, setRenewal] = useState(false);
  const [done, setDone] = useState(false);
  const [types, setTypes] = useState([]);

  const dispatch = useDispatch();

  const searchInventoryById = (id) =>
    inventory.filter((p) => p.productId === id);

  const searchById = (id) => catalogue.filter((p) => p.id === id);

  useEffect(() => {
    if (!catalogue.length > 0) dispatch(getCatalogues());
    if (auth.action.data.role === "franchiseowner") {
      setTypes([
        {
          value: "",
          label: "None",
        },
        ...JSON.parse(auth.action.data.storeId).map((s) => ({
          value: s,
          label: storeIdNumber[s],
        })),
      ]);
    } else {
      setTypes([
        {
          value: "",
          label: "None",
        },
        {
          value: "1",
          label: "Haus Khas",
        },
        {
          value: "2",
          label: "Borah Service",
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (!store) return;
    dispatch(adminGetInventory(store));
    setSearchedProducts(null);
    setProductId("");
    setPosted([]);
  }, [store]);

  useEffect(() => {
    setSearchedProducts(null);
    if (done) {
      setProductId("");
      setPo("");
      setRenewal(false);
    } else {
      setPosted([]);
    }
  }, [done]);

  const myLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  const handleIdChange = (e) => {
    setSearchedProducts(null);
    setProductId(e.target.value);
  };

  const searchProduct = (e) => {
    e.preventDefault();
    setDone(false);
    const matchInInventory = searchInventoryById(productId);
    if (existing) {
      // Add to Inventory Page
      if (matchInInventory.length > 0) {
        // Existing Products are found
        const renewables = matchInInventory.map((p) => ({ ...p, newStock: 0 }));
        setRenewal(true);
        setSearchedProducts(renewables);
      } else {
        // Products doesn't exist, Alert User to try other page
        const alertText =
          "Products not found in Inventory. Try adding from catalogue";
        toast(alertText);
        setRenewal(false);
        setSearchedProducts(null);
      }
      return;
    } else {
      // Add products Page
      if (matchInInventory.length > 0) {
        // Alert to go to add to inventory page
        const alertText =
          "Products already present in Inventory. Go to Add to inventory page!";
        toast(alertText);
        return;
      }
      const matching = searchById(productId);
      if (matching.length > 0) {
        const products = matching.map((p) => ({
          barcode: p.barcode,
          productId: p.id,
          name: p.name,
          shortDescription: p.description,
          url: p.url,
          type: p.type,
          categories: p.categories,
          size: p.size,
          costPrice: p.costPrice,
          mrp: p.mrp,
          comboCategories: p.comboCategories,
          stock: "",
          storeId: store,
          vendor: p.vendor,
        }));
        setSearchedProducts(products);
        setRenewal(false);
      } else {
        const alertText =
          "Products not found in Catalog. Try adding from catalogue";
        toast(alertText);
        setSearchedProducts(null);
      }
    }
    return;
  };

  const updateStock = (e, p) => {
    const newData = searchedProducts.map((item) => {
      if (p.barcode === item.barcode) {
        const count = parseInt(e.target.value, 10);
        if (Number.isNaN(count)) {
          const alertText = "Not valid NUMBER";
          toast(alertText);
          return item;
        }
        return {
          ...item,
          newStock: count,
        };
      } else {
        return item;
      }
    });
    setSearchedProducts(newData);
  };

  const addStock = (e, p) => {
    const newData = searchedProducts.map((item) => {
      if (p.barcode === item.barcode) {
        return {
          ...item,
          stock: e.target.value,
        };
      } else {
        return item;
      }
    });
    setSearchedProducts(newData);
  };

  const handleSubmit = async () => {
    let parseError = false;
    const newProducts = searchedProducts.map((p) => {
      const count = existing ? parseInt(p.newStock, 10) : parseInt(p.stock, 10);
      if (Number.isNaN(count)) {
        parseError = true;
        return p;
      } else {
        return {
          ...p,
          poNumber: po,
          stock: existing ? count + p.stock : count,
        };
      }
    });

    if (parseError) {
      //Alert
      const alertText =
        "Error in Saving Products. Check all fields and try again";
      toast(alertText);
      return;
    }

    if (renewal) {
      if (auth.action.data.role === "franchiseowner") {
        dispatch(updateFranchiseInventoryStock(newProducts));
      } else {
        dispatch(updateInventoryStock(newProducts));
      }
    } else {
      if (auth.action.data.role === "franchiseowner") {
        dispatch(addToFranchiseInventory(newProducts));
      } else {
        dispatch(addToInventory(newProducts));
      }
    }
    setLoading(true);
    setDone(true);
  };

  useEffect(() => {
    setPosted(savedProducts);
    setLoading(false);
  }, [savedProducts]);

  return (
    <>
      {(inventoryLoading || loading) && (
        <LoadingScreen>
          <CircularProgress size={200} />
        </LoadingScreen>
      )}
      {!store ? (
        <Container>
          <h3>Choose Store</h3>
          <br />
          <TextField
            select
            label="Select Store Location"
            value={store}
            onChange={(e) => setStore(e.target.value)}
            variant="outlined"
            className="select"
            fullWidth
          >
            {types.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Container>
      ) : (
        <>
          <h2>Add New Product</h2>
          <Container>
            <Row>
              <h4>
                Inventory for store:{" "}
                <Red>{types.filter((t) => t.value === store)[0].label}</Red>
                <Button
                  style={{ marginLeft: "10px" }}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={(e) => setStore("")}
                >
                  change
                </Button>
              </h4>
            </Row>
            <Row>
              <Col>Search by product id:</Col>
              <Col>
                <form onSubmit={searchProduct}>
                  <TextField
                    id="pid"
                    label="Product Id"
                    type="text"
                    value={productId}
                    onChange={handleIdChange}
                    variant="outlined"
                    size="small"
                  />
                </form>
              </Col>
            </Row>
          </Container>
          {searchedProducts && (
            <Container>
              <hr />
              <Row>
                <Col>
                  <img
                    width="100"
                    height="100"
                    src={searchedProducts[0].url}
                    alt="SSS"
                  />
                </Col>
                <Col>
                  <h3>Name: {searchedProducts[0].name}</h3>
                </Col>
                <Col>
                  <TextField
                    id="name"
                    label="P.O. number"
                    value={po}
                    onChange={(e) => setPo(e.target.value)}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </Col>
              </Row>
              <h3>Add new stock according to sizes</h3>
              <br />
              {existing ? (
                <Row>
                  {searchedProducts.map((p) => (
                    <Col key={p.barcode}>
                      <TextField
                        id={p.barcode}
                        label={"size " + p.size}
                        value={p.newStock}
                        onChange={(e) => updateStock(e, p)}
                        type="text"
                        variant="outlined"
                        size="small"
                      />
                    </Col>
                  ))}
                </Row>
              ) : (
                <Row>
                  {searchedProducts.map((p) => (
                    <Col key={p.barcode}>
                      <TextField
                        id={p.barcode}
                        label={"size " + p.size}
                        value={p.stock}
                        onChange={(e) => addStock(e, p)}
                        type="text"
                        variant="outlined"
                        size="small"
                      />
                    </Col>
                  ))}
                </Row>
              )}
              <br />

              <Row>
                <Col>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    onClick={handleSubmit}
                    startIcon={<FaSave />}
                    fullWidth
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Container>
          )}

          {posted?.length > 0 && (
            <Container>
              <hr />
              <br />
              <h3>Added products are:</h3>
              <Row>
                <Col>
                  <img
                    width="100"
                    height="100"
                    src={posted[0].url}
                    alt="SSS"
                  />
                </Col>
                <Col>Id: {posted[0].productId}</Col>
                <Col>{posted[0].name}</Col>
              </Row>
              <br />
              {posted.map((p) => (
                <Row key={p.barcode}>
                  <Col>Barcode: {p.barcode}</Col>
                  <Col>Size: {p.size}</Col>
                  <Col>Count: {p.stock}</Col>
                </Row>
              ))}
              <br />
            </Container>
          )}
        </>
      )}
    </>
  );
};

const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(256, 256, 256, 0.7);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  margin: 20px auto;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
`;

const Red = styled.span`
  color: red;
`;

const Col = styled.div`
  width: 100%;
  & .select.MuiFormControl-root {
    min-width: 206px;
    max-height: 50px;
  }
  & .select .MuiOutlinedInput-input {
    padding: 10.5px 14px;
  }
  & .select .MuiSelect-nativeInput {
    bottom: 10.5px;
  }
  & .select .MuiInputLabel-outlined {
    transform: translate(14px, 14px) scale(1);
  }
  & .select .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
`;

export default AddProductsForm;
