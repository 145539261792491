import React from "react";

const poCheckout = () => {
    return <div>poCheckout</div>;
};

export default poCheckout;
// import { useEffect, useContext } from "react";
// import styled from "styled-components";
// import Layout from "../../components/Layout";
// import Breadcrump from "../../components/Breadcrump";
// import AuthContext from "../../context/AuthContext";
// import PostPO from "../../components/purchase/PostPO";

// const POCheckout = ({ user }) => {
//   const { setUser } = useContext(AuthContext);

//   useEffect(() => {
//     if (user) setUser(user);
//   }, []);

//   return (
//     <Layout title="PO Checkout | LITTLEBOX">
//       <Breadcrump path="/Purchase/PO Checkout" widgets={[]} />
//       <Content className="content-container">
//         <PostPO />
//       </Content>
//     </Layout>
//   );
// };

// const Content = styled.div`
//   width: 100%;
// `;

// export async function getServerSideProps(ctx) {
//   const API_URL = `${process.env.API_URL}/auth/user`;

//   // Check cookie
//   if (ctx.req.headers.cookie) {
//     const token = ctx.req.headers.cookie.split("=")[1];

//     const response = await fetch(API_URL, {
//       method: "GET",
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     const user = await response.json();

//     if (response.ok) {
//       if (user.role !== "manager") {
//         return {
//           redirect: {
//             destination: "/",
//             permanent: false,
//           },
//         };
//       }
//       return { props: { user } };
//     }
//   }

//   // Else
//   return {
//     redirect: {
//       destination: "/login",
//       permanent: false,
//     },
//   };
// }

// export default POCheckout;
