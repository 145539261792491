import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  searchByNameId,
  searchProduct,
  changeSuggestions,
} from "../../actions/billsAction";

const BarcodeText = () => {
  const { suggestions } = useSelector((state) => state.bills.billing);
  const [placeholder, setPlaceholder] = useState("Type Product Name or ID");
  const [text, setText] = useState("");
  const dispatch = useDispatch();
  const handleChange = (e) => {
    if (e) {
      e.preventDefault();
      setText(e.target.value);
      if (e.target.value !== "") dispatch(searchByNameId(e.target.value));
    }
  };

  const handleFocus = () => {
    setPlaceholder("");
    if (text !== "") dispatch(searchByNameId(text));
  };

  const handleBlur = (e) => {
    // setSuggestions([])
    setPlaceholder("Type Product Name or ID");
    setTimeout(() => dispatch(changeSuggestions([])), 200);
  };

  const handleChoice = (value) => {
    setText("");
    dispatch(searchProduct(value));
    dispatch(changeSuggestions([]));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.value !== "") dispatch(searchByNameId(e.target.value));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <input
          type="text"
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          value={text}
          onChange={handleChange}
        />
        {suggestions.length > 0 && (
          <SuggestionBox>
            {suggestions.map((p, i) => (
              <h4 key={i} onClick={() => handleChoice(p.barcode)}>
                {p.name} Size:{p.size}
              </h4>
            ))}
          </SuggestionBox>
        )}
      </Container>
    </form>
  );
};

const Container = styled.div`
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;

  & > input {
    width: 300px;
    padding: 10px;
    border: 2px solid #aaa;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    transition: all 0.15s ease;
    &:focus,
    &:active {
      border: 2px solid #73c24c;
      outline: none;
      box-shadow: none;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #aaa;
      font-family: "roboto condensed";
      font-size: 1.1rem;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #aaa;
      font-family: "roboto condensed";
      font-size: 1.1rem;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #aaa;
      font-family: "roboto condensed";
      font-size: 1.1rem;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #aaa;
      font-family: "roboto condensed";
      font-size: 1.1rem;
    }
  }
`;

const SuggestionBox = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 200px;

  & > h4 {
    padding: 5px;
    border-bottom: 1px solid black;
    cursor: pointer;
  }
`;

export default BarcodeText;
