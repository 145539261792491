import { useState, useEffect } from "react";
import { FaUserPlus } from "react-icons/fa";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import Modal from "../Modal";
import AddCustomerForm from "../forms/AddCustomerForm";
import { useSelector, useDispatch } from "react-redux";
import { searchCustomer } from "../../actions/billsAction";
import { addCustomer } from "../../actions/billsAction";

const AddCustomer = ({ hint }) => {
  const bills = useSelector((state) => state.bills);
  const { billLoading } = bills;
  const { customer } = bills.billing;
  const [placeholder, setPlaceholder] = useState(hint);
  const [mobileNo, setMobileNo] = useState("");
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (customer === "") {
      const alertText = "Customer not found";
      toast(alertText);
      setShowModal(true);
    } else {
      setMobileNo("");
    }
  }, [customer]);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(searchCustomer(mobileNo));
    // setMobileNo("");
  };

  const handleAddNew = (person) => {
    dispatch(addCustomer(person));
  };
  return (
    <>
      {billLoading && (
        <LoadingScreen>
          <CircularProgress size={200} />
        </LoadingScreen>
      )}
      <Container>
        <IconBox onClick={handleClick}>
          <FaUserPlus />
          <p>Add</p>
        </IconBox>
        <form onSubmit={handleSearch}>
          <input
            type="text"
            onFocus={() => setPlaceholder("")}
            onBlur={() => setPlaceholder(hint)}
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
            placeholder={placeholder}
          />
        </form>
      </Container>
      <Modal
        show={showModal}
        title={"Add Customer Details"}
        onClose={() => setShowModal(false)}
        maxWidth="800px"
      >
        <AddCustomerForm onSubmit={handleAddNew} mobile={mobileNo} />
      </Modal>
    </>
  );
};

const Container = styled.div`
  height: 30px;
  display: flex;
  align-items: center;

  & input {
    height: 30px;
    width: 200px;
    padding: 0 5px;
    border: 2px solid #aaa;
    border-left: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    transition: all 0.15s ease;
    &:focus,
    &:active {
      border: 2px solid #73c24c;
      border-left: none;
      outline: none;
      box-shadow: none;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #aaa;
      font-family: "roboto condensed";
      font-size: 0.7rem;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #aaa;
      font-family: "roboto condensed";
      font-size: 0.7rem;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #aaa;
      font-family: "roboto condensed";
      font-size: 0.7rem;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #aaa;
      font-family: "roboto condensed";
      font-size: 0.7rem;
    }
  }
`;

const IconBox = styled.div`
  height: 30px;
  width: 40px;
  background: #73c24c;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  & > svg {
    color: #fefefe;
    font-size: 1rem;
  }

  & > p {
    color: #fefefe;
    font-size: 0.7rem;
  }
`;
const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(256, 256, 256, 0.7);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default AddCustomer;
