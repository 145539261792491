import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import styled from "styled-components";
import { TextField, Button } from "@mui/material";
import { FaPrint, FaSave } from "react-icons/fa";
import { toast } from "react-toastify";
import Card from "../Card";
import CheckoutDiscount from "./CheckoutDiscount";
import BillPDFComponent from "./BillPDFComponent";
import AddBag from "./AddBag";
import DenominationRecorder from "../forms/DenominationRecorder";
import Modal from "../Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  changeLastBill,
  calculateAll,
  calculateGST,
  refreshSession,
  submitBill,
  printBill,
} from "../../actions/billsAction";

const CashMemo = () => {
  const {
    billedProducts,
    cost,
    gst,
    grandTotal,
    qntyCount,
    nonComboTotal,
    couponDisc,
    bagPrice,
    appliedCoupon,
    isBilled,
  } = useSelector((state) => state.bills.billing);
  const [card, setCard] = useState("0.00");
  const [cheque, setCheque] = useState("0.00");
  const [wallet, setWallet] = useState("0.00");
  const [credit, setCredit] = useState("0.00");
  const [unpaid, setUnpaid] = useState("0.00");
  const [cash, setCash] = useState("0.00");
  const [internalUse, setInternalUse] = useState("");
  const [note, setNote] = useState("");
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const billComponentRef = useRef();

  useEffect(() => {
    dispatch(calculateAll());
  }, [billedProducts, appliedCoupon, bagPrice]);

  useEffect(
    () => dispatch(calculateGST(grandTotal, billedProducts)),
    [grandTotal]
  );

  useEffect(() => {
    if (billedProducts.length === 1) dispatch(refreshSession());
  }, [billedProducts]);

  const handlePrint = useReactToPrint({
    content: () => billComponentRef.current,
    onAfterPrint: () => {
      dispatch(changeLastBill(null));
      dispatch(printBill(false));
    },
  });

  const clearAll = () => {
    setCash("0.00");
    setCard("0.00");
    setCheque("0.00");
    setCredit("0.00");
    setWallet("0.00");
    setUnpaid("0.00");
    setInternalUse("");
    setNote("");
  };

  const handleDenominationDetails = () => {
    setShowModal(false);
  };

  const checkValidations = () => {
    let valid = true;
    // Cash
    const cashValid = parseFloat(cash);
    if (Number.isNaN(cashValid) || cashValid < 0) {
      const alertText = `Entered value ${cash} is not a valid amount`;
      toast(alertText);
      valid = false;
    }

    // Card
    const cardValid = parseFloat(card);
    if (Number.isNaN(cardValid) || cardValid < 0) {
      const alertText = `Entered value ${card} is not a valid amount`;
      toast(alertText);
      valid = false;
    }

    // Cheque
    const chequeValid = parseFloat(cheque);
    if (Number.isNaN(chequeValid) || chequeValid < 0) {
      const alertText = `Entered value ${cheque} is not a valid amount`;
      toast(alertText);
      valid = false;
    }

    // Credit
    const creditValid = parseFloat(credit);
    if (Number.isNaN(creditValid) || creditValid < 0) {
      const alertText = `Entered value ${credit} is not a valid amount`;
      toast(alertText);
      valid = false;
    }

    // Wallet
    const walletValid = parseFloat(wallet);
    if (Number.isNaN(walletValid) || walletValid < 0) {
      const alertText = `Entered value ${wallet} is not a valid amount`;
      toast(alertText);
      valid = false;
    }

    // Unpaid
    const unpaidValid = parseFloat(unpaid);
    if (Number.isNaN(unpaidValid) || unpaidValid < 0) {
      const alertText = `Entered value ${unpaid} is not a valid amount`;
      toast(alertText);
      valid = false;
    }

    const validTotal =
      cashValid + cardValid + walletValid + chequeValid + unpaidValid;

    if (validTotal !== parseFloat(grandTotal)) {
      const alertText = "Payment methods doesn't add up!!!";
      toast(alertText);
      valid = false;
    }

    // internalUse
    if (couponDisc > nonComboTotal * 0.15) {
      if (internalUse === "") {
        const alertText =
          "Field cannot be left empty. Enter remark in internalUse field";
        toast(alertText);
        valid = false;
      }
    }

    return valid;
  };

  useEffect(() => {
    if (isBilled) handlePrint();
  }, [isBilled]);

  const handleSaveNew = (shouldPrint) => {
    const isValidated = checkValidations();
    if (!isValidated) {
      return false;
    } else {
      const billingDetails = {
        card,
        cash,
        cheque,
        wallet,
        credit,
        unpaid,
        internalUse,
        note,
      };
      dispatch(submitBill(billingDetails, shouldPrint));
      clearAll();
      // toast(alertText);
      // return true;
    }
  };

  const handleSavePrint = () => {
    handleSaveNew(true);
  };

  return (
    <div>
      <Card style={{ flexDirection: "column" }}>
        <AddBag />
      </Card>
      <br />
      <Card style={{ flexDirection: "column" }}>
        <CheckoutDiscount />
        <Row style={{ gap: "0px" }}>
          <QntyBox>
            <h4>Total Cost:</h4>
            <h2>₹{cost}</h2>
          </QntyBox>
          <QntyBox>
            <h4>Total GST:</h4>
            <h2>₹{gst}</h2>
          </QntyBox>
        </Row>
        <Row style={{ gap: "0px" }}>
          <QntyBox>
            <h4>Total Qnty:</h4>
            <h2>{qntyCount}</h2>
          </QntyBox>
          <QntyBox>
            <h4>Total Payable:</h4>
            <h2>₹{grandTotal}</h2>
          </QntyBox>
        </Row>
      </Card>
      <BillConatiner>
        <Modal
          show={showModal}
          title={"Add Currency Denomination"}
          onClose={() => setShowModal(false)}
          maxWidth="800px"
        >
          <DenominationRecorder
            setCash={setCash}
            onSubmit={handleDenominationDetails}
          />
        </Modal>
        <Row>
          <Col>
            <TextField
              id="ch"
              label="Cash"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              value={cash}
              onClick={() => setShowModal(true)}
            />
          </Col>
          <Col>
            <TextField
              id="cd"
              label="Card"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              value={card}
              onChange={(e) => setCard(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              id="wl"
              label="UPI"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              value={wallet}
              onChange={(e) => setWallet(e.target.value)}
            />
          </Col>
          <Col>
            <TextField
              id="ck"
              label="Cheque"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              value={cheque}
              onChange={(e) => setCheque(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              id="cn"
              label="Credit Note"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              value={credit}
              onChange={(e) => setCredit(e.target.value)}
            />
          </Col>
          <Col>
            <TextField
              id="ua"
              label="Unpaid Amt."
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              value={unpaid}
              onChange={(e) => setUnpaid(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              id="in"
              label="Note for INTERNAL USE"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              value={internalUse}
              onChange={(e) => setInternalUse(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              id="cu"
              label="Note for CUSTOMER"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              startIcon={<FaPrint />}
              onClick={handleSavePrint}
              disabled={!(billedProducts.length > 0)}
              fullWidth
            >
              Save &#38; Print
            </Button>
          </Col>
          <Col>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<FaSave />}
              onClick={() => handleSaveNew(false)}
              disabled={!(billedProducts.length > 0)}
              fullWidth
            >
              Save &#38; New
            </Button>
          </Col>
        </Row>
      </BillConatiner>
      <HiddenContainer>
        <BillPDFComponent ref={billComponentRef} />
      </HiddenContainer>
    </div>
  );
};

const HiddenContainer = styled.div`
  display: none;
`;

const QntyBox = styled.div`
  flex: 1;
  margin: 5px 10px 5px 10px;
  height: 80px;
  background: #f0f0f0;
  text-align: left;

  & > h4 {
    margin: 10px 0 10px 10px;
  }
  & > h2 {
    margin: 0 0 0 10px;
    color: #73c24c;
  }
`;

const BillConatiner = styled.div`
  margin-top: 20px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
`;

const Col = styled.div`
  flex: 1;
`;

export default CashMemo;
