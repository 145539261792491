import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { searchProduct, changeLastCode } from "../../actions/billsAction";

const BarcodeScanner = () => {
  const { currentProduct, repeatCount, lastCode } = useSelector(
    (state) => state.bills.billing
  );
  const [placeholder, setPlaceholder] = useState("Click to start scanning");
  const [text, setText] = useState("");
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(searchProduct(text));
    setText("");
  };

  useEffect(() => {
    if (count > 0) createToast();
    setCount(count + 1);
  }, [repeatCount]);

  const createToast = () => {
    if (repeatCount === 0) return
    const alertText = currentProduct
      ? `Product ${currentProduct.name} is added`
      : `No product with code "${lastCode}" found!!!`;
    toast(alertText);
    dispatch(changeLastCode(""));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <input
          type="text"
          onFocus={() => setPlaceholder("")}
          onBlur={() => setPlaceholder("Click to start scanning")}
          placeholder={placeholder}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </Container>
    </form>
  );
};

const Container = styled.div`
  height: 30px;
  display: flex;
  align-items: center;

  & > input {
    width: 300px;
    padding: 10px;
    border: 2px solid #aaa;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    transition: all 0.15s ease;
    &:focus,
    &:active {
      border: 2px solid #73c24c;
      outline: none;
      box-shadow: none;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #aaa;
      font-family: "roboto condensed";
      font-size: 1.1rem;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #aaa;
      font-family: "roboto condensed";
      font-size: 1.1rem;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #aaa;
      font-family: "roboto condensed";
      font-size: 1.1rem;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #aaa;
      font-family: "roboto condensed";
      font-size: 1.1rem;
    }
  }
`;

export default BarcodeScanner;
