const initialState = {
    active: false
};

const navReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ACTIVE":
            return {
                ...state,
                active: action.payload,
            };
        default:
            return state;
    }
};

export default navReducer;