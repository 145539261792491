import React from "react";

const startcash = () => {
    return <div>startcash</div>;
};

export default startcash;
// import { useState, useEffect, useContext } from "react";
// import styled from "styled-components";
// import {
//   TextField,
//   MenuItem,
//   Button,
//   CircularProgress,
// } from "@mui/material";
// import { toast } from "react-toastify";
// import Layout from "../../components/Layout";
// import BillingContext from "../../context/BillingContext";
// import Breadcrump from "../../components/Breadcrump";
// import AuthContext from "../../context/AuthContext";
// import { formatCurrency } from "../../utils";

// const EditCash = () => {
//   const { loading, startingCash, setStoreId, setStartingCashInDB } =
//     useContext(BillingContext);
//   const [cash, setCash] = useState("");
//   const [store, setStore] = useState("");

//   useEffect(() => {
//     setStoreId(store);
//   }, [store]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const amt = parseFloat(cash);
//     if (Number.isNaN(amt)) {
//       const alertText = `Entered value ${cash} is not a valid amount`;
//       toast(alertText);
//     } else {
//       const alertText = await setStartingCashInDB(formatCurrency(cash));
//       toast(alertText);
//     }
//   };

//   const types = [
//     {
//       value: "",
//       label: "None",
//     },
//     {
//       value: "1",
//       label: "Haus Khas",
//     },
//     {
//       value: "2",
//       label: "Borah Service",
//     },
//   ];

//   return (
//     <Content className="content-container">
//       {loading && (
//         <LoadingScreen>
//           <CircularProgress size={200} />
//         </LoadingScreen>
//       )}
//       {!store ? (
//         <Container>
//           <h3>Choose Store</h3>
//           <br />
//           <TextField
//             select
//             label="Select Store Location"
//             value={store}
//             onChange={(e) => setStore(e.target.value)}
//             variant="outlined"
//             className="select"
//             fullWidth
//           >
//             {types.map((option) => (
//               <MenuItem key={option.value} value={option.value}>
//                 {option.label}
//               </MenuItem>
//             ))}
//           </TextField>
//         </Container>
//       ) : (
//         <Container>
//           <Container>
//             <h4>
//               Cash for store:{" "}
//               <Red>{types.filter((t) => t.value === store)[0].label}</Red>
//               <Button
//                 style={{ marginLeft: "10px" }}
//                 variant="outlined"
//                 color="secondary"
//                 size="small"
//                 onClick={(e) => setStore("")}
//               >
//                 change
//               </Button>
//             </h4>
//           </Container>
//           <h1>Enter Starting Cash</h1>
//           <form onSubmit={handleSubmit}>
//             <TextField
//               type="text"
//               value={cash}
//               placeholder={startingCash}
//               onChange={(e) => setCash(e.target.value)}
//               variant="outlined"
//               size="small"
//             />
//           </form>
//         </Container>
//       )}
//     </Content>
//   );
// };

// const StartCash = ({ user }) => {
//   const { setUser } = useContext(AuthContext);

//   useEffect(() => {
//     if (user) setUser(user);
//   }, []);

//   const widgets = [];

//   return (
//     <Layout title="Enter Today's Cash | LITTLEBOX">
//       <Breadcrump path="/Sales/Starting Cash" widgets={widgets} />
//       <EditCash />
//     </Layout>
//   );
// };

// const LoadingScreen = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background: rgba(256, 256, 256, 0.7);
//   z-index: 1100;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const Content = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
// `;

// const Container = styled.div`
//   width: 100%;
//   margin: 20px auto;
// `;

// const Red = styled.span`
//   color: red;
// `;

// export async function getServerSideProps(ctx) {
//   const API_URL = `${process.env.API_URL}/auth/user`;

//   // Check cookie
//   if (ctx.req.headers.cookie) {
//     const token = ctx.req.headers.cookie.split("=")[1];

//     const response = await fetch(API_URL, {
//       method: "GET",
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     const user = await response.json();

//     if (response.ok) {
//       if (user.role !== "administrator") {
//         return {
//           redirect: {
//             destination: "/",
//             permanent: false,
//           },
//         };
//       }
//       return { props: { user } };
//     }
//   }

//   // Else
//   return {
//     redirect: {
//       destination: "/login",
//       permanent: false,
//     },
//   };
// }

// export default StartCash;
