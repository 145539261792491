import React from "react";

const addinventoryexcel = () => {
    return <div>addinventoryexcel</div>;
};

export default addinventoryexcel;
// import styled from "styled-components";
// import { useEffect, useContext } from "react";

// import { InventoryProvider } from "../../context/InventoryContext";
// import { CatalogueProvider } from "../../context/CatalogueContext";
// import Layout from "../../components/Layout";
// import Breadcrump from "../../components/Breadcrump";
// import AddProductsForm from "../../components/forms/AddProductsForm";
// import AuthContext from "../../context/AuthContext";
// import AddProductsExcelForm from "../../components/forms/AddProductsExcelForm";

// const AddInventoryExcel = ({ user }) => {
//   const { setUser } = useContext(AuthContext);

//   useEffect(() => {
//     if (user) setUser(user);
//   }, []);

//   return (
//     <InventoryProvider>
//       <Layout title="Add Products | LITTLEBOX">
//         <Breadcrump path="/Inventory/Add to Existing Inventory" widgets={[]} />
//         <Content className="content-container">
//           <CatalogueProvider>
//             <AddProductsExcelForm />
//           </CatalogueProvider>
//         </Content>
//       </Layout>
//     </InventoryProvider>
//   );
// };

// const Content = styled.div`
//   width: 100%;
// `;

// export async function getServerSideProps(ctx) {
//   const API_URL = `${process.env.API_URL}/auth/user`;

//   // Check cookie
//   if (ctx.req.headers.cookie) {
//     const token = ctx.req.headers.cookie.split("=")[1];

//     const response = await fetch(API_URL, {
//       method: "GET",
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     const user = await response.json();

//     if (response.ok) {
//       if (user.role !== "administrator") {
//         return {
//           redirect: {
//             destination: "/",
//             permanent: false,
//           },
//         };
//       }
//       return { props: { user } };
//     }
//   }

//   // Else
//   return {
//     redirect: {
//       destination: "/login",
//       permanent: false,
//     },
//   };
// }

// export default AddInventoryExcel;
