import { useState } from "react";
import Calendar from "react-calendar";
import Layout from "../../../Layout";
import Breadcrump from "../../../Breadcrump";
import SalesReport from "../../../reports/SalesReport";
import ProfitReport from "../../../reports/ProfitReport";
import RedirectLogin from "../../../RedirectLogin";
import { useSelector } from "react-redux";
import "react-calendar/dist/Calendar.css";

const PastReports = ({ user }) => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const auth = useSelector((state) => state.auth);

    const widgets = [];
    return (
        <>
            {!!auth ? (
                <>
                    <Layout title="Today's Sales Report | LITTLEBOX">
                        <Breadcrump
                            path="/Reports/Sales/Sale Report"
                            widgets={widgets}
                        />
                        <div className="content-container">
                            <h1 className="center">Select Date:</h1>
                            <Calendar
                                className="center calender-zoom"
                                onChange={setSelectedDate}
                                value={selectedDate}
                                maxDate={new Date()}
                                minDate={
                                    new Date(
                                        new Date(
                                            new Date().setMonth(
                                                new Date().getMonth() - 1
                                            )
                                        ).setDate("01")
                                    )
                                }
                                //   showNavigation={false}
                            />
                            <ProfitReport date={selectedDate} />
                            <SalesReport date={selectedDate} />
                        </div>
                    </Layout>
                </>
            ) : (
                <RedirectLogin />
            )}
        </>
    );
};

export default PastReports;
