export const Dashboard = {
  name: "Dashboard",
  submenu: false,
  url: "/",
  items: [],
};

export const Sales = {
  name: "Sales",
  submenu: true,
  url: null,
  items: [
    {
      name: "Sales/B2C Bill",
      submenu: false,
      url: "/sales/b2cbill",
      items: [],
    },
  ],
};

export const Purchase = {
  name: "Purchase",
  submenu: true,
  url: null,
  items: [
    {
      name: "Mark For PO",
      submenu: false,
      url: "/purchase/markForPO",
      items: [],
    },
    {
      name: "View Challans",
      submenu: false,
      url: "/purchase/viewChallan",
      items: [],
    },
  ],
};

export const Inventory = {
  name: "Inventory",
  submenu: true,
  url: null,
  items: [
    {
      name: "Edit Products",
      submenu: false,
      url: "/inventory/editproductsmanager",
      items: [],
    },
    {
      name: "Stock Audit",
      submenu: false,
      url: "/inventory/stockaudit",
      items: [],
    },
    {
      name: "Catalogue Audit",
      submenu: false,
      url: "/inventory/managercatalogueaudit",
      items: [],
    },
  ],
};

export const Company = {
  name: "Company",
  submenu: false,
  url: null,
  items: [],
};

export const Store = {
  name: "Store",
  submenu: true,
  url: null,
  items: [
    {
      name: "Store Expenses",
      submenu: false,
      url: "/store/storeexpenses",
      items: [],
    },
  ],
};

// 3rd Level menu containing subitems
const ReportsSales = {
  name: "Sales",
  submenu: true,
  url: null,
  items: [
    {
      name: "Past Sale Reports",
      submenu: false,
      url: "/reports/sales/pastreports",
      items: [],
    },
    {
      name: "GST Report",
      submenu: false,
      url: "/reports/sales/gstreport",
      items: [],
    },
    {
      name: "Product Wise Sale Report",
      submenu: false,
      url: "/reports/sales/pwsalereport",
      items: [],
    },
    {
      name: "Sale Report",
      submenu: false,
      url: "/reports/sales/salereport",
      items: [],
    },
  ],
};

// const ReportsPurchase = {
//   name: "Purchase",
//   submenu: false,
//   url: null,
//   items: [
//     {
//       name: "Debit Note Report",
//       submenu: false,
//       url: null,
//       items: [],
//     },
//     {
//       name: "Expense Report",
//       submenu: false,
//       url: null,
//       items: [],
//     },
//     {
//       name: "Inward Report(Invoice Wise)",
//       submenu: false,
//       url: null,
//       items: [],
//     },
//     {
//       name: "Inward Report(Product Wise)",
//       submenu: false,
//       url: null,
//       items: [],
//     },
//     {
//       name: "Inward/GST(%) Wise Report",
//       submenu: false,
//       url: null,
//       items: [],
//     },
//   ],
// };

const ReportsInventory = {
  name: "Inventory",
  submenu: true,
  url: null,
  items: [
    {
      name: "Store Value Report",
      submenu: false,
      url: "/reports/inventory/everydaystock",
      items: [],
    },
    {
      name: "Replenished Report",
      submenu: false,
      url: "/reports/inventory/replenished",
      items: [],
    },
    {
      name: "Product Aging Report",
      submenu: false,
      url: "/reports/inventory/productagingreport",
      items: [],
    },
  ],
};

// const ReportsReminders = {
//   name: "Reminders",
//   submenu: true,
//   url: null,
//   items: [
//     {
//       name: "Customers Birthdays",
//       submenu: false,
//       url: null,
//       items: [],
//     },
//     {
//       name: "Customers Child Birthdays",
//       submenu: false,
//       url: null,
//       items: [],
//     },
//     {
//       name: "Customers Outstanding",
//       submenu: false,
//       url: null,
//       items: [],
//     },
//     {
//       name: "Product Expiry",
//       submenu: false,
//       url: null,
//       items: [],
//     },
//     {
//       name: "Supplier Payments",
//       submenu: false,
//       url: null,
//       items: [],
//     },
//   ],
// };

// Reports
export const Reports = {
  name: "Reports",
  submenu: true,
  url: null,
  items: [ReportsSales, ReportsInventory],
};
