const initialState = {
  expenses: [],
  todayExpenses: [],
  storeDailyCash: [],
  openingBalance: "",
  expenseLoading: false,
};

const expensesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_YESTERDAY_BALANCE":
      return {
        ...state,
        openingBalance: action.payload,
      };
    case "SHOW_EXPENSES":
      return {
        ...state,
        expenses: action.payload,
      };
    case "SHOW_TODAY_EXPENSES":
      return {
        ...state,
        todayExpenses: action.payload,
      };
    case "SHOW_STORE_DAILY_CASH":
      return {
        ...state,
        storeDailyCash: action.payload,
      };
    case "SHOW_EXPENSE_LOADING":
      return {
        ...state,
        expenseLoading: true,
      };
    case "HIDE_EXPENSE_LOADING":
      return {
        ...state,
        expenseLoading: false,
      };
    default:
      return state;
  }
};

export default expensesReducer;
