import React from "react";

const multipledisplay = () => {
    return <div>multipledisplay</div>;
};

export default multipledisplay;
// import { useEffect, useContext } from "react";
// import Layout from "../../../components/Layout";
// import Breadcrump from "../../../components/Breadcrump";
// import AuthContext from "../../../context/AuthContext";
// import MultipleDisplay from "../../../components/reports/MultipleDisplay";

// const everydayStock = ({ user }) => {
//   const { setUser } = useContext(AuthContext);

//   useEffect(() => {
//     if (user) setUser(user);
//   }, []);

//   const widgets = [];
//   return (
//     <Layout title="Multiple Display | LITTLEBOX">
//       <Breadcrump
//         path="/Reports/Inventory/Multiple Display"
//         widgets={widgets}
//       />
//       <MultipleDisplay />
//     </Layout>
//   );
// };

// export async function getServerSideProps(ctx) {
//   const API_URL = `${process.env.API_URL}/auth/user`;

//   // Check cookie
//   if (ctx.req.headers.cookie) {
//     const token = ctx.req.headers.cookie.split("=")[1];

//     const response = await fetch(API_URL, {
//       method: "GET",
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     const user = await response.json();

//     if (response.ok) {
//       if (user.role !== "manager") {
//         return {
//           redirect: {
//             destination: "/",
//             permanent: false,
//           },
//         };
//       }
//       return { props: { user } };
//     }
//   }

//   // Else
//   return {
//     redirect: {
//       destination: "/login",
//       permanent: false,
//     },
//   };
// }

// export default everydayStock;
