import { useState } from 'react'
import { TextField, Checkbox, Button } from '@mui/material';

import FormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components'
import { FaPlus, FaSave } from 'react-icons/fa'

import GenderSelector from './GenderSelector';

const AddCustomerForm = ({ onSubmit, mobile }) => {
    const [sameAsAbove, setSameAsAbove] = useState(true)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [gender, setGender] = useState("female")
    const [email, setEmail] = useState("")
    const [mobileNo, setMobileNo] = useState(mobile)

    const handleClick = () => {
        const customer = { firstName, lastName, gender, email, mobileNo }
        onSubmit(customer)
    }
    return (
        <form noValidate autoComplete="off">
            <Container>
                <Row>
                    <Col>
                        <TextField
                            id="fn"
                            label="First Name"
                            type="text"
                            variant="outlined"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            size="small" />
                    </Col>
                    <Col>
                        <TextField
                            id="ln"
                            label="Last Name"
                            type="text"
                            variant="outlined"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            size="small" />
                    </Col>
                    <Col>
                        <TextField
                            id="mn"
                            label="Mobile Number"
                            type="text"
                            variant="outlined"
                            value={mobileNo}
                            onChange={e => setMobileNo(e.target.value)}
                            size="small" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <GenderSelector value={gender} setValue={setGender} />
                    </Col>
                    <Col>
                        <TextField
                            id="email"
                            label="Email"
                            type="email"
                            variant="outlined"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            size="small" />
                    </Col>
                    <Col>
                        <TextField
                            id="gst"
                            label="GSTIN"
                            type="text"
                            variant="outlined"
                            value="NA"
                            InputProps={{
                                readOnly: true,
                            }}
                            size="small" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                            id="address"
                            label="Address"
                            type="text"
                            variant="outlined"
                            value="NA"
                            InputProps={{
                                readOnly: true,
                            }}
                            size="small" />
                    </Col>
                    <Col>
                        <TextField
                            id="area"
                            label="Area"
                            type="text"
                            variant="outlined"
                            value="NA"
                            InputProps={{
                                readOnly: true,
                            }}
                            size="small" />
                    </Col>
                    <Col>
                        <TextField
                            id="city"
                            label="City/Town"
                            type="text"
                            variant="outlined"
                            value="NA"
                            InputProps={{
                                readOnly: true,
                            }}
                            size="small" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                            id="pin"
                            label="Pin/Zip Code"
                            type="text"
                            variant="outlined"
                            value="NA"
                            InputProps={{
                                readOnly: true,
                            }}
                            size="small" />
                    </Col>
                    <Col>
                        <TextField
                            id="state"
                            label="State/Region"
                            type="text"
                            variant="outlined"
                            value="NA"
                            InputProps={{
                                readOnly: true,
                            }}
                            size="small" />
                    </Col>
                    <Col>
                        <TextField id="country" label="India" type="text" variant="outlined" size="small" disabled />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField id="dob" label="Date of Birth" type="date" variant="outlined" size="small" className="date-picker" disabled />
                    </Col>
                    <Col>
                        <TextField id="cp" label="Credit Period(Days)" type="text" variant="outlined" size="small" disabled />
                    </Col>
                    <Col>
                        <TextField id="referal" label="Referral ID" type="text" variant="outlined" size="small" disabled />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField id="note" label="Note(for internal use)" type="text" variant="outlined" size="small" disabled />
                    </Col>
                    <Col>
                        <p>How did you come to know about us?</p>

                    </Col>
                    <Col>
                        <TextField id="ms" label="Mention Source" type="text" variant="outlined" size="small" disabled />
                    </Col>
                </Row>
                <hr /><br />
                <Row>
                    <h3>Customer Children Data</h3>
                    <FaPlus />
                </Row>
                <Row>
                    <Col>
                        <TextField id="cn-1" label="Child's Name" type="text" variant="outlined" size="small" disabled />
                    </Col>
                    <Col>
                        <TextField id="cdob-1" label="Date of Birth" type="date" defaultValue="" variant="outlined" size="small" className="date-picker" disabled />
                    </Col>
                    <Col>

                    </Col>
                </Row>
                <hr /><br />
                <Row>
                    <Col>
                        <h3>Customer Delivery Address</h3>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={sameAsAbove}
                                    onChange={() => setSameAsAbove(!sameAsAbove)}
                                    name="checkedB"
                                />
                            }
                            label="Same as above"
                        />
                    </Col>
                    <Col>

                    </Col>
                    <Col>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="medium"
                            startIcon={<FaSave />}
                            onClick={handleClick}
                            fullWidth
                        >
                            Save
                        </Button>
                    </Col>
                    <Col>

                    </Col>
                    <Col>

                    </Col>
                </Row>
            </Container>
        </form>
    )
}

const Container = styled.div`
    margin:20px auto;
`

const Row = styled.div`
    display:flex;
    gap:20px;
    margin-bottom:20px;
    align-items:center;
`

const Col = styled.div`
    flex:1;
`

export default AddCustomerForm
