// import { useEffect } from "react";
// import { useRouter } from "react-router-dom";
// import NProgress from "nprogress";
import Header from "./Header";
import Footer from "./Footer";
import { useSelector } from "react-redux";

const Layout = (props) => {
  // const router = useRouter();
  const state = useSelector((state) => state);
  const auth = state?.auth;

  // useEffect(() => {
  //   const handleStart = (url) => {
  //     NProgress.start();
  //   };
  //   const handleStop = () => {
  //     NProgress.done();
  //   };

  //   router.events.on("routeChangeStart", handleStart);
  //   router.events.on("routeChangeComplete", handleStop);
  //   router.events.on("routeChangeError", handleStop);

  //   return () => {
  //     router.events.off("routeChangeStart", handleStart);
  //     router.events.off("routeChangeComplete", handleStop);
  //     router.events.off("routeChangeError", handleStop);
  //   };
  // }, [router]);

  return (
    <>
      {auth?.action?.data?.name && <Header />}
      {props.children}
      {auth?.action?.data?.name && <Footer />}
    </>
  );
};

export default Layout;
