import { getAwsAuthorization } from "../utils/Signature";
import { toast } from "react-toastify";
export const showPOLoading = () => {
  return {
    type: "SHOW_PO_LOADING",
  };
};

export const hidePOLoading = () => {
  return {
    type: "HIDE_PO_LOADING",
  };
};

export const adminGetAllPO = () => async (dispatch, getState) => {
  const URL =
    "https://cc2sxvpch2.execute-api.ap-south-1.amazonaws.com/alpha/SSSadmingetallpo";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const payload = {
    company: "SSS",
  };
  const authorizationHeaders = getAwsAuthorization(
    AccessKeyId,
    SecretKey,
    SessionToken,
    payload,
    URL
  );
  dispatch(showPOLoading());
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        ...authorizationHeaders,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    dispatch(hidePOLoading());
    if (data.statusCode === 200) {
      dispatch({
        type: "SHOW_PO",
        payload: data.data,
      });
    } else {
      toast.error("Cannot Get Purchase Order Lists", {
        theme: "colored",
      });
    }
  } catch (err) {
    dispatch(hidePOLoading());

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};




export const franchiseGetAllPO = (stores) => async (dispatch, getState) => {
  const URL =
    "https://cc2sxvpch2.execute-api.ap-south-1.amazonaws.com/alpha/SSSadmingetallpo";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const payload = {
    company: "SSS",
  };
  const authorizationHeaders = getAwsAuthorization(
    AccessKeyId,
    SecretKey,
    SessionToken,
    payload,
    URL
  );
  dispatch(showPOLoading());
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        ...authorizationHeaders,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    dispatch(hidePOLoading());
    if (data.statusCode === 200) {
      dispatch({
        type: "SHOW_PO",
        payload: data.data,
      });
    } else {
      toast.error("Cannot Get Purchase Order Lists", {
        theme: "colored",
      });
    }
  } catch (err) {
    dispatch(hidePOLoading());

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};
