import { formatCurrency } from "."

export const reducedClothingTable = (categoryProducts,vendors) => {
    categoryProducts.map( x => {
        vendors.map( y => {
            if(x.vendor === y.id){
                x.vendor = y.name
            }
        })
    })
    
    let data = categoryProducts.reduce((acc, item) => {
        const row = acc.find(p => p.vendor === item.vendor)
        if (row) {            
            let rowTotal = parseFloat(row.total)
            if(item.comboCategories[0] == '102'){
                rowTotal += (item.stock/3)* 1199.00       
            }else if(item.comboCategories[0] == '103'){
                rowTotal += (item.stock/3)* 799.00 
            }else{
                rowTotal += item.stock * parseFloat(item.mrp)
            }
            row.total = formatCurrency(rowTotal)

            row.count = row.count+item.stock
        } 
        else {
            const newProduct = {
                vendor: item.vendor,
                count:item.stock,
            }

            if(item.comboCategories[0] == '102'){
                    newProduct.total = formatCurrency((item.stock/3)* 1199.00)   
            }else if(item.comboCategories[0] == '103'){
                    newProduct.total = formatCurrency((item.stock/3)* 799.00) 
            }else{
                    newProduct.total = formatCurrency(item.stock * parseFloat(item.mrp)) 
            }
            
            acc.push(newProduct) 
        }  
        return acc
    }, []).sort((a,b) => a.vendor.localeCompare(b.vendor))
    return data
}

export const reducedFootwearTable = (categoryProducts, vendors) => {
    categoryProducts.map( x => {
        vendors.map( y => {
            if(x.vendor === y.id){
                x.vendor = y.name
            }
        })
    })

    let data = categoryProducts.reduce((acc, item) => {
        const row = acc.find(p => p.vendor === item.vendor )

        if (row) {
            let rowTotal = parseFloat(row.total)
            if(item.categories[0] == '104' || item.categories[0] == '108'){
                rowTotal += (item.stock/3)* 999.00       
            }else{
                rowTotal += item.stock * parseFloat(item.mrp)
            }
            row.total = formatCurrency(rowTotal)

            row.count = row.count+item.stock
        } 
        else {

            const newProduct = {
                vendor : item.vendor,
                count:item.stock,
            }
            if(item.categories[0] == '104' || item.categories[0] == '108'){
                newProduct.total =  (item.stock/3)* 999.00   
            }else{
                newProduct.total =  item.stock * parseFloat(item.mrp) 
            }
            acc.push(newProduct)
        } 
        return acc
    }, []).sort()
    return data
}

export const reducedImportedTable = (categoryProducts, vendors) => {
    categoryProducts.map( x => {
        vendors.map( y => {
            if(x.vendor === y.id){
                x.vendor = y.name
            }
        })
    })
    let data = categoryProducts.reduce((acc, item) => {
        const row = acc.find(p => p.vendor === item.vendor )

        if (row) {
            let rowTotal = parseFloat(row.total)
            rowTotal += item.stock * parseFloat(item.mrp)
            row.total = formatCurrency(rowTotal)
            row.count = row.count+item.stock
        } 
        else {
            
            const newProduct = {
                vendor : item.vendor,
                count:item.stock,
            }
            newProduct.total = item.stock * parseFloat(item.mrp)
            acc.push(newProduct)
        } 
        return acc
    }, []).sort()
    return data
}