import { useState } from "react";
import { TextField, Button } from "@mui/material";
import styled from "styled-components";
import Card from "../Card";
import { signIn } from "../../actions/authActions";
import { useDispatch, useSelector } from "react-redux";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  return (
    <Container>
      <Row>
        <img src="/images/logo.jpg" alt="Logo" width={64} height={30} />
        <h1>LOGIN</h1>
      </Row>
      <Card>
        <Form>
          <Row>
            <TextField
              id="username"
              label="Username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              variant="outlined"
              fullWidth
              size="small"
            />
          </Row>
          <Row>
            <TextField
              id="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              fullWidth
              size="small"
            />
          </Row>
          <Row>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              onClick={() => dispatch(signIn(username, password))}
              fullWidth
            >
              Log In
            </Button>
          </Row>
        </Form>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  max-width: 400px;
  width: 90%;
  margin: auto;
  margin-top: 200px;
  text-align: center;
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  padding: 20px;
  display: flex;
  gap: 20px;
  justify-content: center;
`;

export default LoginForm;
