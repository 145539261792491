import styled from "styled-components";
import Layout from "../../Layout";
import Breadcrump from "../../Breadcrump";
import AddProductsForm from "../../forms/AddProductsForm";
import { useSelector } from "react-redux";
import RedirectLogin from "../../RedirectLogin";

const AddInventory = () => {
    const auth = useSelector((state) => state.auth);

    return (
        <>
            {!!auth ? (
                <Layout title="Add Products | LITTLEBOX">
                    <Breadcrump
                        path="/Inventory/Add to Existing Inventory"
                        widgets={[]}
                    />
                    <Content className="content-container">
                        <AddProductsForm existing />
                    </Content>
                </Layout>
            ) : (
                <RedirectLogin />
            )}
        </>
    );
};

const Content = styled.div`
    width: 100%;
`;

export default AddInventory;
