import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { formatCurrency } from "./index";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const createPDFfromChallan = (data) => {
  const {
    storeDetails,
    vendorDetails,
    challanProducts,
  } = data;

  const reducedTotalAmount = data.challanProducts.map(x => x.amount).reduce((sum, item) => sum + item, 0)

  const cgstP = data.challanProducts.map(x => parseFloat(x.cgstP))[0]
  const cgstAmount = (cgstP / 100) * reducedTotalAmount

  const sgstP = data.challanProducts.map(x => parseFloat(x.sgstP))[0]
  const sgstAmount = (sgstP / 100) * reducedTotalAmount

  const igstP = data.challanProducts.map(x => parseFloat(x.igstP))[0]
  const igstAmount = (igstP / 100) * reducedTotalAmount

  const grandTotal = reducedTotalAmount + cgstAmount + sgstAmount + igstAmount

  const productTable = challanProducts.map((p) => [
    p.name,
    p.total,
    p.cost,
    formatCurrency(p.amount),

  ]);


  productTable.unshift([
    { text: "Description of Goods", style: "tableHeader" },
    { text: "Quantity", style: "tableHeader" },
    { text: "Price", style: "tableHeader" },
    { text: "Amount", style: "tableHeader" },
  ]);

  productTable.push([
    { text: "Total", style: "tableHeader", colSpan: 3 },
    "",
    "",
    { text: formatCurrency(reducedTotalAmount), style: "tableHeader" },
  ]);

  productTable.push([
    { text: `CGST @ ${cgstP} %`, style: "tableHeader", colSpan: 3 },
    "",
    "",
    { text: formatCurrency(cgstAmount), style: "tableHeader" },
  ]);

  productTable.push([
    { text: `SGST @ ${sgstP} %`, style: "tableHeader", colSpan: 3 },
    "",
    "",
    { text: formatCurrency(sgstAmount), style: "tableHeader" },
  ]);

  productTable.push([
    { text: `IGST @ ${igstP} %`, style: "tableHeader", colSpan: 3 },
    "",
    "",
    { text: formatCurrency(igstAmount), style: "tableHeader" },
  ]);

  productTable.push([
    { text: "Grand Total", style: "tableHeader", colSpan: 3 },
    "",
    "",
    { text: formatCurrency(grandTotal), style: "tableHeader" },
  ]);

  const docDefinition = {
    content: [
      { text: 'Tax Invoice', style: "header" },
      { text: "Vendor Details:", bold: true, },
      `Name : ${vendorDetails[0].name}`,
      `Location : ${vendorDetails[0].state}`,
      `GSTIN : ${vendorDetails[0].gstin}`,
      {
        columns: [
          [
            { text: "\n\nBilled To:", fontSize: 14, bold: true, margin: [0, 0, 0, 5] },
            `Santana Folks LLP ( Guwahati)`,
            `Pramila Mansion Sakuntala Path`,
            `Mathura Nagar Dispur`,
            `Guwahati Kamrup (Assam) - 781006`,
          ],
          [
            { text: "\n\nShipped To:", fontSize: 14, bold: true, margin: [0, 0, 0, 5] },
            `Store Name : ${storeDetails[0].name}`,
            `Store Address : ${storeDetails[0].address}`,
            `Store GSTIN : ${storeDetails[0].gstin}`,
          ],
        ]
      },

      { text: `\n\nChallan No: ${data.challanNumber}`, style: "header" },

      { text: "\nBilling Items:", fontSize: 14, bold: true, margin: [0, 0, 0, 8] },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          body: productTable
        },
        // layout: "noBorders",
      },

    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: false,
        margin: [0, 10, 0, 5],
      },
      tableExample: {
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
    },
  };

  const options = {};

  const newFileName = data.challanNumber; //cryptoRandomString({ length: 20, type: "url-safe" });

  pdfMake.createPdf(docDefinition).download(data.challanNumber);

  return newFileName;
};