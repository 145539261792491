import { useState, useEffect } from "react";

import styled from "styled-components";
import { TextField, CircularProgress } from "@mui/material";
import CardTable from "../CardTable";
import { getInventoryByStoreId } from "../../actions/inventoryActions";
import { useSelector, useDispatch } from "react-redux";
import { getThreeMonthBills } from "../../actions/billsAction";

// TODO choose date

const PWSalesReport = () => {
  const { allBills, billLoading } = useSelector((state) => state.bills);
  const { inventory, inventoryLoading } = useSelector(
    (state) => state.inventory
  );
  const [text, setText] = useState("");
  const [tableData, setTableData] = useState([]);
  const [totalQantity, setTotalQantity] = useState(0);
  const [searchedId, setSearchedId] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (!inventory.length > 0) dispatch(getInventoryByStoreId());
    if (!allBills.length > 0) dispatch(getThreeMonthBills());
  }, []);

  useEffect(() => {
    const products = inventory.filter((p) => p.productId === searchedId);
    if (products.length === 0) {
      setText("No product with id " + searchedId + " found.");
      setTableData([]);
      return;
    } else {
      setText(`Product Name: ${products[0].name}`);
    }

    const weekbills = allBills.filter(
      (b) =>
        new Date(b.soldAt) >
        new Date(new Date().setDate(new Date().getDate() - 7))
    );

    const bills = weekbills.filter((b) => {
      const items = b.products.filter((p) => p.id === searchedId);
      return items.length > 0;
    });

    const data = bills.map((row) => {
      const items = row.products.filter((p) => p.id === searchedId);
      const totalItems = items.reduce((acc, p) => acc + p.count, 0);
      const RenderLink = () => (
        <Anchor href={row.url} target="_blank">
          View PDF
        </Anchor>
      );
      return {
        date: new Date(row.soldAt).toLocaleDateString(),
        link: <RenderLink />,
        barcode: row.barcode,
        name: products[0].name,
        items: totalItems,
      };
    });
    setTableData(data);

    const total = data.reduce((acc, b) => acc + b.items, 0);
    setTotalQantity(total);
  }, [searchedId]);

  return (
    <Content className="content-container">
      {(billLoading || !allBills.length > 0 || inventoryLoading) && (
        <LoadingScreen>
          <CircularProgress size={200} />
        </LoadingScreen>
      )}
      <div>
        <TextField
          label="Search Ids"
          type="text"
          variant="outlined"
          size="small"
          value={searchedId}
          onChange={(e) => setSearchedId(e.target.value)}
        />
      </div>
      {text}
      {tableData.length > 0 ? (
        <>
          <CardTable title="Product Sold" data={tableData} />
          Total Sold in Last Week: {totalQantity}
        </>
      ) : null}
    </Content>
  );
};

const Anchor = styled.a`
  text-decoration: underline;
  color: dodgerblue;
`;

const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(256, 256, 256, 0.7);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default PWSalesReport;
