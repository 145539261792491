import { useState, useEffect } from "react";
import ReactExport from "react-data-export";
import styled from "styled-components";
import { CircularProgress, Button } from "@mui/material";
import { FaFileExcel } from "react-icons/fa";
import CardTable from "../CardTable";
import Card from "../Card";
import { formatCurrency } from "../../utils";
import { createGSTReport } from "../../utils/gstutils";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { getInventoryByStoreId } from "../../actions/inventoryActions";
import {
    getMonthlyBillsByStoreId,
    getPrevMonthBills,
} from "../../actions/billsAction";
import { getGstReports } from "../../actions/billsAction";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const ExportDSR = ({ bills, report, date, name }) => {
    const [loading, setLoading] = useState(true);
    const year = date.getFullYear();
    const month = monthNames[date.getMonth()];
    useEffect(() => {
        if (bills.length > 0) setLoading(false);
    }, [bills]);
    return (
        <div>
            {!loading && (
                <ExcelFile
                    filename={`GST_${name}_${month}_${year}`}
                    element={
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            startIcon={<FaFileExcel />}
                        >
                            EXPORT
                        </Button>
                    }
                >
                    <ExcelSheet data={report} name="Monthly Sales Report">
                        {Object.keys(report[0]).map((item, index) => (
                            <ExcelColumn
                                key={index}
                                label={item}
                                value={item}
                            />
                        ))}
                    </ExcelSheet>
                    <ExcelSheet data={bills} name="All Sales">
                        {Object.keys(bills[0]).map((item, index) => (
                            <ExcelColumn
                                key={index}
                                label={item}
                                value={item}
                            />
                        ))}
                    </ExcelSheet>
                </ExcelFile>
            )}
        </div>
    );
};

const GSTReport = (props) => {
    const { inventory } = useSelector((state) => state.inventory);
    const {
        monthBills,
        lastMonthBillFecthed: fetched,
        gstReport,
        monthBillEmpty,
        todayBills,
        billLoading,
    } = useSelector((state) => state.bills);
    const auth = useSelector((state) => state.auth);
    console.log("auth", auth);
    const [todayData, setTodayData] = useState([]);
    const [allSalesData, setAllSalesData] = useState([]);
    const [pageIndexAllSales, setPageIndexAllSales] = useState(0);

    const [assembled, setAssembled] = useState([]);
    const [daywiseData, setDaywiseData] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);

    const [todayTotalAmt, setTodayTotalAmt] = useState("0.00");
    const [totalGST, setTotalGST] = useState("0.00");
    const [totalRevenue, setTotalRevenue] = useState();
    const [otherMonthGst, setOtherMonthGst] = useState();

    const startDate = props.period;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    //startdate month in letters
    const startDateMonth = monthNames[startDate.getMonth()];
    const dispatch = useDispatch();
    useEffect(() => {
        if (!inventory.length > 0) {
            dispatch(getInventoryByStoreId());
        }
        if (!monthBills.length > 0) {
            dispatch(getMonthlyBillsByStoreId());
        }
        if (!gstReport.length > 0) {
            dispatch(getGstReports());
        }
        // eslint-disable-next-line
    }, []);
    console.log("today data ", todayData);
    useEffect(() => {
        if (fetched) return;
        const date = props.period;
        // console.log("date", date);
        const month = date.getMonth();
        const currentMonth = new Date().getMonth();
        console.log("current month", currentMonth);
        console.log("month", month);
        if (currentMonth > month || (currentMonth === 0 && month === 11)) {
            dispatch(getPrevMonthBills());
        }
        // eslint-disable-next-line
    }, [props.period, fetched]);

    useEffect(() => {
        if (inventory.length > 0) {
            const startDate = props.period;

            const data = monthBills
                .filter(
                    (b) =>
                        new Date(b.soldAt).getMonth() ===
                            new Date(startDate).getMonth() &&
                        new Date(b.soldAt).getFullYear() ===
                            new Date(startDate).getFullYear()
                )
                .sort((a, b) => parseInt(a.barcode) - parseInt(b.barcode))
                .map((row, index) => ({
                    No: index + 1,
                    barcode: row.barcode,
                    date: new Date(row.soldAt).toLocaleDateString(),
                    cash: row.paymentMethod.cash,
                    card: row.paymentMethod.card,
                    UPI: row.paymentMethod.wallet,
                    GST: row.gst,
                    total: row.total,
                }));

            setTodayData(data);

            const dataFetcher = data.slice(
                pageIndexAllSales * 20,
                (pageIndexAllSales + 1) * 20
            );
            setAllSalesData(dataFetcher);
            console.log("start date", startDate);
            const excelData = createGSTReport(
                monthBills.filter(
                    (b) =>
                        new Date(b.soldAt).getMonth() ===
                            new Date(startDate).getMonth() &&
                        new Date(b.soldAt).getFullYear() ===
                            new Date(startDate).getFullYear()
                ),
                startDate,
                inventory
            );
            console.log("excel data", excelData);
            setAssembled(excelData);

            const excelDataFetcher = excelData.slice(
                pageIndex * 10,
                (pageIndex + 1) * 10
            );
            setDaywiseData(excelDataFetcher);

            const total = formatCurrency(
                monthBills.reduce((acc, b) => acc + parseFloat(b.total), 0)
            );
            setTodayTotalAmt(total);

            const gstTotal = formatCurrency(
                monthBills.reduce((acc, b) => acc + parseFloat(b.gst), 0)
            );
            setTotalGST(gstTotal);
        }
    }, [
        todayBills,
        monthBills,
        inventory,
        props,
        pageIndexAllSales,
        pageIndex,
    ]);

    useEffect(() => {
        console.log("Start date full year", startDate.getFullYear());
        console.log("Start date month", startDate.getMonth());
        console.log("full year", currentMonth);
        console.log("Start date full year", currentYear);
        if (
            gstReport &&
            !(
                (startDate.getFullYear() === currentYear &&
                    startDate.getMonth() === currentMonth) ||
                ((startDate.getFullYear() === currentYear - 1 ||
                    startDate.getFullYear() === currentYear) &&
                    (startDate.getMonth() === currentMonth - 1 ||
                        currentMonth === 0))
            )
        ) {
            let temp = gstReport.filter(
                (p) =>
                    p.date === startDateMonth + String(startDate.getFullYear())
            );
            setTotalRevenue(temp[0].totalRevenue);
            setOtherMonthGst(temp[0].totalGst);
        }
        // eslint-disable-next-line
    }, [startDate]);

    return (
        <Content className="content-container">
            {(billLoading || !(monthBillEmpty || daywiseData.length > 0)) && (
                <LoadingScreen>
                    <CircularProgress size={200} />
                </LoadingScreen>
            )}

            <h1>{monthNames[props.period.getMonth()]} month's summary:</h1>
            {!billLoading && (
                <Card>
                    <Col>
                        <h3>Total Revenue</h3>
                        <p>
                            {(startDate.getFullYear() === currentYear &&
                                startDate.getMonth() === currentMonth) ||
                            ((startDate.getFullYear() === currentYear - 1 ||
                                startDate.getFullYear() === currentYear) &&
                                (startDate.getMonth() === currentMonth - 1 ||
                                    currentMonth === 0))
                                ? todayTotalAmt
                                : totalRevenue}
                        </p>
                    </Col>
                    <Col>
                        <h3>Total GST</h3>
                        <p>
                            {(startDate.getFullYear() === currentYear &&
                                startDate.getMonth() === currentMonth) ||
                            ((startDate.getFullYear() === currentYear - 1 ||
                                startDate.getFullYear() === currentYear) &&
                                (startDate.getMonth() === currentMonth - 1 ||
                                    currentMonth === 0))
                                ? totalGST
                                : otherMonthGst}
                        </p>
                    </Col>
                    <Col>
                        <h3>Total Profit</h3>
                        <p>0.00</p>
                    </Col>
                </Card>
            )}
            {(daywiseData.length > 0 &&
                startDate.getFullYear() === currentYear &&
                startDate.getMonth() === currentMonth) ||
            ((startDate.getFullYear() === currentYear - 1 ||
                startDate.getFullYear() === currentYear) &&
                (startDate.getMonth() === currentMonth - 1 ||
                    currentMonth === 0)) ? (
                <>
                    {todayData.length > 0 ? (
                        <ExportDSR
                            bills={todayData}
                            report={assembled}
                            date={startDate}
                            name={auth.action.data.name}
                        />
                    ) : (
                        ""
                    )}

                    <TableContainer>
                        <div>
                            <CardTable title="All Sales" data={allSalesData} />
                            <PaginationArrows>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    disabled={pageIndexAllSales < 1}
                                    startIcon={<FaAngleLeft />}
                                    onClick={() =>
                                        setPageIndexAllSales(
                                            pageIndexAllSales - 1
                                        )
                                    }
                                >
                                    Previous
                                </Button>
                                <span>
                                    {pageIndexAllSales + 1} /{" "}
                                    {Math.ceil(todayData.length / 20)}
                                </span>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    disabled={
                                        pageIndexAllSales >
                                        Math.floor(todayData.length / 20) - 1
                                    }
                                    endIcon={<FaAngleRight />}
                                    onClick={() =>
                                        setPageIndexAllSales(
                                            pageIndexAllSales + 1
                                        )
                                    }
                                >
                                    Next
                                </Button>
                            </PaginationArrows>
                        </div>

                        <div>
                            <CardTable
                                title="Day Wise Sales"
                                data={daywiseData}
                            />
                            <PaginationArrows>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    disabled={pageIndex < 1}
                                    startIcon={<FaAngleLeft />}
                                    onClick={() => setPageIndex(pageIndex - 1)}
                                >
                                    Previous
                                </Button>
                                <span>
                                    {pageIndex + 1} /{" "}
                                    {Math.ceil(assembled.length / 10)}
                                </span>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    disabled={
                                        pageIndex >=
                                        Math.floor(assembled.length / 10) - 1
                                    }
                                    endIcon={<FaAngleRight />}
                                    onClick={() => setPageIndex(pageIndex + 1)}
                                >
                                    Next
                                </Button>
                            </PaginationArrows>
                        </div>
                    </TableContainer>
                </>
            ) : null}
            <br />
            <br />
        </Content>
    );
};

const LoadingScreen = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(256, 256, 256, 0.7);
    z-index: 1100;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const TableContainer = styled.div`
    display: flex;
    gap: 20px;
    & > div {
        flex: 1;
    }
`;
const PaginationArrows = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
`;

const Col = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
`;

export default GSTReport;
