import { useState } from 'react'
import DatePicker from "react-datepicker";
import styled from 'styled-components';

import "react-datepicker/dist/react-datepicker.css";

const CalendarPicker = () => {
    const [startDate, setStartDate] = useState(new Date());
    return (
        <Container>
            <span>Date</span>
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd-MM-yyyy" />
        </Container>
    )
}

const Container = styled.div`
    height:30px;
    display:flex;
    align-items:center;
    gap:10px;
    margin-left:20px;

    & > span{
        font-size:0.9rem;
    }

    & input{
        height:30px;
        width:200px;
        padding:0 5px;
        border:2px solid #aaa;
        border-radius: 8px;
        box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
        transition: all 0.15s ease;    
        &:focus,
        &:active{
            border:2px solid #73C24C;
            outline: none;
            box-shadow:none;
        }    
    }
    
`

export default CalendarPicker
