import { useSelector } from "react-redux";
import styled from "styled-components";
import ProductExchange from "../../productExchange/DeleteDuplicateBill";
import Layout from "../../Layout";
import Breadcrump from "../../Breadcrump";
import RedirectLogin from "../../RedirectLogin";

const Exchange = () => {
    const auth = useSelector((state) => state.auth);
    return (
        <>
            {!!auth ? (
                <Layout title="Delete Duplicate Bills | LITTLEBOX">
                    <Breadcrump
                        path="/Inventory/Delete Duplicate Bills"
                        widgets={[]}
                    />
                    <Content className="content-container">
                        <ProductExchange />
                    </Content>
                </Layout>
            ) : (
                <RedirectLogin />
            )}
        </>
    );
};

const Content = styled.div`
    width: 100%;
`;

export default Exchange;
