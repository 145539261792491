const Footer = () => {
    return (
        <div className="footer">
            <p>
                Created and maintained by{" "}
                <span>
                    <a
                        target="_blank"
                        href="https://goodtribe.io/"
                        rel="noreferrer"
                    >
                        GoodTribe Technology & Marketing Solutions Pvt. Lmt.
                    </a>
                </span>
            </p>
        </div>
    );
};

export default Footer;
