export const VDashboard = {
  name: "Dashboard",
  submenu: false,
  url: "/",
  items: [],
};

export const VSales = {
  name: "Sales",
  submenu: true,
  url: null,
  items: [
    {
      name: "Sales/B2C Bill",
      submenu: false,
      url: "/sales/b2cbill",
      items: [],
    },
    {
      name: "Starting Cash",
      submenu: false,
      url: "/sales/startcash",
      items: [],
    },
    {
      name: "Sales/B2B Bill",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "View Bills",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Consignment Bill",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Challan Consignment",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "View Challan (Consignments)",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Franchise Bill",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "View Franchise Bills",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Sales Return",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Returned Products",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Customer Source",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Customers",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Customer Balance",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Portal Balance",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Customer Receipts",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Referral Points",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Loyalty Setup",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Promotional SMS",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Transactional SMS",
      submenu: false,
      url: null,
      items: [],
    },
  ],
};

export const VPurchase = {
  name: "Purchase",
  submenu: true,
  url: null,
  items: [
    {
      name: "Garment Inward Stock",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Unique Inward Stock",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "View Inward Stock",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Issue PO",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Unique Batch No Issue PO",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "View PO",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Suppliers",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Amount Payable To Supplier",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Supplier Payment Receipt",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Debit Note",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "View Debit Note",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Expense Manager",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Expense Report",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Category Wise Expense Report",
      submenu: false,
      url: null,
      items: [],
    },
  ],
};

export const VInventory = {
  name: "Inventory",
  submenu: true,
  url: null,
  items: [
    {
      name: "Add Products",
      submenu: false,
      url: "/inventory/addproducts",
      items: [],
    },
    {
      name: "Edit/Delete Products",
      submenu: false,
      url: "/inventory/editproducts",
      items: [],
    },
    {
      name: "Products Profile",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Stock Audit",
      submenu: false,
      url: "/inventory/stockaudit",
      items: [],
    },
    {
      name: "Make Kit",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "View Kit",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "View Kit Inward",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Damaged/Used Products",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Barcode Printing",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Discount Master",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "View Flat Discount Products",
      submenu: false,
      url: null,
      items: [],
    },
  ],
};

export const Vendor = {
  name: "Vendor",
  submenu: true,
  url: null,
  items: [
    {
      name: "My Products",
      submenu: false,
      url: "/vendor/myproducts/",
      items: [],
    },
    {
      name: "View My PO",
      submenu: false,
      url: "/vendor/viewpo/",
      items: [],
    },
    {
      name: "View Under Manufacture Products",
      submenu: false,
      url: "/vendor/undermanufacture/",
      items: [],
    },
    {
      name: "Mark For Challan",
      submenu: false,
      url: "/vendor/manufactured/",
      items: [],
    },
  ],
};

export const VStore = {
  name: "Store",
  submenu: true,
  url: null,
  items: [
    {
      name: "Store Profile",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "View Store",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "View Store PO",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Stock Transfer",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "View Stock Transfer",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "View Stock Transfer Detail",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Stock Transfer Inward",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Store Return Products",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "View Return Products",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Manage Return Products",
      submenu: false,
      url: null,
      items: [],
    },
  ],
};

// 3rd Level menu containing subitems
const ReportsSales = {
  name: "Sales",
  submenu: true,
  url: null,
  items: [
    {
      name: "Past Sale Reports",
      submenu: false,
      url: "/reports/sales/pastreports",
      items: [],
    },
    {
      name: "Credit Note Report",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Customer Transaction",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "GST Report",
      submenu: false,
      url: "/reports/sales/gstreport",
      items: [],
    },
    {
      name: "Product Wise Sale Report",
      submenu: false,
      url: "/reports/sales/pwsalereport",
      items: [],
    },
    {
      name: "Profit & Loss Report(Bill Wise)",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Profit & Loss Report(Product Wise)",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Sale GST Report (%) Wise",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Sale Report",
      submenu: false,
      url: "/reports/sales/salereport",
      items: [],
    },
    {
      name: "Supplier Wise Sale Report",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Top Customers",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Top Selling Products",
      submenu: false,
      url: null,
      items: [],
    },
  ],
};

const ReportsPurchase = {
  name: "Purchase",
  submenu: true,
  url: null,
  items: [
    {
      name: "Debit Note Report",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Expense Report",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Inward Report(Invoice Wise)",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Inward Report(Product Wise)",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Inward/GST(%) Wise Report",
      submenu: false,
      url: null,
      items: [],
    },
  ],
};

const ReportsInventory = {
  name: "Inventory",
  submenu: true,
  url: null,
  items: [
    {
      name: "Damage Used Product",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Damage/Used",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Item Report By Store(by size)",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Low Stock Report",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Price Master",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Product Aging Report",
      submenu: false,
      url: "/reports/inventory/productagingreport",
      items: [],
    },
    {
      name: "Product Summary Report",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Stock Audit Report",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Stock Audit Report(Batch Wise)",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Stock Report",
      submenu: false,
      url: null,
      items: [],
    },
  ],
};

const ReportsReminders = {
  name: "Reminders",
  submenu: true,
  url: null,
  items: [
    {
      name: "Customers Birthdays",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Customers Child Birthdays",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Customers Outstanding",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Product Expiry",
      submenu: false,
      url: null,
      items: [],
    },
    {
      name: "Supplier Payments",
      submenu: false,
      url: null,
      items: [],
    },
  ],
};

// Reports
export const VReports = {
  name: "Reports",
  submenu: true,
  url: null,
  items: [ReportsSales, ReportsPurchase, ReportsInventory, ReportsReminders],
};
