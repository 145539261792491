const initialState = {
  inventory: [],
  categories: [],
  vendors: [],
  catalogue: [],
  savedProducts: [],
  utils: {
    url: null,
    enteredDetails: false,
    reset: false,
  },
  discountCategories: [],
  inventoryLoading: false,
};

const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_INVENTORY":
      return {
        ...state,
        inventory: action.payload,
      };
    case "SHOW_SAVED_PRODUCTS":
      return {
        ...state,
        savedProducts: action.payload,
      };
    case "SHOW_CATEGORIES":
      return {
        ...state,
        categories: action.payload,
      };
    case "SHOW_DISCOUNT_CATEGORIES":
      return {
        ...state,
        discountCategories: action.payload,
      };
    case "SHOW_CATALOGUE":
      return {
        ...state,
        catalogue: action.payload,
      };
    case "SHOW_VENDORS":
      return {
        ...state,
        vendors: action.payload,
      };
    case "SET_URL":
      return {
        ...state,
        utils: {
          ...state.utils,
          url: action.payload,
        },
      };
    case "SET_ENTERED_DETAILS":
      return {
        ...state,
        utils: {
          ...state.utils,
          enteredDetails: action.payload,
        },
      };
    case "SET_RESET":
      return {
        ...state,
        utils: {
          ...state.utils,
          reset: action.payload,
        },
      };
    case "RESET_URL":
      return {
        ...state,
        utils: {
          ...state.utils,
          url: action.payload,
        },
      };
    case "SHOW_INVENTORY_LOADING":
      return {
        ...state,
        inventoryLoading: true,
      };
    case "HIDE_INVENTORY_LOADING":
      return {
        ...state,
        inventoryLoading: false,
      };
    default:
      return state;
  }
};

export default inventoryReducer;
