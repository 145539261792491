import React, { useState, useEffect } from "react";
import { TextField, CircularProgress, MenuItem } from "@mui/material";
import CardTable from "../CardTable";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { adminGetBill, adminUpdateBill } from "../../actions/billsAction";
import { adminGetInventory } from "../../actions/inventoryActions";
import { storeIdNumber } from "../../utils/storeIdutils";

const DeleteDuplicateBill = () => {
  const auth = useSelector((state) => state.auth);
  const { billLoading, singleBill } = useSelector((state) => state.bills);
  const { inventory, inventoryLoading } = useSelector(
    (state) => state.inventory
  );

  const [types, setTypes] = useState([]);
  const [bill, setBill] = useState("");
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState();
  const [disabled, setDisabled] = useState(false);
  const [storeId, setStoreId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.action.data.role === "franchiseowner") {
      setTypes([
        {
          value: "",
          label: "None",
        },
        ...JSON.parse(auth.action.data.storeId).map((s) => ({
          value: s,
          label: storeIdNumber[s],
        })),
      ]);
    } else {
      setTypes([
        {
          value: "",
          label: "None",
        },
        {
          value: "1",
          label: "Haus Khas",
        },
        {
          value: "2",
          label: "Borah Service",
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (!inventory.length > 0 && storeId) dispatch(adminGetInventory(storeId));
  }, [storeId]);

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      const newData = data.products.map((item, id) => ({
        id: item.id,
        name: item.name,
        count: item.count,
        size: item.size,
      }));
      setTableData(newData);
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(singleBill).length !== 0) {
      setData(singleBill);
    }
  }, [singleBill]);

  const handleSubmit = async () => {
    setDisabled(false);
    dispatch(adminGetBill(storeId, bill));
  };

  const handleBillProducts = async () => {
    if (Object.keys(data).length > 0) {
      setDisabled(true);
      const { storeId, barcode, products, ...rest } = data;

      dispatch(adminUpdateBill(storeId, barcode, products));

      // clear redux
      setData([]);

      // if (data1?.success) {
      //   const alertText = data1.message;
      //   toast(alertText);
      //   setData([]);
      //   setBill("");
      // } else {
      //   const alertText = "Bill Not Updated";
      //   toast(alertText);
      // }
    }
  };

  return (
    <div>
      {(billLoading || inventoryLoading) && (
        <LoadingScreen>
          <CircularProgress size={200} />
        </LoadingScreen>
      )}
      <TextField
        select
        label="Select Store Location"
        value={storeId}
        onChange={(e) => setStoreId(e.target.value)}
        variant="outlined"
        className="select"
        fullWidth
      >
        {types.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <br />
      <TextField
        label="Search Bill IDs"
        type="text"
        variant="outlined"
        size="small"
        value={bill}
        onChange={(e) => {
          setBill(e.target.value);
        }}
      />

      {bill.length > 0 ? <Submit onClick={handleSubmit}> enter </Submit> : ""}
      {Object.keys(data).length > 0 ? (
        <div>
          <br /> <br />
          <div>
            <h2>Billing Details</h2>
            <br />
            <p>
              <b>Number</b> : {data.barcode}
            </p>
            <p>
              <b>Date</b> : {data.soldAt}
            </p>
            <p>
              <b>Amount </b>: {data.total}
            </p>
            <p>
              <b>Customer Number</b> : {data.customer}
            </p>
          </div>
          <br />
          <hr />
          <p>
            <br />
            <b>Product Details :</b>
          </p>
          <CardTable data={tableData} />
          <br />
          <Submit disabled={disabled} onClick={handleBillProducts}>
            Mark Cancelled
          </Submit>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const Submit = styled.button`
  background-color: #008cba;
  border: none;
  color: white;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;

  &:disabled {
    background-color: red;
  }
`;

const LoadingScreen = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(256, 256, 256, 0.7);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default DeleteDuplicateBill;
