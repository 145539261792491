import { getAwsAuthorization } from "../utils/Signature";
import { toast } from "react-toastify";
export const showInventoryLoading = () => {
  return {
    type: "SHOW_INVENTORY_LOADING",
  };
};

export const hideInventoryLoading = () => {
  return {
    type: "HIDE_INVENTORY_LOADING",
  };
};

export const getInventoryByStoreId = () => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSgetinventoryproducts";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const { storeId } = state.auth.action.data;
  let payload = {
    storeId: storeId,
    LastEvaluatedKey: null,
  };
  dispatch(showInventoryLoading());
  let fetching = true;
  let allProducts = [];
  // let LastEvaluatedKey = null
  try {
    while (fetching) {
      let authorizationHeaders = getAwsAuthorization(
        AccessKeyId,
        SecretKey,
        SessionToken,
        payload,
        URL
      );
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.statusCode === 200) {
        const inventoryProducts = data.data;
        allProducts.push(...inventoryProducts);
        payload.LastEvaluatedKey = data.LastEvaluatedKey;
        if (!payload.LastEvaluatedKey) {
          fetching = false;
        }
      } else {
        allProducts = [];
        fetching = false;
        toast.error("Error", {
          theme: "colored",
        });
      }
    }
    dispatch(hideInventoryLoading());
    dispatch({
      type: "SHOW_INVENTORY",
      payload: allProducts,
    });
  } catch (err) {
    dispatch(hideInventoryLoading());

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const getAllCategories = () => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSgetallcategories";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const payload = {
    storeId: "littlebox",
  };
  const authorizationHeaders = getAwsAuthorization(
    AccessKeyId,
    SecretKey,
    SessionToken,
    payload,
    URL
  );
  dispatch(showInventoryLoading());
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        ...authorizationHeaders,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    dispatch(hideInventoryLoading());
    if (data.statusCode === 200) {
      dispatch({
        type: "SHOW_CATEGORIES",
        payload: data.data,
      });
    } else {
      toast.error("Error", {
        theme: "colored",
      });
    }
  } catch (err) {
    dispatch(hideInventoryLoading());

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const getAllVendors = () => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSgetallvendors";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const payload = {
    storeId: "littlebox",
  };
  const authorizationHeaders = getAwsAuthorization(
    AccessKeyId,
    SecretKey,
    SessionToken,
    payload,
    URL
  );
  dispatch(showInventoryLoading());
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        ...authorizationHeaders,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    dispatch(hideInventoryLoading());
    if (data.statusCode === 200) {
      dispatch({
        type: "SHOW_VENDORS",
        payload: data.data,
      });
    } else {
      toast.error("Error fetching categories", {
        theme: "colored",
      });
    }
  } catch (err) {
    dispatch(hideInventoryLoading());

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const getCatalogues = () => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSgetallcatalogues";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  let payload = {
    storeId: "littlebox",
    LastEvaluatedKey: null,
  };

  dispatch(showInventoryLoading());
  let fetching = true;
  let allCatalogues = [];
  try {
    while (fetching) {
      let authorizationHeaders = getAwsAuthorization(
        AccessKeyId,
        SecretKey,
        SessionToken,
        payload,
        URL
      );

      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();

      if (data.statusCode === 200) {
        const cataloguesProducts = data.data;
        allCatalogues.push(...cataloguesProducts);
        payload.LastEvaluatedKey = data.LastEvaluatedKey;
        if (!payload.LastEvaluatedKey) {
          fetching = false;
        }
      } else {
        allCatalogues = [];
        fetching = false;
        toast.error("Error fetching catalogues", {
          theme: "colored",
        });
      }
    }
    dispatch(hideInventoryLoading());

    dispatch({
      type: "SHOW_CATALOGUE",
      payload: allCatalogues,
    });
  } catch (err) {
    dispatch(hideInventoryLoading());

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const getAllDiscountCategories = () => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSgetalldiscountcategories";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const payload = {
    storeId: "littlebox",
  };
  const authorizationHeaders = getAwsAuthorization(
    AccessKeyId,
    SecretKey,
    SessionToken,
    payload,
    URL
  );
  dispatch(showInventoryLoading());
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        ...authorizationHeaders,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    dispatch(hideInventoryLoading());
    if (data.statusCode === 200) {
      dispatch({
        type: "SHOW_DISCOUNT_CATEGORIES",
        payload: data.data,
      });
    } else {
      toast.error("Error fetching discounts", {
        theme: "colored",
      });
    }
  } catch (err) {
    dispatch(hideInventoryLoading());

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const updateProductCount =
  (selectedProduct) => async (dispatch, getState) => {
    const URL =
      "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSupdateproductstock";
    const state = getState();
    const { AccessKeyId, SecretKey, SessionToken } =
      state.auth.action.credentials;
    const { inventory } = state.inventory;
    const payload = inventory
      .filter(
        (p) =>
          p.productId === selectedProduct.productId && p.size in selectedProduct
      )
      .map((item) => {
        return {
          productId: item.productId,
          storeId: item.storeId,
          barcode: item.barcode,
          size: item.size,
          stock: parseInt(selectedProduct[item.size]),
        };
      });

    const authorizationHeaders = getAwsAuthorization(
      AccessKeyId,
      SecretKey,
      SessionToken,
      payload,
      URL
    );
    dispatch(showInventoryLoading());
    try {
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      dispatch(hideInventoryLoading());
      if (data.statusCode === 200) {
        const updatedProducts = data.data;

        const newInventory = inventory.map((product) => {
          const found = updatedProducts.filter(
            (i) =>
              i.productId === product.productId && i.barcode === product.barcode
          );
          if (found.length > 0) {
            product.stock = found[0].stock;
          }
          return product;
        });
        dispatch({
          type: "SHOW_INVENTORY",
          payload: newInventory,
        });
        toast("updated successfully");
      } else {
        toast.error("Error updating stock", {
          theme: "colored",
        });
      }
    } catch (err) {
      dispatch(hideInventoryLoading());

      toast.error("Something Went Wrong With Connecting To Database", {
        theme: "colored",
      });
    }
  };

export const adminGetInventory = (storeId) => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSgetinventoryproducts";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  dispatch(showInventoryLoading());
  let fetching = true;
  let allProducts = [];
  let payload = {
    storeId: storeId,
    LastEvaluatedKey: null,
  };
  try {
    while (fetching) {
      let authorizationHeaders = getAwsAuthorization(
        AccessKeyId,
        SecretKey,
        SessionToken,
        payload,
        URL
      );
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();

      if (data.statusCode === 200) {
        const inventoryProducts = data.data;
        allProducts.push(...inventoryProducts);
        payload.LastEvaluatedKey = data.LastEvaluatedKey;
        if (!payload.LastEvaluatedKey) {
          fetching = false;
        }
      } else {
        allProducts = [];
        fetching = false;
        toast.error("Error fetching inventory", {
          theme: "colored",
        });
      }
    }
    dispatch(hideInventoryLoading());
    dispatch({
      type: "SHOW_INVENTORY",
      payload: allProducts,
    });
  } catch (err) {
    dispatch(hideInventoryLoading());

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const addToInventory = (productsArray) => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSaddtoinventory";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const { inventory } = state.inventory;
  const payload = productsArray;
  const authorizationHeaders = getAwsAuthorization(
    AccessKeyId,
    SecretKey,
    SessionToken,
    payload,
    URL
  );
  dispatch(showInventoryLoading());
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        ...authorizationHeaders,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    dispatch(hideInventoryLoading());
    if (data.statusCode === 200) {
      let newInventory = inventory;
      newInventory.concat(data.data);
      dispatch({
        type: "SHOW_INVENTORY",
        payload: newInventory,
      });
      dispatch({
        type: "SHOW_SAVED_PRODUCTS",
        payload: data.data,
      });
      toast("updated successfully");
    } else {
      toast.error("Error Updating", {
        theme: "colored",
      });
    }
  } catch (err) {
    dispatch(hideInventoryLoading());

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const addToFranchiseInventory =
  (productsArray) => async (dispatch, getState) => {
    const URL =
      "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSaddtoinventory";
    const state = getState();
    const { AccessKeyId, SecretKey, SessionToken } =
      state.auth.action.credentials;
    const { inventory } = state.inventory;
    const payload = productsArray;
    const authorizationHeaders = getAwsAuthorization(
      AccessKeyId,
      SecretKey,
      SessionToken,
      payload,
      URL
    );
    dispatch(showInventoryLoading());
    try {
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      dispatch(hideInventoryLoading());
      if (data.statusCode === 200) {
        let newInventory = inventory;
        newInventory.concat(data.data);
        dispatch({
          type: "SHOW_INVENTORY",
          payload: newInventory,
        });
        dispatch({
          type: "SHOW_SAVED_PRODUCTS",
          payload: data.data,
        });
        toast("updated successfully");
      } else {
        toast.error("Error Updating", {
          theme: "colored",
        });
      }
    } catch (err) {
      dispatch(hideInventoryLoading());

      toast.error("Something Went Wrong With Connecting To Database", {
        theme: "colored",
      });
    }
  };

export const adminDeleteProduct =
  (id, storeId) => async (dispatch, getState) => {
    const URL =
      "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSadmindeleteproducts";
    const state = getState();
    const { AccessKeyId, SecretKey, SessionToken } =
      state.auth.action.credentials;
    const { inventory } = state.inventory;
    const payload = {
      id,
      storeId,
    };
    const authorizationHeaders = getAwsAuthorization(
      AccessKeyId,
      SecretKey,
      SessionToken,
      payload,
      URL
    );
    dispatch(showInventoryLoading());
    try {
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();

      if (data.statusCode === 200) {
        const productsToBeRemoved = data.data;

        const newInventory = inventory.filter(
          (i) => !productsToBeRemoved.find((p) => p.barcode === i.barcode)
        );

        dispatch({
          type: "SHOW_INVENTORY",
          payload: newInventory,
        });
        toast("Product Deleted Successfully");
      } else {
        toast.error("Error Deleting Product", {
          theme: "colored",
        });
      }
      dispatch(hideInventoryLoading());
    } catch (err) {
      dispatch(hideInventoryLoading());

      toast.error("Something Went Wrong With Connecting To Database", {
        theme: "colored",
      });
    }
  };

export const updateInventoryStock =
  (productsArray) => async (dispatch, getState) => {
    const URL =
      "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSupdateproductstock";
    const state = getState();
    const { AccessKeyId, SecretKey, SessionToken } =
      state.auth.action.credentials;
    const { inventory } = state.inventory;

    const payload = productsArray.map((row) => {
      return {
        barcode: row.barcode,
        storeId: row.storeId,
        stock: row.stock,
        productId: row.productId,
      };
    });

    const authorizationHeaders = getAwsAuthorization(
      AccessKeyId,
      SecretKey,
      SessionToken,
      payload,
      URL
    );
    dispatch(showInventoryLoading());
    try {
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      dispatch(hideInventoryLoading());
      if (data.statusCode === 200) {
        const updatedProducts = data.data;
        const newInventory = inventory.map((product) => {
          const found = updatedProducts.filter(
            (i) =>
              i.productId === product.productId && i.barcode === product.barcode
          );
          if (found.length > 0) {
            product.stock = found[0].stock;
          }
          return product;
        });
        dispatch({
          type: "SHOW_INVENTORY",
          payload: newInventory,
        });
        dispatch({
          type: "SHOW_SAVED_PRODUCTS",
          payload: data.data,
        });
        toast("updated successfully");
      } else {
        toast.error("Error updating stock", {
          theme: "colored",
        });
      }
    } catch (err) {
      dispatch(hideInventoryLoading());

      toast.error("Something Went Wrong With Connecting To Database", {
        theme: "colored",
      });
    }
  };

export const updateFranchiseInventoryStock =
  (productsArray) => async (dispatch, getState) => {
    const URL =
      "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSupdateproductstock";
    const state = getState();
    const { AccessKeyId, SecretKey, SessionToken } =
      state.auth.action.credentials;
    const { inventory } = state.inventory;

    const payload = productsArray.map((row) => {
      return {
        barcode: row.barcode,
        storeId: row.storeId,
        stock: row.stock,
        productId: row.productId,
      };
    });

    const authorizationHeaders = getAwsAuthorization(
      AccessKeyId,
      SecretKey,
      SessionToken,
      payload,
      URL
    );
    dispatch(showInventoryLoading());
    try {
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      dispatch(hideInventoryLoading());
      if (data.statusCode === 200) {
        const updatedProducts = data.data;
        const newInventory = inventory.map((product) => {
          const found = updatedProducts.filter(
            (i) =>
              i.productId === product.productId && i.barcode === product.barcode
          );
          if (found.length > 0) {
            product.stock = found[0].stock;
          }
          return product;
        });
        dispatch({
          type: "SHOW_INVENTORY",
          payload: newInventory,
        });
        dispatch({
          type: "SHOW_SAVED_PRODUCTS",
          payload: data.data,
        });
        toast("updated successfully");
      } else {
        toast.error("Error updating stock", {
          theme: "colored",
        });
      }
    } catch (err) {
      dispatch(hideInventoryLoading());

      toast.error("Something Went Wrong With Connecting To Database", {
        theme: "colored",
      });
    }
  };

export const uploadImage =
  (imageString, productId) => async (dispatch, getState) => {
    const URL =
      "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSuploadproductimagetoS3";
    const state = getState();
    const { AccessKeyId, SecretKey, SessionToken } =
      state.auth.action.credentials;

    const payload = {
      productId,
      dataUrl: imageString,
    };
    const authorizationHeaders = getAwsAuthorization(
      AccessKeyId,
      SecretKey,
      SessionToken,
      payload,
      URL
    );
    dispatch(showInventoryLoading());
    try {
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      dispatch(hideInventoryLoading());
      if (data.statusCode === 200) {
        dispatch({
          type: "SET_URL",
          payload: data.url,
        });
        toast("Image uploaded successfully");
      } else {
        toast.error("Error Uploading Image", {
          theme: "colored",
        });
      }
    } catch (err) {
      dispatch(hideInventoryLoading());
      toast.error("Something Went Wrong With Connecting To Database", {
        theme: "colored",
      });
    }
  };

export const addToCatalogue = (productArray) => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSpostcatalogue";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const { catalogue } = state.inventory;
  const payload = productArray;
  const authorizationHeaders = getAwsAuthorization(
    AccessKeyId,
    SecretKey,
    SessionToken,
    payload,
    URL
  );
  dispatch(showInventoryLoading());
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        ...authorizationHeaders,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    dispatch(hideInventoryLoading());
    if (data.statusCode === 200) {
      let newCatalogue = catalogue;
      newCatalogue.concat(data.data);
      dispatch({
        type: "SHOW_CATALOGUE",
        payload: data.data,
      });
      dispatch(changeReset(true));
      dispatch(changeEnteredDetails(false));
      toast("Saved successfully");
    } else {
      toast.error("Error Saving To Catalogue", {
        theme: "colored",
      });
    }
  } catch (err) {
    dispatch(hideInventoryLoading());
    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const resetImageUrl = () => {
  return {
    type: "RESET_URL",
  };
};

export const changeReset = (state) => {
  return {
    type: "SET_RESET",
    payload: state,
  };
};

export const changeEnteredDetails = (state) => {
  return {
    type: "SET_ENTERED_DETAILS",
    payload: state,
  };
};
