import styled from "styled-components"
import { CircularProgress } from "@mui/material";
import Card from "../Card"

const DisplaySummary = (props) => {
    const { loading, sale, netP, saleR, ...side } = props
    return (
        <Card {...side}>
            <Container>
                <div>
                    {loading ? (
                        <LoadingScreen>
                            <CircularProgress size={25} />
                        </LoadingScreen>
                    ) : (<h2>{sale}</h2>)}

                    <p>Sale ₹</p>
                </div>
                {/* <Break /> */}
                {/* <div>
                    {loading ? (
                        <LoadingScreen>
                            <CircularProgress size={25} />
                        </LoadingScreen>
                    ) : (<h2>{netP}</h2>)}
                    <p>Net Profit ₹</p>
                </div> */}
                <Break />
                <div>
                    {loading ? (
                        <LoadingScreen>
                            <CircularProgress size={25} />
                        </LoadingScreen>
                    ) : (<h2>{saleR}</h2>)}
                    <p>Cash in Hand ₹</p>
                </div>
            </Container>
        </Card>
    )
}

const Container = styled.div`
    max-width:400px;
    display:flex;
    justify-content:space-between;
    margin:auto;
    
    & h2{
        color:red;
    }

    & p{
        font-weight:bold;
        font-size:0.9rem;
    }

    @media (max-width:991px){
        max-width:320px;

        & h2{
            font-size:1.2rem;
        }
    }
`

const Break = styled.div`
    width: 2px;
    height:inherit;
    background-color:red;
`

const LoadingScreen = styled.div`  
  background: rgba(256, 256, 256, 0.7);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default DisplaySummary
