const initialState = {
  monthBills: [],
  todayBills: [],
  gstReport: [],
  allBills: [],
  customers: [],
  customersFromBills: [],
  storeBills: {
    storeId_1: [],
    storeId_2: [],
  },
  singleBill: {},
  billing: {
    isBilled: false,
    customer: null,
    coupons: [],
    lastCode: "",
    billedProducts: [],
    currentProduct: null,
    suggestions: [],
    grandTotal: "0.00",
    grandTotalDiscount: "0.00",
    qntyCount: 0,
    repeatCount: 0,
    appliedCoupon: null,
    cost: "0.00",
    gst: "0.00",
    grandTotal: "0.00",
    lastBill: null,
    nonComboTotal: 0,
    couponDisc: 0,
    bagPrice: 0,
  },
  todayBillEmpty: false,
  monthBillEmpty: false,
  billLoading: false,
  lastMonthBillFecthed: false,
};

const billsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_MONTH_BILLS":
      return {
        ...state,
        monthBills: action.payload,
      };
    case "FETCHED_LAST_MONTH_BILL":
      return {
        ...state,
        lastMonthBillFecthed: action.payload,
      };
    case "SHOW_TODAY_BILLS":
      return {
        ...state,
        todayBills: action.payload,
      };
    case "SHOW_SINGLE_BILL":
      return {
        ...state,
        singleBill: action.payload,
      };
    case "SHOW_ALL_BILLS":
      return {
        ...state,
        allBills: action.payload,
      };
    case "SHOW_BILLS_STORE_1":
      return {
        ...state,
        storeBills: {
          ...state.storeBills,
          storeId_1: action.payload,
        },
      };
    case "SHOW_BILLS_STORE_2":
      return {
        ...state,
        storeBills: {
          ...state.storeBills,
          storeId_2: action.payload,
        },
      };
    case "SHOW_GST_REPORTS":
      return {
        ...state,
        gstReport: action.payload,
      };
    case "SHOW_ALL_CUSTOMERS":
      return {
        ...state,
        customers: action.payload,
      };
    case "SHOW_CUSTOMERS_FROM_BILL":
      return {
        ...state,
        customersFromBills: action.payload,
      };
    case "SHOW_COUPONS":
      return {
        ...state,
        billing: {
          ...state.billing,
          coupons: action.payload,
        },
      };
    case "SET_CUSTOMER":
      return {
        ...state,
        billing: {
          ...state.billing,
          customer: action.payload,
        },
      };
    case "SET_LAST_CODE":
      return {
        ...state,
        billing: {
          ...state.billing,
          lastCode: action.payload,
        },
      };
    case "SET_BILLED_PRODUCTS":
      return {
        ...state,
        billing: {
          ...state.billing,
          billedProducts: action.payload,
        },
      };
    case "SET_CURRENT_PRODUCT":
      return {
        ...state,
        billing: {
          ...state.billing,
          currentProduct: action.payload,
        },
      };
    case "SET_SUGGESTIONS":
      return {
        ...state,
        billing: {
          ...state.billing,
          suggestions: action.payload,
        },
      };
    case "SET_GRAND_TOTAL":
      return {
        ...state,
        billing: {
          ...state.billing,
          grandTotal: action.payload,
        },
      };
    case "SET_GRAND_TOTAL_DISCOUNT":
      return {
        ...state,
        billing: {
          ...state.billing,
          grandTotalDiscount: action.payload,
        },
      };
    case "SET_QNTY_COUNT":
      return {
        ...state,
        billing: {
          ...state.billing,
          qntyCount: action.payload,
        },
      };
    case "SET_REPEAT_COUNT":
      return {
        ...state,
        billing: {
          ...state.billing,
          repeatCount: action.payload,
        },
      };
    case "SET_APPLIED_COUPON":
      return {
        ...state,
        billing: {
          ...state.billing,
          appliedCoupon: action.payload,
        },
      };
    case "SET_COST":
      return {
        ...state,
        billing: {
          ...state.billing,
          cost: action.payload,
        },
      };
    case "SET_GST":
      return {
        ...state,
        billing: {
          ...state.billing,
          gst: action.payload,
        },
      };
    case "SET_NON_COMBO_TOTAL":
      return {
        ...state,
        billing: {
          ...state.billing,
          nonComboTotal: action.payload,
        },
      };
    case "SET_COUPON_DISC":
      return {
        ...state,
        billing: {
          ...state.billing,
          couponDisc: action.payload,
        },
      };
    case "SET_BAG_PRICE":
      return {
        ...state,
        billing: {
          ...state.billing,
          bagPrice: action.payload,
        },
      };
    case "SET_LAST_BILL":
      return {
        ...state,
        billing: {
          ...state.billing,
          lastBill: action.payload,
        },
      };
    case "PRINT_BILL":
      return {
        ...state,
        billing: {
          ...state.billing,
          isBilled: action.payload,
        },
      };
    case "CLEAR_BILLING":
      return {
        ...state,
        billing: {
          ...state.billing,
          billedProducts: [],
          currentProduct: null,
          suggestions: [],
          grandTotal: "0.00",
          grandTotalDiscount: "0.00",
          qntyCount: 0,
          repeatCount: 0,
          appliedCoupon: null,
        },
      };
    case "TODAY_BILL_EMPTY":
      return {
        ...state,
        todayBillEmpty: true,
      };
    case "MONTH_BILL_EMPTY":
      return {
        ...state,
        monthBillEmpty: true,
      };
    case "SHOW_BILL_LOADING":
      return {
        ...state,
        billLoading: true,
      };
    case "HIDE_BILL_LOADING":
      return {
        ...state,
        billLoading: false,
      };
    default:
      return state;
  }
};

export default billsReducer;
