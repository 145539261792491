const initialState = {
  PO: [],
  POLoading: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_PO":
      return {
        ...state,
        PO: action.payload,
      };
    case "SHOW_PO_LOADING":
      return { ...state, POLoading: true };
    case "HIDE_PO_LOADING":
      return { ...state, POLoading: false };
    default:
      return state;
  }
};

export default authReducer;
