import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import CatalogueTable from "./CatalogueTable";
import { getCatalogues } from "../../actions/inventoryActions";

const CatalogueProducts = () => {
  const { inventoryLoading, catalogue } = useSelector(
    (state) => state.inventory
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!catalogue.length > 0) dispatch(getCatalogues());
  }, []);
  return <CatalogueTable products={catalogue} loading={inventoryLoading} />;
};

export default CatalogueProducts;
