import { FaSearch } from "react-icons/fa"
import styled from "styled-components"

const Search = () => {
    return (
        <SearchBox>
            <FaSearch />
            <input type="text" placeholder="Search" />
        </SearchBox>
    )
}

const SearchBox = styled.div`
    display:flex;
    align-items:center;
    gap:10px;
    padding: 5px 10px;
    border:1px solid #999;
    border-radius:99px;

    & > *{
        color: #888;
    }

    & > input{
        border:none;
        outline:none;
        font-size: 0.8rem;
        line-height:0.8rem;
    }
`

export default Search
