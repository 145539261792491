import { FaTimes } from "react-icons/fa"
import { Scrollbars } from 'react-custom-scrollbars-2';

const Modal = ({ show, onClose, children, title, maxWidth }) => {


    const customStyles = {
        maxWidth: maxWidth || '100vw'
    }

    const modalContent = show ? (
        <div className="modal-overlay">
            <div className="modal-container" style={customStyles}>
                <Scrollbars
                    style={{ height: 'calc(100vh - 150px)' }}>
                    <div className="modal-header">
                        <a href="#" onClick={onClose}>
                            <FaTimes />
                        </a>
                        {title && <h3>{title}</h3>}
                    </div>
                    <hr />
                    <div className="modal-body">
                        {children}
                    </div>
                </Scrollbars>
            </div>
        </div>
    ) : null

    return modalContent
}


export default Modal
