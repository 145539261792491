import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setStores } from "../../actions/storeActions"
import { storeIdNumber } from "../../utils/storeIdutils"

const ReportFranchise = () => {
    const auth = useSelector((state) => state.auth.action.data);
    const { storesFranchise } = useSelector((state) => state.stores);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log("AUTH", auth)
        dispatch(setStores(JSON.parse(auth.storeId)))
    }, [auth])

    return (
        <Container>
            {storesFranchise.length > 0 && <h2>Welcome Franchise Admin of
                {storesFranchise.map(s => <span key={s}> {storeIdNumber[s]} </span>)}    Store(s)
            </h2>}

        </Container>
    );
};

const Container = styled.div`
  margin: 20px 20px;
  display: flex;
  flex-direction: column;
`;

export default ReportFranchise;
