import React from "react";

const markForPO = () => {
    return <div>markForPO</div>;
};

export default markForPO;
// import { useState, useEffect, useContext } from "react";
// import styled from "styled-components";
// import { Button } from "@mui/material";
// import Layout from "../../components/Layout";
// import Breadcrump from "../../components/Breadcrump";
// import AuthContext from "../../context/AuthContext";
// import MarkCatalogueProducts from "../../components/purchase/MarkCatalogueProducts";
// import MarkInventoryProducts from "../../components/purchase/MarkInventoryProducts";
// import UploadCsv from "../../components/purchase/UploadCsv";

// const ChoiceButtons = ({ handleChoice }) => (
//   <>
//     <ButtonContainer>
//       <Button
//         onClick={() => handleChoice("old")}
//         color="primary"
//         variant="contained"
//       >
//         Choose from existing
//       </Button>
//       <Button
//         onClick={() => handleChoice("new")}
//         color="primary"
//         variant="contained"
//       >
//         Choose from catalogue
//       </Button>
//     </ButtonContainer>
//     <Container>
//       <Button
//         onClick={() => handleChoice("csv")}
//         color="primary"
//         variant="contained"
//         postion="absolute"
//       >
//         upload CSV
//       </Button>
//     </Container>
//   </>
// );

// const POLists = ({ user }) => {
//   const { setUser } = useContext(AuthContext);
//   const [choice, setChoice] = useState("");

//   useEffect(() => {
//     if (user) setUser(user);
//   }, []);

//   return (
//     <Layout title="Mark Products for PO | LITTLEBOX">
//       <Breadcrump path="/Purchase/Mark Products" widgets={[]} />
//       <Content className="content-container">
//         {choice === "new" ? (
//           <MarkCatalogueProducts handleChoice={setChoice} />
//         ) : choice === "old" ? (
//           <MarkInventoryProducts handleChoice={setChoice} />
//         ) : choice === "csv" ? (
//           <UploadCsv handleChoice={setChoice} />
//         ) : (
//           <ChoiceButtons handleChoice={setChoice} />
//         )}
//       </Content>
//     </Layout>
//   );
// };

// const Content = styled.div`
//   width: 100%;
// `;

// const ButtonContainer = styled.div`
//   display: flex;
//   max-width: 550px;
//   margin: 50px auto;
//   flex-wrap: wrap;
//   justify-content: space-between;
// `;

// const Container = styled.div`
//   & Button {
//     position: absolute;
//     left: 50%;
//     transform: translateX(-50%);
//   }
// `;

// export default POLists;
