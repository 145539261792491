import { useSelector } from "react-redux";
import styled from "styled-components";
import Layout from "../../Layout";
import Breadcrump from "../../Breadcrump";
import CatalogueProducts from "../../stockaudit/CatalogueProducts";
import RedirectLogin from "../../RedirectLogin";

const CatalogueAudit = () => {
    const auth = useSelector((state) => state.auth);

    return (
        <>
            {!!auth ? (
                <Layout title="Stock Audit | LITTLEBOX">
                    <Breadcrump path="/Inventory/Add Products" widgets={[]} />
                    <Content className="content-container">
                        <CatalogueProducts />
                    </Content>
                </Layout>
            ) : (
                <RedirectLogin />
            )}
        </>
    );
};

const Content = styled.div`
    width: 100%;
`;

export default CatalogueAudit;
