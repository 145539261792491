import styled from "styled-components"
import { FaHome } from "react-icons/fa"

import AddCustomer from './widgets/AddCustomer'
import CustomerInfo from "./widgets/CustomerInfo"
import Reference from "./widgets/Reference"
import CalendarPicker from "./widgets/CalendarPicker"

const Breadcrump = ({ path, widgets }) => {
    return (
        <Row>
            <Col>
                <FaHome /><p>Home {path}</p>
            </Col>
            <Col>
                {widgets?.includes('Add') && <AddCustomer hint="Search Customer Name or Mobile Number" />}
                {widgets?.includes('Customer') && <CustomerInfo />}
            </Col>
            <Col>
                {widgets?.includes('Reference') && <Reference hint="Reference" />}
                {widgets?.includes('CalendarPicker') && <CalendarPicker />}
            </Col>
        </Row>
    )
}

const Row = styled.div`
    height: 50px;
    padding-left: 30px;
    display:flex;
    gap:10px;
    justify-content:space-between;
    align-items:center;
    border-bottom: 1px solid #eee;
    margin-top:60px;

    & > * {
        color: #888;
        font-size:0.8em;
    }

    @media (max-width: 991px) {
        flex-direction:column;
        height:auto;
        padding: 10px 30px;
    }
`

const Col = styled.div`
    min-width:100px;
    display:flex;
    gap:10px;
    align-items:center;

    @media (max-width: 767px) {
        flex-direction:column;
    }
`

export default Breadcrump
