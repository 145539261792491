import styled from "styled-components"
const CardTable = (props) => {
    const { title, data } = props


    return (
        <div>
            <Title>{title}</Title>
            <TableBox>
                {data && data.length > 0 && (
                    <Table >
                        <Thead>
                            <tr>
                                {Object.keys(data[0]).map((item, index) => <th key={index}>{item}</th>)}
                            </tr>
                        </Thead>
                        <tbody>
                            {data.map((row, index) => 
                            <tr key={index}>
                                {Object.values(row).map((col, index) => <td key={index}>{col}</td>)}
                            </tr>)}
                        </tbody>
                    </Table>
                )}
            </TableBox>
        </div>
    )
}

const Title = styled.h4`
    font-size:0.8rem;
    font-weight:bold;
    margin-bottom:10px;
`
const Table = styled.table`
    border-spacing:0px;

    & td{
        padding:5px 0 5px 5px;
        border-bottom:1px solid #ddd;
        border-right:1px solid #ddd;
        text-align: left;
    }
`

const Thead = styled.thead`
    & > tr > * {
        padding:8px 0 8px 5px;
        border-bottom:1px solid #ddd;
        border-right:1px solid #ddd;
        text-align: left;
    }
`

const TableBox = styled.div`
    display:flex;
    border-radius: 8px;
    background-color:white;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 10px #ddd;
    
    & > * {
        flex:1;
        font-size:0.85rem;
    }
`

export default CardTable