import { useState } from "react";
import { FaPen, FaTrash } from "react-icons/fa";
import styled from "styled-components";
import EditProductDetails from "./EditProductDetails";
import Modal from "../Modal";
import { getSizesByType } from "../../utils/productUtils";
import { useDispatch } from "react-redux";
import { updateProductCount } from "../../actions/inventoryActions";

const ProductRow = ({ item, index, productType }) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const sizes = getSizesByType(productType);

  const handleChangeCount = async (id, sizes) => {
    const payloadSize = { productId: id };
    console.log("id", id);
    console.log("sizes", sizes);
    for (const size in sizes) {
      if (Number.isNaN(sizes[size])) {
        continue;
      }
      payloadSize[size] = sizes[size];
      // const data = await updateProductCount(item.id, size, sizes[size]);
      // console.log("item", item);
      // console.log("item id", item.id);
      // console.log("sizes", sizes[size]);
    }
    console.log(payloadSize);
    dispatch(updateProductCount(payloadSize));
    setShowModal(false);
  };

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <img
          width="100"
          height="100"
          src={item.url}
          alt="SSS"
        />
      </td>
      <td>{item.id}</td>
      <td>{item.name}</td>
      <td>{item.mrp}</td>
      {sizes.map((k) => (
        <td key={k}>
          <Modal
            show={showModal}
            title={"Change Stock Size"}
            onClose={() => setShowModal(false)}
          >
            <EditProductDetails
              id={item.id}
              productType={productType}
              sizes={item}
              total={item.total}
              sendData={handleChangeCount}
            ></EditProductDetails>
          </Modal>
          {item[k]}
        </td>
      ))}
      <td>{item.total}</td>
      <td>
        <EditContainer>
          <FaPen
            onClick={() => {
              setShowModal(true);
            }}
          />
        </EditContainer>
      </td>
    </tr>
  );
};

const EditContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20%;

  & > svg {
    font-size: 1.3rem;
    cursor: pointer;
  }
`;

export default ProductRow;
