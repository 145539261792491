import styled from "styled-components";
import { CircularProgress, Button } from "@mui/material";
import { useEffect, useState } from "react";
import CardTable from "../CardTable";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { getAllCustomers } from "../../actions/billsAction";
import { adminGetBills } from "../../actions/billsAction";

const ReportAdmin = () => {
  const { customers, billLoading, storeBills } = useSelector(
    (state) => state.bills
  );
  const { storeId_1, storeId_2 } = storeBills;
  const [customersStore1, setCustomersStore1] = useState([]);
  const [customersStore2, setCustomersStore2] = useState([]);
  const [countForNewCustomerStore1, setCountForNewCustomerStore1] = useState(0);
  const [countForNewCustomerStore2, setCountForNewCustomerStore2] = useState(0);
  const [pageIndex1, setPageIndex1] = useState(0);
  const [pageIndex2, setPageIndex2] = useState(0);
  const [paginatedCustomerStore1, setPaginatedCustomerStore1] = useState();

  const [paginatedCustomerStore2, setPaginatedCustomerStore2] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!customers.length > 0) dispatch(getAllCustomers());
    if (!storeId_1.length > 0) dispatch(adminGetBills("1"));
    if (!storeId_2.length > 0) dispatch(adminGetBills("2"));
  }, []);

  useEffect(() => {
    if (customers.length > 0) {
      getcustomers();
    }
  }, [customers, storeId_1, storeId_2]);

  useEffect(() => {
    const paginatedCustomerForStore1 = customersStore1.slice(
      pageIndex1 * 10,
      (pageIndex1 + 1) * 10
    );
    setPaginatedCustomerStore1(paginatedCustomerForStore1);
  }, [customersStore1, pageIndex1]);

  useEffect(() => {
    const paginatedCustomerForStore2 = customersStore2.slice(
      pageIndex2 * 10,
      (pageIndex2 + 1) * 10
    );
    setPaginatedCustomerStore2(paginatedCustomerForStore2);
  }, [customersStore2, pageIndex2]);

  const getcustomers = async () => {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    let countForStore1 = 0;
    let countForStore2 = 0;
    let monthCustomer = storeId_1.map((b) => b.customer);

    const allCustomerForStore1 = customers.filter((p) =>
      p.stores.includes("1")
    );

    allCustomerForStore1.forEach((row) => {
      let found = monthCustomer.find((c) => c === row.mobileNo);

      if (!found) {
        return;
      }

      if (
        new Date(row.date).getMonth() === month &&
        new Date(row.date).getFullYear() === year
      ) {
        countForStore1++;
        setCustomersStore1((prev) => [
          ...prev,
          { customer: row.mobileNo, status: "new customer" },
        ]);
      } else {
        setCustomersStore1((prev) => [
          ...prev,
          { customer: row.mobileNo, status: "old customer" },
        ]);
      }
    });

    const allCustomerForStore2 = customers.filter((p) =>
      p.stores.includes("2")
    );

    monthCustomer = storeId_2.map((b) => b.customer);

    allCustomerForStore2.forEach((row) => {
      let found = monthCustomer.find((c) => c === row.mobileNo);
      if (!found) {
        return;
      }

      if (
        new Date(row.date).getMonth() === month &&
        new Date(row.date).getFullYear() === year
      ) {
        countForStore2++;
        setCustomersStore2((prev) => [
          ...prev,
          { customer: row.mobileNo, status: "new customer" },
        ]);
      } else {
        setCustomersStore2((prev) => [
          ...prev,
          { customer: row.mobileNo, status: "old customer" },
        ]);
      }
    });

    setCountForNewCustomerStore1(countForStore1);
    setCountForNewCustomerStore2(countForStore2);
  };

  return (
    <Container>
      {billLoading && (
        <LoadingScreen>
          <CircularProgress size={200} />
        </LoadingScreen>
      )}
      <br />
      <h2>
        new customer for Haus Khaus :
        {customersStore1.length > 0
          ? (countForNewCustomerStore1 / customersStore1.length) * 100
          : "0"}
        %
      </h2>

      <h2>
        new customer for Borah Service:{" "}
        {customersStore2.length > 0
          ? (countForNewCustomerStore2 / customersStore2.length) * 100
          : "0"}
        %
      </h2>

      <br />
      <br />

      <TableContainer>
        <div>
          <CardTable
            title="Customer Borah Service"
            data={paginatedCustomerStore2}
          />
          <PaginationArrows>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={pageIndex2 < 1}
              startIcon={<FaAngleLeft />}
              onClick={() => setPageIndex2(pageIndex2 - 1)}
            >
              Previous
            </Button>
            <span>
              {pageIndex2 + 1} / {Math.ceil(customersStore2.length / 10)}
            </span>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={
                pageIndex2 > Math.floor(customersStore2.length / 10) - 1
              }
              endIcon={<FaAngleRight />}
              onClick={() => setPageIndex2(pageIndex2 + 1)}
            >
              Next
            </Button>
          </PaginationArrows>
        </div>
        <div>
          <CardTable
            title="Customer Haus Khaus"
            data={paginatedCustomerStore1}
          />
          <PaginationArrows>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={pageIndex1 < 1}
              startIcon={<FaAngleLeft />}
              onClick={() => setPageIndex1(pageIndex1 - 1)}
            >
              Previous
            </Button>
            <span>
              {pageIndex1 + 1} / {Math.ceil(customersStore1.length / 10)}
            </span>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={
                pageIndex1 > Math.floor(customersStore1.length / 10) - 1
              }
              endIcon={<FaAngleRight />}
              onClick={() => setPageIndex1(pageIndex1 + 1)}
            >
              Next
            </Button>
          </PaginationArrows>
        </div>
      </TableContainer>
    </Container>
  );
};

const PaginationArrows = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
`;

const Container = styled.div`
  margin: 20px 20px;
  display: flex;
  flex-direction: column;
`;

const TableContainer = styled.div`
  display: flex;
  gap: 20px;
  & > div {
    flex: 1;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const Break = styled.div`
  width: 2px;
  height: inherit;
  background-color: red;
`;

const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(256, 256, 256, 0.7);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ReportAdmin;
