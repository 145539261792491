import styled from "styled-components"
import NavItem from "./NavItem"

const SubNav = ({ items }) => {
    return (
        <Container>
            {items.map((item, index) => {
                if (item.submenu) {
                    return <NavItem key={index} title={item.name} url={item.url} data={item} bold third />
                } else {
                    return <NavItem key={index} title={item.name} url={item.url} bold />
                }
            })}
        </Container>
    )
}

const Container = styled.div`
    width: 300px;
    min-height:100px;
    background-color:#fff;

    & > div{
        padding:7px 10px;
        color: #3f3f3f;
    }
`

export default SubNav
