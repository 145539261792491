import { useState, useEffect } from "react";
import styled from "styled-components";
import {
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from "@mui/material";
import CardTable from "../CardTable";
import { formatCurrency } from "../../utils";
import ExportDSR from "./ExportDSR";

import { useSelector, useDispatch } from "react-redux";
import {
    getAllCategories,
    getInventoryByStoreId,
} from "../../actions/inventoryActions";
import { getStoreDailyCash } from "../../actions/expensesActions";
import { getMonthlyBillsByStoreId } from "../../actions/billsAction";

const SalesReport = (props) => {
    const [sortValue, setSortValue] = useState("Descending");
    const { inventory, inventoryLoading, categories } = useSelector(
        (state) => state.inventory
    );
    const { monthBills, todayBills, billLoading } = useSelector(
        (state) => state.bills
    );
    const { expenseLoading, storeDailyCash } = useSelector(
        (state) => state.expenses
    );
    const [exportBills, setExportBills] = useState([]);
    const [todayData, setTodayData] = useState([]);
    const [todayTotalAmt, setTodayTotalAmt] = useState("0.00");
    const [cashReport, setCashReport] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!inventory.length > 0) dispatch(getInventoryByStoreId());
        if (!monthBills.length > 0) dispatch(getMonthlyBillsByStoreId());
        if (!categories.length > 0) dispatch(getAllCategories());
        if (!storeDailyCash.length > 0) dispatch(getStoreDailyCash());
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const bills = !props.date
            ? todayBills
            : monthBills.filter(
                  (b) =>
                      new Date(b.soldAt).toLocaleDateString() ===
                      new Date(props.date).toLocaleDateString()
              );

        setExportBills(bills);

        const data = bills
            .sort((a, b) => {
                if (sortValue === "Descending") {
                    return parseInt(b.barcode) - parseInt(a.barcode);
                } else {
                    return parseInt(a.barcode) - parseInt(b.barcode);
                }
            })
            .map((row) => {
                const totalItems = row.products.reduce(
                    (acc, p) => acc + p.count,
                    0
                );
                const RenderLink = () => (
                    <Anchor href={row.url} target="_blank">
                        View PDF
                    </Anchor>
                );
                return {
                    link: <RenderLink />,
                    barcode: row.barcode,
                    items: totalItems,
                    coupon: row.coupon?.name,
                    discount: row.discount,
                    cash: row.paymentMethod.cash,
                    card: row.paymentMethod.card,
                    UPI: row.paymentMethod.wallet,
                    customerMobile: row.customer,
                    total: row.total,
                };
            });
        setTodayData(data);

        const dcashDate = props.date
            ? props.date
            : new Date().toLocaleDateString();

        const dCash = storeDailyCash.filter(
            (c) =>
                new Date(c.date).toLocaleDateString() ===
                new Date(dcashDate).toLocaleDateString()
        )[0];
        setCashReport([
            {
                Date: dCash?.date,
                Opening_Balance: dCash?.opening,
                Total_Sale_by_Cash: dCash?.cashSale,
                Advance_Cash: dCash?.advanceCash,
                Bank_Deposit: dCash?.deposit,
                Staff_Welfare_and_Tea: dCash?.staff,
                Stationary_and_Promotion: dCash?.stationary,
                House_Keeping: dCash?.housekeeping,
                Transport_and_Loading: dCash?.transport,
                Wifi_Bill: dCash?.wifi,
                Electricity_Bill: dCash?.electricity,
                Maintanance_and_IT_Equipment: dCash?.maintanance,
                Marketing_Expenses: dCash?.marketing,
                Closing_Balance: dCash?.closing,
            },
        ]);

        const total = formatCurrency(
            bills.reduce((acc, b) => acc + parseFloat(b.total), 0)
        );
        setTodayTotalAmt(total);
        setLoading(false);
    }, [
        loading,
        expenseLoading,
        todayBills,
        monthBills,
        storeDailyCash,
        props,
        sortValue,
    ]);

    return (
        <Content className="content-container">
            {(billLoading || expenseLoading || inventoryLoading || loading) && (
                <LoadingScreen>
                    <CircularProgress size={200} />
                </LoadingScreen>
            )}
            <h1>Total sale: {todayTotalAmt}</h1>
            <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                    Sort By
                </FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={sortValue}
                    onChange={(e) => {
                        setSortValue(e.target.value);
                    }}
                >
                    <FormControlLabel
                        value="Ascending"
                        control={<Radio />}
                        label="Ascending"
                    />
                    <FormControlLabel
                        value="Descending"
                        control={<Radio />}
                        label="Descending"
                    />
                </RadioGroup>
            </FormControl>
            {todayData.length > 0 ? (
                <>
                    <ExportDSR
                        date={
                            props.date
                                ? new Date(props.date).toLocaleDateString()
                                : new Date().toLocaleDateString()
                        }
                        tableData={todayData}
                        cashReport={cashReport}
                        bills={exportBills}
                        productList={inventory}
                        categoryList={categories}
                    />
                    <CardTable title="Today's Sales" data={todayData} />
                </>
            ) : null}
        </Content>
    );
};

const Anchor = styled.a`
    text-decoration: underline;
    color: dodgerblue;
`;

const LoadingScreen = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(256, 256, 256, 0.7);
    z-index: 1100;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export default SalesReport;
