import { useSelector } from "react-redux";
import styled from "styled-components";
import Layout from "../../Layout";
import Breadcrump from "../../Breadcrump";
import BillingItems from "../../b2bbill/BillingItems";
import CashMemo from "../../b2bbill/CashMemo";
import RedirectLogin from "../../RedirectLogin";

const B2CBill = () => {
    const widgets = ["Add", "Customer", "Reference", "CalendarPicker"];

    const auth = useSelector((state) => state.auth);

    return (
        <>
            {!!auth ? (
                <Layout title="B2C Billing | LITTLEBOX">
                    <Breadcrump path="/Sales/B2C Bills" widgets={widgets} />
                    <Content className="content-container">
                        <BillingItems />
                        <CashMemo />
                    </Content>
                </Layout>
            ) : (
                <RedirectLogin />
            )}
        </>
    );
};

const Content = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 20px;

    @media (max-width: 1200px) {
        grid-template-columns: 1fr;
    }
`;

export default B2CBill;
