import React, { useEffect } from "react";
import LoginForm from "./forms/LoginForm";
import { useSelector, useDispatch } from "react-redux";
import { loadUser } from "../actions/authActions";
const RedirectLogin = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth) dispatch(loadUser());
  }, [dispatch]);
  return (
    <div>
      <LoginForm />
    </div>
  );
};

export default RedirectLogin;
