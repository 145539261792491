import { useSelector } from "react-redux";
import styled from "styled-components";
import Layout from "../../Layout";
import Breadcrump from "../../Breadcrump";
import EditProductsTable from "../../addproducts/EditProductsTable";
import RedirectLogin from "../../RedirectLogin";

const EditProducts = () => {
    const auth = useSelector((state) => state.auth);
    return (
        <>
            {!!auth ? (
                <Layout title="Edit Products | LITTLEBOX">
                    <Breadcrump path="/Inventory/Edit Products" widgets={[]} />
                    <Content className="content-container">
                        <EditProductsTable />
                    </Content>
                </Layout>
            ) : (
                <RedirectLogin />
            )}
        </>
    );
};

const Content = styled.div`
    width: 100%;
`;

export default EditProducts;
