import { useState, useEffect } from "react";
import styled from "styled-components";
import { TextField, MenuItem } from "@mui/material";

import CardTable from "../CardTable";
import {
  reducedClothingTable,
  reducedFootwearTable,
  reducedImportedTable,
} from "../../utils/reportutils";
import { formatCurrency } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import {
  getInventoryByStoreId,
  getAllCategories,
  getAllVendors,
} from "../../actions/inventoryActions";

const EveryDayStockReport = () => {
  const { inventory, categories, vendors } = useSelector(
    (state) => state.inventory
  );
  const [tableProducts, setTableProducts] = useState([]);
  const [productType, setProductType] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [totalValue, setTotalValue] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!inventory.length > 0) dispatch(getInventoryByStoreId());
    if (!categories.length > 0) dispatch(getAllCategories());
    if (!vendors.length > 0) dispatch(getAllVendors());
  }, []);

  useEffect(() => createTableData(), [inventory, productType, productCategory]);

  const types = [
    {
      value: "",
      label: "None",
    },
    {
      value: "clothing",
      label: "Clothing",
    },
    {
      value: "footwear",
      label: "Footwear",
    },
    {
      value: "imported",
      label: "Imported",
    },
    {
      value: "accessories",
      label: "Accessories",
    },
  ];

  const cats = [
    { value: "", label: "NA" },
    ...categories
      .filter((c) => c.type === productType)
      .map((c) => ({ value: c.categoryId, label: c.name })),
  ];

  const createTableData = () => {
    const validProducts = inventory.filter(
      (p) => !p.productId?.startsWith("000")
    );
    const typeProducts = validProducts.filter((p) => p.type === productType);
    const categoryProducts =
      productCategory === ""
        ? typeProducts
        : typeProducts.filter(
          (p) =>
            p.categories.length > 0 && p.categories?.includes(productCategory)
        );

    switch (productType) {
      case "clothing":
        let reduceCloth = reducedClothingTable(categoryProducts, vendors);
        const clothSumValue = formatCurrency(
          reduceCloth.reduce((acc, item) => parseFloat(item.total) + acc, 0)
        );
        setTableProducts(reduceCloth);
        setTotalValue(clothSumValue);
        break;
      case "footwear":
        let reduceFootwear = reducedFootwearTable(categoryProducts, vendors);
        const footwearSumValue = formatCurrency(
          reduceFootwear.reduce((acc, item) => parseFloat(item.total) + acc, 0)
        );
        setTableProducts(reduceFootwear);
        setTotalValue(footwearSumValue);
        break;
      case "imported":
        let reduceImported = reducedImportedTable(categoryProducts, vendors);
        const importedSumValue = formatCurrency(
          reduceImported.reduce((acc, item) => parseFloat(item.total) + acc, 0)
        );
        setTableProducts(reduceImported);
        setTotalValue(importedSumValue);
        break;
      default:
        break;
    }
  };

  return (
    <Content className="content-container">
      <h1>Store's Total Value</h1>
      <Container>
        <h3>Choose Product Type</h3>
        <br />
        <TextField
          select
          label="Select Product Type"
          value={productType}
          onChange={(e) => setProductType(e.target.value)}
          variant="outlined"
          className="select"
          fullWidth
        >
          {types.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Container>

      {productType && (
        <Container>
          <div>
            <TextField
              select
              label="Select Product Category"
              value={productCategory}
              onChange={(e) => setProductCategory(e.target.value)}
              variant="outlined"
              className="select"
              fullWidth
            >
              {cats.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <br />
          </div>
          <br />
          <br />
          <h1>
            Estimated {productType} value: ₹ {totalValue}
          </h1>
          <TableContainer>
            {tableProducts.length > 0 && <CardTable data={tableProducts} />}
          </TableContainer>
        </Container>
      )}
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableContainer = styled.div`
  display: flex;
  gap: 20px;
  & > div {
    flex: 1;
  }
`;
const Container = styled.div`
  margin-top: 50px;
`;
const LoadingScreen = styled.div`
  width: 100%;
  height: 50vh;
  background: rgba(256, 256, 256, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default EveryDayStockReport;
