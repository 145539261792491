import { useState, useEffect } from 'react'
import { TextField, Button } from '@mui/material';
import styled from 'styled-components'
import { FaHandHoldingUsd, FaCheck } from 'react-icons/fa'
import { formatCurrency } from '../../utils';

const DenominationRecorder = ({ onSubmit, setCash }) => {
    const [total, setTotal] = useState(0)
    const [twoThousand, setTwoThousand] = useState(0)
    const [fiveHundred, setFiveHundred] = useState(0)
    const [twoHundred, setTwoHundred] = useState(0)
    const [oneHundred, setOneHundred] = useState(0)
    const [fifty, setFifty] = useState(0)
    const [twenty, setTwenty] = useState(0)
    const [ten, setTen] = useState(0)
    const [change, setChange] = useState(0)
    const [returnAmt, setReturnAmt] = useState(0)

    useEffect(() => {
        setCash(formatCurrency(total))
    }, [total])

    const calculateReturn = () => {
        const givenTotal = twoThousand * 2000 + fiveHundred * 500 + twoHundred * 200 + oneHundred * 100 + fifty * 50 + twenty * 20 + ten * 10 + change
        setReturnAmt(givenTotal - total)
    }

    return (
        <form noValidate autoComplete="off">
            <Container>
                <Row>
                    <Col></Col>
                    <Col>Bill Amount</Col>
                    <Col>
                        <TextField
                            label="Total"
                            type="text"
                            variant="outlined"
                            value={total}
                            onChange={(e) => setTotal(parseInt(e.target.value))}
                            size="small" />
                    </Col>
                    <Col></Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <TextField
                            label="2000 notes"
                            type="text"
                            variant="outlined"
                            value={twoThousand}
                            onChange={(e) => setTwoThousand(parseInt(e.target.value))}
                            size="small" />
                    </Col>
                    <Col>
                        <TextField
                            label="500 notes"
                            type="text"
                            variant="outlined"
                            value={fiveHundred}
                            onChange={(e) => setFiveHundred(parseInt(e.target.value))}
                            size="small" />
                    </Col>
                    <Col>
                        <TextField
                            label="200 notes"
                            type="text"
                            variant="outlined"
                            value={twoHundred}
                            onChange={(e) => setTwoHundred(parseInt(e.target.value))}
                            size="small" />
                    </Col>
                    <Col>
                        <TextField
                            label="100 notes"
                            type="text"
                            variant="outlined"
                            value={oneHundred}
                            onChange={(e) => setOneHundred(parseInt(e.target.value))}
                            size="small" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                            label="50 notes"
                            type="text"
                            variant="outlined"
                            value={fifty}
                            onChange={(e) => setFifty(parseInt(e.target.value))}
                            size="small" />
                    </Col>
                    <Col>
                        <TextField
                            label="20 notes"
                            type="text"
                            variant="outlined"
                            value={twenty}
                            onChange={(e) => setTwenty(parseInt(e.target.value))}
                            size="small" />
                    </Col>
                    <Col>
                        <TextField
                            label="10 notes"
                            type="text"
                            variant="outlined"
                            value={ten}
                            onChange={(e) => setTen(parseInt(e.target.value))}
                            size="small" />
                    </Col>
                    <Col>
                        <TextField
                            label="change amount"
                            type="text"
                            variant="outlined"
                            value={change}
                            onChange={(e) => setChange(parseInt(e.target.value))}
                            size="small" />
                    </Col>
                </Row>
                <hr /><br />
                <Row>
                    <Col>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="medium"
                            startIcon={<FaHandHoldingUsd />}
                            onClick={calculateReturn}
                            fullWidth
                        >
                            Calculate Return
                        </Button>
                    </Col>
                    <Col>

                    </Col>
                    <Col>
                        <h4>Return {returnAmt}</h4>
                    </Col>
                </Row>
                <hr /><br />
                <Row>
                    <Col>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="medium"
                            startIcon={<FaCheck />}
                            onClick={onSubmit}
                            fullWidth
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            </Container>
        </form>
    )
}

const Container = styled.div`
    margin:20px auto;
`

const Row = styled.div`
    display:flex;
    gap:20px;
    margin-bottom:20px;
    align-items:center;
`

const Col = styled.div`
    flex:1;
`

export default DenominationRecorder
