import { useSelector } from "react-redux";
import styled from "styled-components"

const Card = (props) => {
    const { active } = useSelector(state => state.nav)
    const { children, ...others } = props

    return (
        <FlexBox {...others} overlay={active}>
            {children}
        </FlexBox>
    )
}

const FlexBox = styled.div`
        display:flex;
        background-color:white;
        box-shadow: 0px 0px 10px #ddd;
        padding:20px 0;
        border-radius: ${props => props.right ? "0 8px 8px 0" : props.left ? "8px 0 0 8px" : "8px"};
        clip-path: ${props => props.overlay ? "none" : props.left ? "inset(-8px 0px -8px -8px )" : props.right ? "inset(-8px -8px -8px 0px )" : "none"};
        
        @media(max-width: 767px) {
            border-radius: 8px;
            clip-path: none;
        }

        & > * {
            flex:1;
            text-align:center;
        }
    `

export default Card
