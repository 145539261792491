import { getAwsAuthorization } from "../utils/Signature";
import { toast } from "react-toastify";
export const showInventoryLoading = () => {
  return {
    type: "SHOW_CHALLAN_LOADING",
  };
};

export const hideInventoryLoading = () => {
  return {
    type: "HIDE_CHALLAN_LOADING",
  };
};

export const getChallanByStoreId = () => async (dispatch, getState) => {
  const URL =
    "https://cc2sxvpch2.execute-api.ap-south-1.amazonaws.com/alpha/goodtribegetchallanbycompanyorstore";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const { storeName } = state.auth.action.data.store;
  const payload = {
    company: "SSS",
    name: storeName,
  };
  const authorizationHeaders = getAwsAuthorization(
    AccessKeyId,
    SecretKey,
    SessionToken,
    payload,
    URL
  );
  dispatch(showInventoryLoading());
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        ...authorizationHeaders,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    dispatch(hideInventoryLoading());
    if (data.statusCode === 200) {
      dispatch({
        type: "SHOW_CHALLANS",
        payload: data.data,
      });
    } else {
      toast.error("Error Fetching Challans", {
        theme: "colored",
      });
    }
  } catch (err) {
    dispatch(hideInventoryLoading());

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};
