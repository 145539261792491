import { useSelector } from "react-redux";
import styled from "styled-components";
import Layout from "../../Layout";
import Breadcrump from "../../Breadcrump";

import EditStockTable from "../../editstockproductsmanager/EditStockTable";
import RedirectLogin from "../../RedirectLogin";

const StockAudit = () => {
    const auth = useSelector((state) => state.auth);

    return (
        <>
            {!!auth ? (
                <Layout title="Edit Product Stock | LITTLEBOX">
                    <Breadcrump
                        path="/Inventory/Edit Product Stock"
                        widgets={[]}
                    />
                    <Content className="content-container">
                        <EditStockTable />
                    </Content>
                </Layout>
            ) : (
                <RedirectLogin />
            )}
        </>
    );
};

const Content = styled.div`
    width: 100%;
`;

export default StockAudit;
