import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";

import Home from "./components/pages/index";
import AddInventory from "./components/pages/inventory/addinventory";
import AddInventoryExcel from "./components/pages/inventory/addinventoryexcel";
import AddProducts from "./components/pages/inventory/addproducts";
import CatalogueAudit from "./components/pages/inventory/catalogueaudit";
import DuplicateBill from "./components/pages/inventory/duplicateBill";
import EditProducts from "./components/pages/inventory/editproducts";
import EditProductsManager from "./components/pages/inventory/editproductsmanager";
import ManageCatalogue from "./components/pages/inventory/managecatalogue";
import ManageCatalogueAudit from "./components/pages/inventory/managercatalogueaudit";
import StockAudit from "./components/pages/inventory/stockaudit";

import MarkForPO from "./components/pages/purchase/markForPO";
import POCheckout from "./components/pages/purchase/poCheckout";
import Polists from "./components/pages/purchase/polists";
import ViewChallan from "./components/pages/purchase/viewChallan";

import DeleteGSTBills from "./components/pages/reports/deleteGstBills";
import ExportCustomer from "./components/pages/reports/exportcustomer";
import EveryDayStock from "./components/pages/reports/inventory/everydaystock";
import MultipleDisplay from "./components/pages/reports/inventory/multipledisplay";
import ProductAgingReport from "./components/pages/reports/inventory/productagingreport";
import Replenished from "./components/pages/reports/inventory/replenished";
import ExportReport from "./components/pages/reports/sales/exportreport";
import Gstreport from "./components/pages/reports/sales/gstreport";
import PastReports from "./components/pages/reports/sales/pastreports";
import PWSaleReport from "./components/pages/reports/sales/pwsalereport";
import SaleReport from "./components/pages/reports/sales/salereport";

import B2CBills from "./components/pages/sales/b2cbill";
import StartCash from "./components/pages/sales/startcash";

import StoreExpenses from "./components/pages/store/storeexpenses";

import ChallanProducts from "./components/pages/vendor/challanproducts";
import Manufactured from "./components/pages/vendor/manufactured";
import MyProducts from "./components/pages/vendor/myproducts";
import UnderManufacture from "./components/pages/vendor/undermanufacture";
import ViewPo from "./components/pages/vendor/viewpo";
import DetailedPO from "./components/pages/vendor/detailedpo";
import StoreDetails from "./components/pages/vendor/storedetails";

import "./App.css";

function App() {
    const dispatch = useDispatch();
    useEffect(() => {
        // dispatch(loadUser());
        // dispatch(getShiprocketToken());
    }, [dispatch]);

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/inventory/addinventory" element={<AddInventory />} />
            <Route
                path="/inventory/addinventoryexcel"
                element={<AddInventoryExcel />}
            />
            <Route path="/inventory/addproducts" element={<AddProducts />} />
            <Route
                path="/inventory/catalogueaudit"
                element={<CatalogueAudit />}
            />
            <Route
                path="/inventory/duplicateBill"
                element={<DuplicateBill />}
            />
            <Route path="/inventory/editproducts" element={<EditProducts />} />
            <Route
                path="/inventory/editproductsmanager"
                element={<EditProductsManager />}
            />
            <Route
                path="/inventory/managecatalogue"
                element={<ManageCatalogue />}
            />
            <Route
                path="/inventory/managercatalogueaudit"
                element={<ManageCatalogueAudit />}
            />
            <Route path="/inventory/stockaudit" element={<StockAudit />} />

            <Route path="/purchase/markForPO" element={<MarkForPO />} />
            <Route path="/purchase/poCheckout" element={<POCheckout />} />
            <Route path="/purchase/polists" element={<Polists />} />
            <Route path="/purchase/viewChallan" element={<ViewChallan />} />

            <Route
                path="/reports/deleteGstBills"
                element={<DeleteGSTBills />}
            />
            <Route
                path="/reports/exportcustomer"
                element={<ExportCustomer />}
            />
            <Route
                path="/reports/inventory/everydaystock"
                element={<EveryDayStock />}
            />
            <Route
                path="/reports/inventory/multipledisplay"
                element={<MultipleDisplay />}
            />
            <Route
                path="/reports/inventory/productagingreport"
                element={<ProductAgingReport />}
            />
            <Route
                path="/reports/inventory/replenished"
                element={<Replenished />}
            />
            <Route
                path="/reports/sales/exportreport"
                element={<ExportReport />}
            />
            <Route path="/reports/sales/gstreport" element={<Gstreport />} />
            <Route
                path="/reports/sales/pastreports"
                element={<PastReports />}
            />
            <Route
                path="/reports/sales/pwsalereport"
                element={<PWSaleReport />}
            />
            <Route path="/reports/sales/salereport" element={<SaleReport />} />

            <Route path="/sales/b2cbill" element={<B2CBills />} />
            <Route path="/sales/startcash" element={<StartCash />} />

            <Route path="/store/storeexpenses" element={<StoreExpenses />} />

            <Route
                path="/vendor/challanproducts"
                element={<ChallanProducts />}
            />
            <Route path="/vendor/manufactured" element={<Manufactured />} />
            <Route path="/vendor/myproducts" element={<MyProducts />} />
            <Route
                path="/vendor/undermanufacture"
                element={<UnderManufacture />}
            />
            <Route path="/vendor/viewpo" element={<ViewPo />} />
            <Route path="/vendor/detailedpo" element={<DetailedPO />} />
            <Route path="/vendor/storedetails" element={<StoreDetails />} />
        </Routes>
    );
}

export default App;
