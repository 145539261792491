import { useEffect, useState } from "react"
import styled from "styled-components";
import { Button, TextField } from '@mui/material';
import { toast } from "react-toastify";
import { getSizesByType } from "../../utils/productUtils";

const EditProductDetails = ({ id, sizes, productType, total, sendData }) => {

  const [size34, setSize34] = useState(sizes['34'])
  const [size35, setSize35] = useState(sizes['35'])
  const [size36, setSize36] = useState(sizes['36'])
  const [size37, setSize37] = useState(sizes['37'])
  const [size38, setSize38] = useState(sizes['38'])
  const [size39, setSize39] = useState(sizes['39'])
  const [size40, setSize40] = useState(sizes['40'])
  const [size41, setSize41] = useState(sizes['41'])
  const [size42, setSize42] = useState(sizes['42'])

  const [sizeSF, setSizeSF] = useState(sizes['SF'])
  const [sizeXS, setSizeXS] = useState(sizes['XS'])
  const [sizeS, setSizeS] = useState(sizes['S'])
  const [sizeM, setSizeM] = useState(sizes['M'])
  const [sizeL, setSizeL] = useState(sizes['L'])
  const [sizeXL, setSizeXL] = useState(sizes['XL'])
  const [sizeLF, setSizeLF] = useState(sizes['LF'])
  const [sizeFR, setSizeFR] = useState(sizes['FR'])

  const [currentTotalClothing, setCurrentTotalClothing] = useState(total)
  const [currentTotalFootwear, setCurrentTotalFootwear] = useState(total)

  const sizesHeader = getSizesByType(productType)

  useEffect(() => {
    const newTotalClothing = parseInt(sizeSF ? sizeSF : 0) + parseInt(sizeXS ? sizeXS : 0) + parseInt(sizeS ? sizeS : 0) + parseInt(sizeM ? sizeM : 0) + parseInt(sizeL ? sizeL : 0)
      + parseInt(sizeXL ? sizeXL : 0) + parseInt(sizeLF ? sizeLF : 0) + parseInt(sizeFR ? sizeFR : 0)
    const newTotalFootwear = parseInt(size34) + parseInt(size35) + parseInt(size36) + parseInt(size37)
      + parseInt(size38) + parseInt(size39) + parseInt(size40) + parseInt(size41) + parseInt(size42)
    setCurrentTotalClothing(newTotalClothing)
    setCurrentTotalFootwear(newTotalFootwear)
  }, [size34, size35, size36, size37, size38, size39, size40, size41, size42, sizeSF, sizeXS, sizeS, sizeM, sizeL, sizeXL, sizeLF, sizeFR])

  const printTableHeader = () => {
    return (
      <tr>
        <td>id</td>
        {sizesHeader.map((i, k) => <td key={k}>Size: {i.toString()}</td>
        )}
        <td>Total</td>
      </tr>
    );
  };

  const ClothingTable = () => {
    return (
      <tbody>
        <tr>
          <td>{id}</td>
          <td>
            {sizeSF === undefined ? (
              <TextField
                label="count"
                value={"NA"}
                disabled
                type="text"
                variant="outlined"
                size="small"
                fullWidth
              />
            ) : (
              <TextField
                label="count"
                value={sizeSF}
                type="text"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setSizeSF(e.target.value)
                }}
                fullWidth
              />
            )}
          </td>
          <td>
            {sizeXS === undefined ? (
              <TextField
                label="count"
                value={"NA"}
                disabled
                type="text"
                variant="outlined"
                size="small"
                fullWidth
              />
            ) : (
              <TextField
                label="count"
                value={sizeXS}
                type="text"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setSizeXS(e.target.value)
                }}
                fullWidth
              />
            )}
          </td>
          <td>
            {sizeS === undefined ? (
              <TextField
                label="count"
                value={"NA"}
                disabled
                type="text"
                variant="outlined"
                size="small"
                fullWidth
              />
            ) : (
              <TextField
                label="count"
                value={sizeS}
                type="text"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setSizeS(e.target.value)
                }}
                fullWidth
              />
            )}
          </td>
          <td>
            {sizeM === undefined ? (
              <TextField
                label="count"
                value={"NA"}
                disabled
                type="text"
                variant="outlined"
                size="small"
                fullWidth
              />
            ) : (
              <TextField
                label="count"
                value={sizeM}
                type="text"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setSizeM(e.target.value)
                }}
                fullWidth
              />
            )}
          </td>
          <td>
            {sizeL === undefined ? (
              <TextField
                label="count"
                value={"NA"}
                disabled
                type="text"
                variant="outlined"
                size="small"
                fullWidth
              />
            ) : (
              <TextField
                label="count"
                value={sizeL}
                type="text"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setSizeL(e.target.value)
                }}
                fullWidth
              />
            )}
          </td>
          <td>
            {sizeXL === undefined ? (
              <TextField
                label="count"
                value={"NA"}
                disabled
                type="text"
                variant="outlined"
                size="small"
                fullWidth
              />
            ) : (
              <TextField
                label="count"
                value={sizeXL}
                type="text"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setSizeXL(e.target.value)
                }}
                fullWidth
              />
            )}
          </td>
          <td>
            {sizeLF === undefined ? (
              <TextField
                label="count"
                value={"NA"}
                disabled
                type="text"
                variant="outlined"
                size="small"
                fullWidth
              />
            ) : (
              <TextField
                label="count"
                value={sizeLF}
                type="text"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setSizeLF(e.target.value)
                }}
                fullWidth
              />
            )}
          </td>
          <td>
            {sizeFR === undefined ? (
              <TextField
                label="count"
                value={"NA"}
                disabled
                type="text"
                variant="outlined"
                size="small"
                fullWidth
              />
            ) : (
              <TextField
                label="count"
                value={sizeFR}
                type="text"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setSizeFR(e.target.value)
                }}
                fullWidth
              />
            )}
          </td>
          <td>{Number.isNaN(currentTotalClothing) ? '' : currentTotalClothing}</td>
        </tr>
      </tbody>
    )
  }

  const FootwearTable = () => {
    return (
      <tbody>
        <tr>
          <td>{id}</td>
          <td>
            <TextField
              label="count"
              value={size34}
              type="text"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setSize34(e.target.value)
              }}
              fullWidth
            />
          </td>
          <td>
            <TextField
              label="count"
              value={size35}
              type="text"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setSize35(e.target.value)
              }}
              fullWidth
            />
          </td>
          <td>
            <TextField
              label="count"
              value={size36}
              type="text"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setSize36(e.target.value)
              }}
              fullWidth
            />
          </td>
          <td>
            <TextField
              label="count"
              value={size37}
              type="text"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setSize37(e.target.value)
              }}
              fullWidth
            />
          </td>
          <td>
            <TextField
              label="count"
              value={size38}
              type="text"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setSize38(e.target.value)
              }}
              fullWidth
            />
          </td>
          <td>
            <TextField
              label="count"
              value={size39}
              type="text"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setSize39(e.target.value)
              }}
              fullWidth
            />
          </td>
          <td>
            <TextField
              label="count"
              value={size40}
              type="text"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setSize40(e.target.value)
              }}
              fullWidth
            />
          </td>
          <td>
            <TextField
              label="count"
              value={size41}
              type="text"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setSize41(e.target.value)
              }}
              fullWidth
            />
          </td>
          <td>
            <TextField
              label="count"
              value={size42}
              type="text"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setSize42(e.target.value)
              }}
              fullWidth
            />
          </td>
          <td>{Number.isNaN(currentTotalFootwear) ? '' : currentTotalFootwear}</td>
        </tr>
      </tbody>
    )
  }

  const handleSubmit = () => {
    if (productType === 'clothing') {
      const clothSize = {
        SF: parseInt(sizeSF),
        XS: parseInt(sizeXS),
        S: parseInt(sizeS),
        M: parseInt(sizeM),
        L: parseInt(sizeL),
        XL: parseInt(sizeXL),
        LF: parseInt(sizeLF),
        FR: parseInt(sizeFR),
      }
      if (currentTotalClothing === total) {
        sendData(id, clothSize)
      } else {
        const alertText = `Total Stock Is Changed. Please Check Entered Sizes Again`
        toast(alertText)
        return;
      }
    } else {
      const sizes = {
        34: parseInt(size34),
        35: parseInt(size35),
        36: parseInt(size36),
        37: parseInt(size37),
        38: parseInt(size38),
        39: parseInt(size39),
        40: parseInt(size40),
        41: parseInt(size41),
        42: parseInt(size42),
      }
      if (currentTotalFootwear === total) {
        sendData(id, sizes)
      } else {
        const alertText = `Total Stock Is Changed. Please Check Entered Sizes Again`
        toast(alertText)
        return;
      }
    }
  }

  return (
    <div>
      <TableBox>
        <Table>
          <Thead>{printTableHeader()}</Thead>
          {productType === 'clothing' ? ClothingTable() : FootwearTable()}
        </Table>
      </TableBox>
      <br />
      <ButtonBox>
        <Button variant="contained" style={{ backgroundColor: "#f50057", color: "white" }} onClick={() => handleSubmit()}>Submit</Button>
      </ButtonBox>
    </div>
  )
}

const TableBox = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 10px #ddd;

  & > * {
    flex: 1;
    font-size: 1.05rem;
  }
`;
const Table = styled.table`
  border-spacing: 0px;
  & td {
    padding: 5px 0 5px 5px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-align: left;
  }
`;
const Thead = styled.thead`
  & > tr > * {
    padding: 8px 0 8px 5px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-align: left;
    background: #ccc;
  }
`;
const ButtonBox = styled.button`
  margin:auto;
  display:flex;
  justify-content:center;
  border:none;
`

export default EditProductDetails
