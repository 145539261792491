export const FDashboard = {
    name: "Dashboard",
    submenu: false,
    url: "/",
    items: [],
};

export const FSales = {
    name: "Sales",
    submenu: false,
    url: null,
    items: [
        // {
        //   name: "Starting Cash",
        //   submenu: false,
        //   url: "/sales/startcash",
        //   items: [],
        // }, 
    ],
};

export const FPurchase = {
    name: "Purchase",
    submenu: true,
    url: null,
    items: [
        {
            name: "View All POs",
            submenu: false,
            url: "/purchase/polists/",
            items: [],
        },
    ],
};

export const FInventory = {
    name: "Inventory",
    submenu: true,
    url: null,
    items: [
        {
            name: "Add New Products",
            submenu: false,
            url: "/inventory/addproducts",
            items: [],
        },
        {
            name: "Add to Existing Inventory",
            submenu: false,
            url: "/inventory/addinventory",
            items: [],
        },
        {
            name: "Edit/Delete Products",
            submenu: false,
            url: "/inventory/editproducts",
            items: [],
        },
        {
            name: "Delete Duplicate Bill",
            submenu: false,
            url: "/inventory/duplicateBill",
            items: [],
        },
        {
            name: "View Catalogue",
            submenu: false,
            url: "/inventory/catalogueaudit",
            items: [],
        },
    ],
};

export const FCompany = {
    name: "Company",
    submenu: false,
    url: null,
    items: [],
};

export const FStore = {
    name: "Store",
    submenu: false,
    url: null,
    items: [],
};

// 3rd Level menu containing subitems
const ReportsSales = {
    name: "Sales",
    submenu: true,
    url: null,
    items: [
        {
            name: "Past Sale Reports",
            submenu: false,
            url: "/reports/sales/pastreports",
            items: [],
        },
        {
            name: "Credit Note Report",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Customer Transaction",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "GST Report",
            submenu: false,
            url: "/reports/sales/gstreport",
            items: [],
        },
        {
            name: "Product Wise Sale Report",
            submenu: false,
            url: "/reports/sales/pwsalereport",
            items: [],
        },
        {
            name: "Profit & Loss Report(Bill Wise)",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Profit & Loss Report(Product Wise)",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Sale GST Report (%) Wise",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Sale Report",
            submenu: false,
            url: "/reports/sales/salereport",
            items: [],
        },
        {
            name: "Supplier Wise Sale Report",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Top Customers",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Top Selling Products",
            submenu: false,
            url: null,
            items: [],
        },
    ],
};

const ReportsPurchase = {
    name: "Purchase",
    submenu: true,
    url: null,
    items: [
        {
            name: "Debit Note Report",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Expense Report",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Inward Report(Invoice Wise)",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Inward Report(Product Wise)",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Inward/GST(%) Wise Report",
            submenu: false,
            url: null,
            items: [],
        },
    ],
};

const ReportsInventory = {
    name: "Inventory",
    submenu: true,
    url: null,
    items: [
        {
            name: "Damage Used Product",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Damage/Used",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Item Report By Store(by size)",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Low Stock Report",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Price Master",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Product Aging Report",
            submenu: false,
            url: "/reports/inventory/productagingreport",
            items: [],
        },
        {
            name: "Product Summary Report",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Stock Audit Report",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Stock Audit Report(Batch Wise)",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Stock Report",
            submenu: false,
            url: null,
            items: [],
        },
    ],
};

const ReportsReminders = {
    name: "Reminders",
    submenu: true,
    url: null,
    items: [
        {
            name: "Customers Birthdays",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Customers Child Birthdays",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Customers Outstanding",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Product Expiry",
            submenu: false,
            url: null,
            items: [],
        },
        {
            name: "Supplier Payments",
            submenu: false,
            url: null,
            items: [],
        },
    ],
};

// Reports
export const FReports = {
    name: "Reports",
    submenu: true,
    url: null,
    items: [
        {
            name: "Export Customer Info",
            submenu: false,
            url: "/reports/exportcustomer",
            items: [],
        },
    ],
};
