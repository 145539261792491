import { useState } from "react";
import { FaPen, FaTrash } from "react-icons/fa";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteBilledProducts,
  editProductCount,
} from "../../actions/billsAction";

const BillingRow = ({ item, index }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [newCount, setNewCount] = useState("");
  const { discountCategories } = useSelector((state) => state.inventory);

  const dispatch = useDispatch();

  const discCatName =
    discountCategories.filter((c) => c.categoryId == item.comboCategories[0])
      .length > 0
      ? discountCategories.filter(
        (c) => c.categoryId == item.comboCategories[0]
      )[0].name
      : "";

  const editCount = (e) => {
    e.preventDefault();
    const count = parseInt(newCount);
    if (Number.isNaN(count) || count < 1) {
      const alertText = `Entered value ${newCount} is not a valid number`;
      toast(alertText);
    } else if (count > item.stock) {
      const alertText = `Not enough stock available. Try ${count - item.stock
        } items less!!!`;
      toast(alertText);
    } else {
      dispatch(editProductCount(item, count));
    }
    setIsEditable(false);
  };

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <img
          width={100}
          height={100}
          src={item.url}
          alt="SSS"
        />
      </td>
      <td>{item.productId}</td>
      <td>{item.barcode}</td>
      <td>{item.name}</td>
      <td>{item.size}</td>
      <td>{item.stock - item.count}</td>
      <td>{item.mrp}</td>
      <td>{discCatName}</td>
      <td>
        {isEditable ? (
          <form onSubmit={editCount} style={{ maxWidth: "100px" }}>
            <TextField
              id="edit"
              label="count"
              type="text"
              variant="outlined"
              size="small"
              onChange={(e) => setNewCount(e.target.value)}
              fullWidth
            />
          </form>
        ) : (
          <>{item.count}</>
        )}
      </td>
      <td>{item.discountAmt}</td>
      <td>{item.total}</td>
      <td>
        <EditConatiner>
          <FaPen onClick={() => setIsEditable(true)} />
          <FaTrash onClick={() => dispatch(deleteBilledProducts(item))} />
        </EditConatiner>
      </td>
    </tr>
  );
};

const EditConatiner = styled.div`
  display: flex;
  justify-content: center;
  gap: 20%;

  & > svg {
    font-size: 1.3rem;
    cursor: pointer;
  }
`;

export default BillingRow;
