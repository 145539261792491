import { useState, useEffect } from "react";
import styled from "styled-components";
import { TextField, MenuItem, CircularProgress } from "@mui/material";
import { FaSortAlphaDown, FaSortAlphaUpAlt } from "react-icons/fa";
import StockTableRow from "./StockTableRow";
import { convertAllToTypeWise, getSizesByType } from "../../utils/productUtils";
import {
  getInventoryByStoreId,
  getAllVendors,
  getAllCategories,
} from "../../actions/inventoryActions";
import { useSelector, useDispatch } from "react-redux";

const StockTable = () => {
  const { inventoryLoading, inventory, categories, vendors } = useSelector(
    (state) => state.inventory
  );
  const [tableProducts, setTableProducts] = useState([]);
  const [productType, setProductType] = useState("");
  const [productVendor, setProductVendor] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [asc, setAsc] = useState(true);
  const [searchedId, setSearchedId] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (inventory.length === 0) dispatch(getInventoryByStoreId());
    if (categories.length === 0) dispatch(getAllCategories());
    if (vendors.length === 0) dispatch(getAllVendors());
  }, []);

  useEffect(
    () => createTableData(),
    [inventory, productType, productVendor, productCategory]
  );

  const types = [
    {
      value: "",
      label: "None",
    },
    {
      value: "clothing",
      label: "Clothing",
    },
    {
      value: "footwear",
      label: "Footwear",
    },
    {
      value: "imported",
      label: "Imported",
    },
    {
      value: "accessories",
      label: "Accessories",
    },
  ];

  const cats = [
    { value: "", label: "NA" },
    ...categories
      .filter((c) => c.type === productType)
      .map((c) => ({ value: c.categoryId, label: c.name })),
  ];

  const vends = [
    { value: "", label: "NA" },
    ...vendors
      .filter((v) => v.type === productType)
      .map((v) => ({ value: v.id, label: v.name })),
  ];

  const handleSearchId = (e) => {
    e.preventDefault();
    const text = e.target.value;
    setSearchedId(text);
    if (text.length > 1) {
      const likeProducts = tableProducts.filter((p) =>
        p.id.toString().match(text.toUpperCase())
      );
      setTableProducts(likeProducts);
    } else {
      // Get inventory by type
      createTableData();
    }
  };

  const sortById = () => {
    const sortedById = tableProducts.sort(
      (a, b) => Number(a.id) - Number(b.id)
    );
    if (asc) setTableProducts(sortedById.reverse());
    else setTableProducts(sortedById);
    setAsc(!asc);
  };

  const sortByTotal = () => {
    const sortedByTotal = tableProducts.sort(
      (a, b) => Number(a.total) - Number(b.total)
    );
    if (asc) setTableProducts(sortedByTotal.reverse());
    else setTableProducts(sortedByTotal);
    setAsc(!asc);
  };

  const createTableData = () => {
    const validProducts = inventory.filter(
      (p) => !p.productId?.startsWith("000")
    );
    const typeProducts = validProducts.filter((p) => p.type === productType);
    const vendorProducts =
      productVendor === ""
        ? typeProducts
        : typeProducts.filter((p) => p.vendor === productVendor);
    const categoryProducts =
      productCategory === ""
        ? vendorProducts
        : vendorProducts.filter(
          (p) =>
            p.categories.length > 0 && p.categories?.includes(productCategory)
        );
    const tableData = categoryProducts
      .reduce((acc, item) => {
        const row = acc.find((p) => p.id === item.productId);
        if (row) {
          row[item.size] = item.stock
          row.total = row.total + item.stock
        } else {
          const newProduct = {
            id: item.productId,
            url: item.url,
            name: item.name,
            mrp: item.mrp,
            [item.size]: item.stock,
            total: item.stock,
          };
          acc.push(newProduct);
        }
        return acc;
      }, [])
      .sort((a, b) => Number(a.id) - Number(b.id));
    setTableProducts(tableData);
  };

  const printTableHeader = () => {
    const sizes = getSizesByType(productType);

    return (
      <tr>
        <td>
          <CountSpan>{tableProducts.length}</CountSpan>Item
        </td>
        <td>Image</td>
        <TD>
          <SortButton onClick={sortById}>Id</SortButton>
          {asc ? <FaSortAlphaDown /> : <FaSortAlphaUpAlt />}
        </TD>
        <td>Name</td>
        <td>M.R.P.</td>
        {sizes.map((i, k) => (
          <td key={k}>Size: {i.toString()}</td>
        ))}
        <TD>
          <SortButton onClick={sortByTotal}>Total</SortButton>
          {asc ? <FaSortAlphaDown /> : <FaSortAlphaUpAlt />}
        </TD>
      </tr>
    );
  };

  return (
    <div>
      <h1>Available Stock</h1>
      <Container>
        <Row>
          <Col>
            <h3>Choose Product Type</h3>
            <br />
            <TextField
              select
              label="Select Product Type"
              value={productType}
              onChange={(e) => setProductType(e.target.value)}
              variant="outlined"
              className="select"
              fullWidth
            >
              {types.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          {productType && (
            <Col>
              <h3>Choose Vendor</h3>
              <br />
              <TextField
                select
                label="Select Vendor"
                value={productVendor}
                onChange={(e) => setProductVendor(e.target.value)}
                variant="outlined"
                className="select"
                fullWidth
              >
                {vends.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
          )}
          {productType && (
            <Col>
              <h3>Choose Product Category</h3>
              <br />
              <TextField
                select
                label="Select Product Category"
                value={productCategory}
                onChange={(e) => setProductCategory(e.target.value)}
                variant="outlined"
                className="select"
                fullWidth
              >
                {cats.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
          )}
        </Row>
      </Container>
      {productType && (
        <Container>
          <div>
            <TextField
              label="Search Ids"
              type="text"
              variant="outlined"
              size="small"
              value={searchedId}
              onChange={(e) => handleSearchId(e)}
            />
            <br />
            <br />
          </div>
          <TableBox>
            {inventoryLoading && (
              <LoadingScreen>
                <CircularProgress size={100} />
              </LoadingScreen>
            )}
            {tableProducts.length > 0 && (
              <Table>
                <Thead>{printTableHeader()}</Thead>
                {!inventoryLoading && (
                  <tbody>
                    {!inventoryLoading &&
                      tableProducts.map((item, index) => (
                        <StockTableRow
                          key={index}
                          item={item}
                          index={index}
                          productType={productType}
                        />
                      ))}
                  </tbody>
                )}
              </Table>
            )}
          </TableBox>
        </Container>
      )}
    </div>
  );
};

const Container = styled.div`
  margin-top: 50px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const Col = styled.div`
  align-self: center;
  width: 300px;
`;

const Table = styled.table`
  border-spacing: 0px;
  & td {
    padding: 5px 0 5px 5px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-align: left;
  }
`;

const Thead = styled.thead`
  & > tr > * {
    padding: 8px 0 8px 5px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-align: left;
    background: #ccc;
  }
`;
const TD = styled.td`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  cursor: pointer;
`;

const SortButton = styled.button`
  height: 40px;
  flex: 1;
  font-size: 1rem;
  background: transparent;
  cursor: pointer;
  border: none;
`;

const TableBox = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 10px #ddd;

  & > * {
    flex: 1;
    font-size: 1.05rem;
  }
`;

const CountSpan = styled.span`
  padding: 5px 8px;
  height: 30px;
  border-radius: 50%;
  background: #73c24c;
  font-size: 0.8rem;
  font-weight: bold;
  color: #fefefe;
  margin-right: 10px;
`;

const LoadingScreen = styled.div`
  width: 100%;
  height: 50vh;
  background: rgba(256, 256, 256, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default StockTable;
