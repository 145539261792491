import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { TextField, MenuItem, CircularProgress } from "@mui/material";

import ProductRow from "./ProductRow";
import { getSizesByType } from "../../utils/productUtils";
import { getInventoryByStoreId } from "../../actions/inventoryActions";

const EditStockTable = () => {
  const { inventoryLoading, inventory } = useSelector(
    (state) => state.inventory
  );
  const [productType, setProductType] = useState("");
  const [tableProducts, setTableProducts] = useState([]);
  const [searchedId, setSearchedId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!inventory.length > 0) dispatch(getInventoryByStoreId());
  }, []);
  useEffect(() => createTableData(), [productType, inventory]);

  const types = [
    {
      value: "",
      label: "None",
    },
    {
      value: "clothing",
      label: "Clothing",
    },
    {
      value: "footwear",
      label: "Footwear",
    },
    // {
    //   value: "imported",
    //   label: "Imported",
    // },
    // {
    //   value: "accessories",
    //   label: "Accessories",
    // },
  ];

  const handleSearchId = (e) => {
    e.preventDefault();
    const text = e.target.value;
    setSearchedId(text);
    if (text.length > 1) {
      const likeProducts = tableProducts.filter((p) =>
        p.id.toString().match(text.toUpperCase())
      );
      setTableProducts(likeProducts);
    } else {
      // Get inventory by type
      createTableData();
    }
  };

  const createTableData = () => {
    const typeProducts = inventory.filter((p) => p.type === productType);
    const tableData = typeProducts.reduce((acc, item) => {
      const row = acc.find((p) => p.id === item.productId);
      if (row) {
        row[item.size] = item.stock;
        row.total += item.stock;
      } else {
        const newProduct = {
          id: item.productId,
          url: item.url,
          name: item.name,
          mrp: item.mrp,
          [item.size]: item.stock,
          total: item.stock,
        };
        acc.push(newProduct);
      }
      return acc;
    }, []);
    setTableProducts(tableData);
  };

  const printTableHeader = () => {
    const sizes = getSizesByType(productType);

    return (
      <tr>
        <td>
          <CountSpan>{tableProducts.length}</CountSpan>Item
        </td>
        <td>Image</td>
        <td>id</td>
        <td>Name</td>
        <td>M.R.P</td>
        {sizes.map((i, k) => (
          <td key={k}>Size: {i.toString()}</td>
        ))}
        <td>Total</td>
        <td>Edit</td>
      </tr>
    );
  };

  return (
    <div>
      <h1>Edit Products Stock</h1>

      <>
        <Container>
          <h3>Choose Product Type</h3>
          <br />
          <TextField
            select
            label="Select Product Type"
            value={productType}
            onChange={(e) => setProductType(e.target.value)}
            variant="outlined"
            className="select"
            fullWidth
          >
            {types.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Container>
        {productType && (
          <Container>
            <div>
              <TextField
                label="Search Ids"
                type="text"
                variant="outlined"
                size="small"
                value={searchedId}
                onChange={(e) => handleSearchId(e)}
              />
              <br />
              <br />
            </div>
            <TableBox>
              {tableProducts.length > 0 && (
                <Table>
                  <Thead>{printTableHeader()}</Thead>
                  {inventoryLoading ? (
                    <LoadingScreen>
                      <tr>
                        <td colSpan="100">
                          <CircularProgress size={100} />
                        </td>
                      </tr>
                    </LoadingScreen>
                  ) : (
                    <tbody>
                      {tableProducts.map((item, index) => (
                        <ProductRow
                          key={index}
                          item={item}
                          index={index}
                          productType={productType}
                        />
                      ))}
                    </tbody>
                  )}
                </Table>
              )}
            </TableBox>
          </Container>
        )}
      </>
    </div>
  );
};

const Container = styled.div`
  padding: 10px;
`;

const Table = styled.table`
  border-spacing: 0px;
  & td {
    padding: 5px 0 5px 5px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-align: left;
  }
`;

const Thead = styled.thead`
  & > tr > * {
    padding: 8px 0 8px 5px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-align: left;
    background: #ccc;
  }
`;

const TableBox = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 10px #ddd;

  & > * {
    flex: 1;
    font-size: 1.05rem;
  }
`;

const CountSpan = styled.span`
  padding: 5px 8px;
  height: 30px;
  border-radius: 50%;
  background: #73c24c;
  font-size: 0.8rem;
  font-weight: bold;
  color: #fefefe;
  margin-right: 10px;
`;

const LoadingScreen = styled.tbody`
  width: 100%;
  height: 50vh;
  background: rgba(256, 256, 256, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default EditStockTable;
