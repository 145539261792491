import Layout from "../../../Layout";
import Breadcrump from "../../../Breadcrump";

import ReplenishedTable from "../../../reports/ReplenishedStockTable";
import { useSelector } from "react-redux";
import RedirectLogin from "../../../RedirectLogin";

const ReplenishedReport = () => {
    const auth = useSelector((state) => state.auth);

    const widgets = [];

    return (
        <>
            {!!auth ? (
                <Layout title="Replenished Report | LITTLEBOX">
                    <Breadcrump
                        path="/Reports/Inventory/Replenished Report"
                        widgets={widgets}
                    />

                    <ReplenishedTable />
                </Layout>
            ) : (
                <RedirectLogin />
            )}
        </>
    );
};

export default ReplenishedReport;
