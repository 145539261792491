import { useState } from 'react'
import styled from 'styled-components'

const Reference = ({ hint }) => {
    const [placeholder, setPlaceholder] = useState(hint)
    const [text, setText] = useState('')


    return (
        <Container>
            <input type="text"
                onFocus={() => setPlaceholder('')}
                onBlur={() => setPlaceholder(hint)}
                placeholder={placeholder}
                value={text}
                onChange={(e) => setText(e.target.value)}
            />
        </Container>
    )
}

const Container = styled.div`
    height:30px;
    display:flex;
    align-items:center;

    & > input{
        height:30px;
        width:200px;
        padding:0 5px;
        border:2px solid #aaa;
        border-radius: 8px;
        box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
        transition: all 0.15s ease;
        &:focus,
        &:active{
            border:2px solid #73C24C;
            outline: none;
            box-shadow:none;
        }
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #aaa;
            font-family:'roboto condensed';
            font-size:0.7rem;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: #aaa;
            font-family:'roboto condensed';
            font-size:0.7rem;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: #aaa;
            font-family:'roboto condensed';
            font-size:0.7rem;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: #aaa;
            font-family:'roboto condensed';
            font-size:0.7rem;
        }
    }
`

export default Reference
