import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TextField,
  Checkbox,
  MenuItem,
  Button,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";
import { FaSave } from "react-icons/fa";
import { toast } from "react-toastify";

import { formatCurrency } from "../../utils";
import { getSizesByType } from "../../utils/productUtils";
import { FileUploader } from "react-drag-drop-files";
import {
  addToCatalogue,
  changeEnteredDetails,
  changeReset,
  getAllCategories,
  getAllDiscountCategories,
  getAllVendors,
  getCatalogues,
  resetImageUrl,
  uploadImage,
} from "../../actions/inventoryActions";

const AddCatalogueForm = () => {
  const {
    inventoryLoading,
    catalogue,
    categories,
    discountCategories,
    vendors: allVendors,
    utils,
  } = useSelector((state) => state.inventory);
  const { reset, enteredDetails, url } = utils;
  const [submitLoading, setLoading] = useState(false);
  const [productType, setProductType] = useState("");
  const [productId, setProductId] = useState("");
  const [name, setName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [costPrice, setCostPrice] = useState("");
  const [mrp, setMrp] = useState("");
  const [combo, setCombo] = useState("");
  const [vendor, setVendor] = useState("");
  const [productCategories, setProductCategories] = useState([]);
  const [productVendors, setProductVendors] = useState([]);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();

  const fetchCategories = (type) => categories.filter((c) => c.type == type);
  const fetchVendors = (type) => allVendors.filter((v) => v.type == type);

  useEffect(() => {
    if (!catalogue.length > 0) dispatch(getCatalogues());
    if (!allVendors.length > 0) dispatch(getAllVendors());
    if (!discountCategories.length > 0) dispatch(getAllDiscountCategories());
    if (!categories.length > 0) dispatch(getAllCategories());
  }, []);

  useEffect(() => changeOptions(), [productType]);

  useEffect(() => {
    if (reset) {
      setProductId("");
      setName("");
      setCostPrice("");
      setMrp("");
      setCombo("");
      setVendor("");
      setFile(null);
      dispatch(resetImageUrl());
      dispatch(changeReset(false));
      setProductCategories([]);
    }
  }, [reset]);

  const handleEwayUpload = (file) => {
    setFile(file);
  };

  const fileTypes = ["JPG"];

  const changeOptions = () => {
    // Fetch Categories by type
    const categories = fetchCategories(productType);
    const vendors = fetchVendors(productType);
    const cats = categories.map((cat) => ({
      id: cat.categoryId,
      name: cat.name,
      checked: false,
    }));
    setProductCategories(cats);
    setProductVendors(vendors);
  };

  const handleChecked = (event, cat) => {
    const newCats = productCategories.map((c) =>
      c.id === cat.id ? { ...c, checked: event.target.checked } : c
    );
    setProductCategories(newCats);
  };

  const handleSubmit = async () => {
    const categoryString =
      productCategories.filter((p) => p.checked).length > 0
        ? productCategories.filter((p) => p.checked)[0].name
        : "";
    const convertCategoryString = categoryString.slice(0, 2).toUpperCase();
    const newCategories = productCategories
      .filter((p) => p.checked)
      .map((p) => p.id);
    const sizeArray = getSizesByType(productType);
    const productArray = [];
    if (!name || !vendor || !mrp || !costPrice) {
      toast("Incomplete Field");
      return;
    }
    for (let i = 0; i < sizeArray.length; i++) {
      const newProduct = {
        barcode:
          parseInt(mrp) +
          convertCategoryString +
          productId +
          vendor +
          sizeArray[i],
        id: productId,
        name,
        description: shortDescription,
        type: productType,
        url,
        categories: newCategories,
        size: sizeArray[i],
        costPrice: formatCurrency(costPrice),
        mrp: formatCurrency(mrp),
        comboCategories: combo == "" ? [] : [combo],
        vendor,
      };
      productArray.push(newProduct);
    }
    dispatch(addToCatalogue(productArray));
  };

  const uploadPhoto = async () => {
    setLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      dispatch(uploadImage(reader.result, productId));
    };
    setLoading(false);
  };

  const handleProductIdSearch = async () => {
    const filteredId = catalogue.filter((item) => item.id === productId);
    if (filteredId.length > 0) {
      const alertText = "Product Already Exists";
      toast(alertText);
    } else {
      dispatch(changeEnteredDetails(true));
    }
  };

  const handleImageChange = () => {
    dispatch(resetImageUrl());
  };

  const combos = [
    { value: "", label: "NA" },
    ...discountCategories.map((c) => ({ value: c.categoryId, label: c.name })),
  ];

  const vendors = [
    { value: "", label: "None" },
    ...productVendors.map((v) => ({ value: v.id, label: v.name })),
  ];

  const types = [
    {
      value: "",
      label: "None",
    },
    {
      value: "clothing",
      label: "Clothing",
    },
    {
      value: "footwear",
      label: "Footwear",
    },
    {
      value: "imported",
      label: "Imported",
    },
    {
      value: "accessories",
      label: "Accessories",
    },
    // {
    //   value: "rawmaterials",
    //   label: "Raw Materials",
    // },
  ];

  return (
    <form>
      {(submitLoading || inventoryLoading) && (
        <LoadingScreen>
          <CircularProgress size={200} />
        </LoadingScreen>
      )}

      <h2>Add New Product</h2>
      <Container>
        <Row>
          <Col>
            <TextField
              id="vendor"
              select
              label="Select Product Type"
              value={productType}
              onChange={(e) => setProductType(e.target.value)}
              variant="outlined"
              className="select"
            >
              {types.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
      </Container>

      {productType && (
        <Container>
          <Row>
            <Col>
              <h4>Enter Product ID</h4>
              <br />
              <TextField
                style={{ paddingRight: "15px" }}
                id="pid"
                label="Product Id"
                type="text"
                value={productId}
                onChange={(e) => setProductId(e.target.value)}
                variant="outlined"
                size="small"
              />
              {productId ? (
                <Button
                  variant="contained"
                  color="secondary"
                  size="medium"
                  onClick={handleProductIdSearch}
                >
                  Enter
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Container>
      )}
      {enteredDetails && (
        <Container>
          <h4>Please Select/Drag an Image for the Product (.JPG Files Only)</h4>
          <br />
          <>
            {url ? (
              <>
                <img
                  width="200"
                  height="120"
                  src={url}
                  alt="SSS"
                />
                <Button
                  style={{ left: "35px", bottom: "60px" }}
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={handleImageChange}
                >
                  Change Image
                </Button>
                <br />
              </>
            ) : (
              <Row>
                <Col>
                  <FileUploader
                    handleChange={handleEwayUpload}
                    name="file"
                    types={fileTypes}
                  />
                </Col>
                <Col>
                  <p>
                    {file ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="medium"
                        onClick={uploadPhoto}
                      >
                        Upload
                      </Button>
                    ) : (
                      <b>no files selected</b>
                    )}
                  </p>
                </Col>
              </Row>
            )}
          </>
          <br />
          <Row>
            <Col>
              <TextField
                id="name"
                label="Product Name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                size="small"
              />
            </Col>
            <Col>
              <TextField
                id="sd"
                label="description"
                type="text"
                value={shortDescription}
                onChange={(e) => setShortDescription(e.target.value)}
                variant="outlined"
                size="small"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                id="cost"
                label="Cost Price"
                type="text"
                value={costPrice}
                onChange={(e) => setCostPrice(e.target.value)}
                variant="outlined"
                size="small"
              />
            </Col>
            <Col>
              <TextField
                id="mrp"
                label="MRP"
                type="text"
                value={mrp}
                onChange={(e) => setMrp(e.target.value)}
                variant="outlined"
                size="small"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                id="vendor"
                select
                label="Select Vendor"
                value={vendor}
                onChange={(e) => setVendor(e.target.value)}
                variant="outlined"
                className="select"
              >
                {vendors.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col>
              <TextField
                id="combo"
                select
                label="Select Combo"
                value={combo}
                onChange={(e) => setCombo(e.target.value)}
                variant="outlined"
                className="select"
              >
                {combos.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
          </Row>

          <br />
          <FullWidthRow>
            <Col>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <h4>Product Category</h4>
                </FormLabel>
                <FormGroup style={{ flexDirection: "row" }}>
                  {productCategories.length > 0 &&
                    productCategories.map((p) => (
                      <FormControlLabel
                        key={p.id}
                        control={
                          <Checkbox
                            checked={p.checked}
                            onChange={(e) => handleChecked(e, p)}
                          />
                        }
                        label={p.name}
                      />
                    ))}
                </FormGroup>
                <FormHelperText>Multiple can be selected</FormHelperText>
              </FormControl>
            </Col>
          </FullWidthRow>
          <br />
          <br />
          <Row>
            <Col>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                onClick={handleSubmit}
                startIcon={<FaSave />}
                fullWidth
              >
                Save
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </form>
  );
};

const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(256, 256, 256, 0.7);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  margin: 20px auto;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
`;

const FullWidthRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
`;

const Col = styled.div`
  width: 100%;
  & .select.MuiFormControl-root {
    min-width: 206px;
    max-height: 50px;
  }
  & .select .MuiOutlinedInput-input {
    padding: 10.5px 14px;
  }
  & .select .MuiSelect-nativeInput {
    bottom: 10.5px;
  }
  & .select .MuiInputLabel-outlined {
    transform: translate(14px, 14px) scale(1);
  }
  & .select .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
`;

export default AddCatalogueForm;
