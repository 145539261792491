import { combineReducers } from "redux";
import authReducer from "./authReducer";
import expensesReducer from "./expensesReducer";
import inventoryReducer from "./inventoryReducer";
import billsReducer from "./billsReducer";
import loadReducer from "./loadReducer";
import challanReducer from "./challanReducer";
import POReducer from "./POReducer";
import navReducer from "./navReducer";
import storeReducer from "./storeReducer";

const appReducer = combineReducers({
  auth: authReducer,
  inventory: inventoryReducer,
  bills: billsReducer,
  expenses: expensesReducer,
  loaders: loadReducer,
  challan: challanReducer,
  purchaseOrder: POReducer,
  nav: navReducer,
  stores: storeReducer
});

const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
export default rootReducer;
