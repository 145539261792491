import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";

import { types } from "./expenseTypes";
import ConfirmAlert from "../ConfirmAlert";
import { useSelector, useDispatch } from "react-redux";
import { postExpense } from "../../actions/expensesActions";

const NewExpense = () => {
  const { expenseLoading } = useSelector((state) => state.expenses);
  const [createNew, setCreateNew] = useState(false);
  const [expenseType, setExpenseType] = useState("");
  const [amount, setAmount] = useState("0.00");
  const [openDialogue, setOpenDialogue] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [note, setNote] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setDialogData(createDialogData());
  }, [expenseType, amount, note]);

  const createDialogData = () => (
    <>
      <strong>Type Of Expense</strong>:{" "}
      <i>{types.filter((t) => t.value === expenseType)[0].label}</i>
      <br />
      <strong>Amount</strong>: ₹ <i>{amount}</i>
      <br />
      <strong>Note</strong>: {note}
    </>
  );

  const handleSubmit = async () => {
    dispatch(postExpense({ type: expenseType, amount, note: note }));

    setOpenDialogue(false);
    setExpenseType("");
    setAmount("0.00");
    setCreateNew(false);
    setNote("");
  };

  const cancelChanges = () => setOpenDialogue(false);

  return (
    <>
      {expenseLoading && (
        <LoadingScreen>
          <CircularProgress size={100} />
        </LoadingScreen>
      )}
      <Row>
        <h2>Add New Expense</h2>
        <Button variant="outlined" onClick={() => setCreateNew(!createNew)}>
          {createNew ? "Cancel" : "Add"}
        </Button>
      </Row>
      {createNew ? (
        <div>
          <br />
          <TextField
            select
            label="Select Expense Type"
            value={expenseType}
            onChange={(e) => setExpenseType(e.target.value)}
            variant="outlined"
            className="select"
            fullWidth
          >
            {types.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <br />
          <br />
          <TextField
            label="Amount"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            variant="outlined"
            fullWidth
          ></TextField>
          <br />
          <br />
          <TextField
            label="Note"
            type="string"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            variant="outlined"
            fullWidth
          ></TextField>
          <br />
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialogue(true)}
          >
            Submit
          </Button>
          <br />
          <br />
          <ConfirmAlert
            data={dialogData}
            open={openDialogue}
            handleCancel={cancelChanges}
            handleConfirm={handleSubmit}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const Row = styled.div`
  display: flex;
  gap: 20px;
`;

const LoadingScreen = styled.div`
  width: 100%;
  height: 50vh;
  background: rgba(256, 256, 256, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default NewExpense;
