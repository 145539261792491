import { useSelector } from "react-redux";
import Layout from "../../../Layout";
import Breadcrump from "../../../Breadcrump";
import StockAgingTable from "../../../reports/StockAgingTable";
import RedirectLogin from "../../../RedirectLogin";

const ProductAgingReport = () => {
    const auth = useSelector((state) => state.auth);
    const widgets = [];

    return (
        <>
            {!!auth ? (
                <Layout title="Product Aging Report | LITTLEBOX">
                    <Breadcrump
                        path="/Reports/Inventory/Product Aging Report"
                        widgets={widgets}
                    />

                    <StockAgingTable />
                </Layout>
            ) : (
                <RedirectLogin />
            )}
        </>
    );
};

export default ProductAgingReport;
