export const getSortedProducts = (products) => {
    let sortedProds = []

    if(products[0].type === 'clothing'){
        const clothProds = products.map(x => ({
            Product_Id: x.id,
            Name: x.name,
            SF: x.sizes.SF ? x.sizes.SF : 0,
            XS: x.sizes.XS ? x.sizes.XS : 0,
            S: x.sizes.S ? x.sizes.S : 0,
            M: x.sizes.M ? x.sizes.M : 0,
            L: x.sizes.L ? x.sizes.L : 0,
            XL: x.sizes.XL ? x.sizes.XL : 0,
            LF: x.sizes.LF ? x.sizes.LF : 0,
            FR: x.sizes.FR ? x.sizes.FR : 0,
        }))
        sortedProds.push(clothProds)
    }else if(products[0].type === 'footwear'){
        const footwearProds = products.map(x => ({
            Product_Id: x.id,
            Name: x.name,
            34: x.sizes["34"] ? x.sizes["34"] : 0,
            35: x.sizes["35"] ? x.sizes["35"] : 0,
            36: x.sizes["36"] ? x.sizes["36"] : 0,
            37: x.sizes["37"] ? x.sizes["37"] : 0,
            38: x.sizes["38"] ? x.sizes["38"] : 0,
            39: x.sizes["39"] ? x.sizes["39"] : 0,
            40: x.sizes["40"] ? x.sizes["40"] : 0,
            41: x.sizes["41"] ? x.sizes["41"] : 0,
            42: x.sizes["42"] ? x.sizes["42"] : 0,
        }))
        sortedProds.push(footwearProds)
    }
    return sortedProds
}