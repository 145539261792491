import { useState, useEffect } from "react";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import CardTable from "../CardTable";
import { formatCurrency } from "../../utils";
import { types } from "./expenseTypes";
import { useSelector, useDispatch } from "react-redux";
import { getExpensesBystoreId } from "../../actions/expensesActions";
import { getMonthlyBillsByStoreId } from "../../actions/billsAction";

const ExpensesReport = (props) => {
  const { expenses, expenseLoading } = useSelector((state) => state.expenses);
  const { billLoading, monthBills } = useSelector((state) => state.bills);

  const [monthData, setMonthData] = useState([]);
  const [totalAmt, setTotalAmt] = useState("0.00");
  const [cashInHand, setCashInHand] = useState("0.00");
  const dispatch = useDispatch();

  const getMonthsCash = () =>
    formatCurrency(
      monthBills.reduce((acc, b) => acc + parseFloat(b.paymentMethod.cash), 0)
    );

  useEffect(() => {
    if (!expenses.length > 0) dispatch(getExpensesBystoreId());
    if (!monthBills.length > 0) dispatch(getMonthlyBillsByStoreId());
  }, []);

  useEffect(() => {
    const records = expenses.filter(
      (record) =>
        new Date(record.createdAt).getFullYear() === props.date.getFullYear() &&
        new Date(record.createdAt).getMonth() === props.date.getMonth()
    );

    const data = records.map((row) => ({
      Date: new Date(row.createdAt).toLocaleDateString("en-US", {
        timeZone: "Asia/Kolkata",
      }),
      ExpenseType: types.filter((t) => t.value === row.type)[0].label,
      Amount: `₹ ${row.amount}`,
      Note: row.note,
    }));

    setMonthData(data);

    const total = formatCurrency(
      records.reduce((acc, b) => acc + parseFloat(b.amount), 0)
    );

    const totalEx = formatCurrency(
      records.reduce(
        (acc, b) => (b.type !== "deposit" ? acc + parseFloat(b.amount) : acc),
        0
      )
    );

    setTotalAmt(totalEx);

    setCashInHand(formatCurrency(parseFloat(getMonthsCash()) - total));
  }, [expenses, billLoading, expenseLoading, props]);

  return (
    <Content className="content-container">
      {(expenseLoading || billLoading) && (
        <LoadingScreen>
          <CircularProgress size={200} />
        </LoadingScreen>
      )}
      <h1>Total Monthly Expense: ₹{totalAmt}</h1>
      {props.date.getMonth() === new Date().getMonth() &&
        props.date.getFullYear() === new Date().getFullYear() && (
          <h1>Cash In Hand: ₹{cashInHand}</h1>
        )}
      {monthData.length > 0 ? (
        <>
          <CardTable title="Expenses" data={monthData} />
        </>
      ) : null}
    </Content>
  );
};

const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(256, 256, 256, 0.7);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default ExpensesReport;
