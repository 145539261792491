import { useState, useEffect } from "react";
import styled from "styled-components";
import { TextField, MenuItem, CircularProgress } from "@mui/material";
import { FaSortAlphaDown, FaSortAlphaUpAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { getThreeMonthBills } from "../../actions/billsAction";
import {
  getInventoryByStoreId,
  getAllCategories,
} from "../../actions/inventoryActions";

const ReplenishedTableRow = ({ item, index }) => {

  const { url, name, id, total, soldLastDay, ..._ } = item;

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <img width="100" height="100" src={url} alt="SSS" />
      </td>
      <td>{id}</td>
      <td>{name}</td>
      <td>{total}</td>
      <td>{soldLastDay}</td>
    </tr>
  );
};

const ReplenishedTable = () => {
  const { inventoryLoading, inventory, categories } = useSelector(
    (state) => state.inventory
  );
  const { billLoading, allBills } = useSelector((state) => state.bills);
  const [tableProducts, setTableProducts] = useState([]);
  const [productType, setProductType] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [asc, setAsc] = useState(true);
  const [searchedId, setSearchedId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!allBills.length > 0) dispatch(getThreeMonthBills());
    if (!inventory.length > 0) dispatch(getInventoryByStoreId());
    if (!categories.length > 0) dispatch(getAllCategories());
  }, []);

  useEffect(() => createTableData(), [inventory, productType, productCategory]);

  const types = [
    {
      value: "",
      label: "None",
    },
    {
      value: "clothing",
      label: "Clothing",
    },
    {
      value: "footwear",
      label: "Footwear",
    },
    {
      value: "imported",
      label: "Imported",
    },
    {
      value: "accessories",
      label: "Accessories",
    },
  ];

  const cats = [
    { value: "", label: "NA" },
    ...categories
      .filter((c) => c.type === productType)
      .map((c) => ({ value: c.categoryId, label: c.name })),
  ];

  const handleSearchId = (e) => {
    e.preventDefault();
    const text = e.target.value;
    setSearchedId(text);
    if (text.length > 1) {
      const likeProducts = tableProducts.filter((p) =>
        p.id.toString().match(text.toUpperCase())
      );
      setTableProducts(likeProducts);
    } else {
      // Get inventory by type
      createTableData();
    }
  };

  const sortBy = (para) => {
    const sortedBy = tableProducts.sort(
      (a, b) => Number(a[para]) - Number(b[para])
    );
    if (asc) setTableProducts(sortedBy.reverse());
    else setTableProducts(sortedBy);
    setAsc(!asc);
  };

  const createTableData = () => {
    const lastDayBills = allBills.filter(
      (b) =>
        new Date(b.soldAt) >
        new Date(new Date().setDate(new Date().getDate() - 1))
    );
    const validProducts = inventory.filter(
      (p) => !p.productId?.startsWith("000")
    );
    const typeProducts = validProducts.filter((p) => p.type === productType);
    const categoryProducts =
      productCategory === ""
        ? typeProducts
        : typeProducts.filter(
          (p) =>
            p.categories.length > 0 && p.categories?.includes(productCategory)
        );
    const tableData = categoryProducts
      .reduce((acc, item) => {
        const row = acc.find((p) => p.id === item.productId);

        if (row) {
          row[item.size] = item.stock;
          row.total = row.total + item.stock;
        } else {
          const soldLastDay = lastDayBills.reduce(
            (acc, bill) =>
              bill.products
                .filter((p) => p.id === item.productId)
                .reduce((sum, i) => sum + i.count, 0) + acc,
            0
          );

          const newProduct = {
            id: item.productId,
            url: item.url,
            name: item.name,
            [item.size]: item.stock,
            total: item.stock,
            soldLastDay,
          };
          acc.push(newProduct);
        }
        return acc;
      }, [])
      .sort((a, b) => Number(a.id) - Number(b.id));
    setTableProducts(tableData);
  };

  const printTableHeader = () => {
    return (
      <tr>
        <td>
          <CountSpan>{tableProducts.length}</CountSpan>Item
        </td>
        <td>Image</td>
        <TD>
          <div>
            <SortButton onClick={() => sortBy("id")}>Id</SortButton>
            {asc ? <FaSortAlphaDown /> : <FaSortAlphaUpAlt />}
          </div>
        </TD>
        <td>Name</td>
        <TD>
          <div>
            <SortButton onClick={() => sortBy("total")}>
              Remaining Stock
            </SortButton>
            {asc ? <FaSortAlphaDown /> : <FaSortAlphaUpAlt />}
          </div>
        </TD>
        <TD>
          <div>
            <SortButton onClick={() => sortBy("soldLastDay")}>
              Last Day Sold
            </SortButton>
            {asc ? <FaSortAlphaDown /> : <FaSortAlphaUpAlt />}
          </div>
        </TD>
      </tr>
    );
  };

  return (
    <Content>
      <h1>Replenished Stock</h1>
      <Container>
        <h3>Choose Product Type</h3>
        <br />
        <TextField
          select
          label="Select Product Type"
          value={productType}
          onChange={(e) => setProductType(e.target.value)}
          variant="outlined"
          className="select"
          fullWidth
        >
          {types.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Container>
      {productType && (
        <Container>
          <div>
            <TextField
              select
              label="Select Product Category"
              value={productCategory}
              onChange={(e) => setProductCategory(e.target.value)}
              variant="outlined"
              className="select"
              fullWidth
            >
              {cats.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <br />
            <TextField
              label="Search Ids"
              type="text"
              variant="outlined"
              size="small"
              value={searchedId}
              onChange={(e) => handleSearchId(e)}
            />
            <br />
            <br />
          </div>
          <TableBox>
            {(inventoryLoading || billLoading) && (
              <LoadingScreen>
                <CircularProgress size={100} />
              </LoadingScreen>
            )}
            {tableProducts.length > 0 && (
              <Table>
                <Thead>{printTableHeader()}</Thead>
                {!(inventoryLoading || billLoading) && (
                  <tbody>
                    {tableProducts.map((item, index) => (
                      <ReplenishedTableRow
                        key={index}
                        item={item}
                        index={index}
                      />
                    ))}
                  </tbody>
                )}
              </Table>
            )}
          </TableBox>
        </Container>
      )}
    </Content>
  );
};

const Content = styled.div`
  min-height: 100vh;
  padding: 0.5em 30px;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Container = styled.div`
  margin-top: 50px;
`;

const Table = styled.table`
  display: table;
  border-spacing: 0px;
  & td {
    padding: 5px 0 5px 5px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-align: left;
  }
`;

const Thead = styled.thead`
  & > tr > * {
    padding: 8px 0 8px 5px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-align: left;
    background: #ccc;
  }
`;
const TD = styled.td`
  display: table-cell;
  padding: 0 5px;
  cursor: pointer;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const SortButton = styled.button`
  height: 40px;
  flex: 1;
  font-size: 1rem;
  background: transparent;
  cursor: pointer;
  border: none;
`;

const TableBox = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 10px #ddd;

  & > * {
    flex: 1;
    font-size: 1.05rem;
  }
`;

const CountSpan = styled.span`
  padding: 5px 8px;
  height: 30px;
  border-radius: 50%;
  background: #73c24c;
  font-size: 0.8rem;
  font-weight: bold;
  color: #fefefe;
  margin-right: 10px;
`;

const LoadingScreen = styled.div`
  width: 100%;
  height: 50vh;
  background: rgba(256, 256, 256, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ReplenishedTable;
