
import { Link } from "react-router-dom";
import { useRef } from 'react'
import { useDispatch } from "react-redux";
import styled from 'styled-components'
import { FaCaretRight, FaFileExcel } from 'react-icons/fa'
import SubNav from './SubNav'
import { setActive } from "../../actions/navActions"

const NavItem = (props) => {

    const spanRef = useRef()
    const dispatch = useDispatch()

    const variableStyles = {
        fontWeight: props.bold ? 'bold' : 'normal'
    }

    const mouseEnter = () => {
        if (spanRef.current) {
            dispatch(setActive(true))
            spanRef.current.style.display = 'block'
        }
    }

    const mouseLeave = () => {
        if (spanRef.current) {
            dispatch(setActive(false))
            spanRef.current.style.display = 'none'
        }
    }

    return (
        <Container
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            third={props.third}>
            <Link to={props.url || '/'} style={variableStyles}>
                {props.title} {props.third && <FaCaretRight />}
            </Link>
            {props?.data?.submenu && (
                <>
                    <SubNav items={props.data.items} />
                    {!props.third && <span ref={spanRef} onMouseEnter={mouseLeave}></span>}
                </>
            )}
        </Container>
    )
}

const Container = styled.div`
    height:100%;
    padding:0 10px;
    color:#8f8f8f;
    display:flex;
    align-items:center;
    position:relative;

    & > a > svg{
        position: absolute;
        right: 0;
    }

    & > div{
        display:none;
        position:absolute;
        top: ${props => props.third ? '0' : '60px'};
        left:${props => props.third ? '300px' : '0'};
        z-index:101;
    }  
    
    & > span{
        display:none;
        position:fixed;
        top: 60px;
        left:0;
        bottom:0;
        right:0;
        background-color:rgba(0,0,0,0.6);
    }

    &:hover{        
        background:#008FB3;
        color: #eee;
    }

    &:hover > div{
        display:block;
    }
`

export default NavItem
