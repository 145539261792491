import styled from "styled-components";
import Layout from "../../Layout";
import Breadcrump from "../../Breadcrump";
import ViewChallan from "../../managerchallan/ViewChallan";
import RedirectLogin from "../../RedirectLogin";
import { useSelector } from "react-redux";

const POLists = () => {
    const auth = useSelector((state) => state.auth);

    return (
        <>
            {!!auth ? (
                <Layout title="Add PO Products | LITTLEBOX">
                    <Breadcrump path="/PO/Add Products" widgets={[]} />
                    <Content className="content-container">
                        <ViewChallan />
                    </Content>
                </Layout>
            ) : (
                <RedirectLogin />
            )}
        </>
    );
};

const Content = styled.div`
    width: 100%;
`;

export default POLists;
