import { useSelector } from "react-redux";

const CustomerInfo = () => {
  const { customer } = useSelector((state) => state.bills.billing);
  return (
    <div>
      {customer && (
        <h3>Current Customer: {customer.firstName || customer.mobileNo}</h3>
      )}
    </div>
  );
};

export default CustomerInfo;
