import { useEffect, useState } from "react"
import styled from "styled-components"
import { Animated } from "react-animated-css";
import {formatCurrency} from "../../utils"

const PaymentCollectionMethodDropdown = ({ data }) => {
    const [show, setShow] = useState(false)
    const [animate, setAnimate] = useState(false)

    const [cashData, setCashData ] = useState(0)
    const [cardData, setCardData ] = useState(0)
    const [upiData, setUpiData ] = useState(0)
    const [chequeData, setChequeData ] = useState(0)

    useEffect(() => {
        if(data && data.length > 0){
            setCashData(data.map(p => parseFloat(p['cash'])).reduce((sum,item) => sum + item,0))
            setCardData(data.map(p => parseFloat(p['card'])).reduce((sum,item) => sum + item,0))
            setUpiData(data.map(p => parseFloat(p['upi'])).reduce((sum,item) => sum + item,0))
            setChequeData(data.map(p => parseFloat(p['cheque'])).reduce((sum,item) => sum + item,0))
        }
    }, [data])

    const toggleDetails = () => {
        setAnimate(!animate)
        setTimeout(() => setShow(!show), show ? 150 : 0)
    }
    
    return (
        <>
            <Animated animationIn="bounceIn" animationOut="zoomOutLeft" animationInDuration={750} animationOutDuration={800} isVisible={animate}>
                <DetailsBox visible={show}>
                    <p>Cash : ₹ {formatCurrency(cashData)}</p>
                    <p>Card : ₹ {formatCurrency(cardData)}</p>
                    <p>Wallet : ₹ {formatCurrency(upiData)}</p>
                    <p>Cheque : ₹ {formatCurrency(chequeData)}</p>
                </DetailsBox>
            </Animated>
            <Dropdown onClick={toggleDetails}>
                <p>View Payment Collection Methods</p>
            </Dropdown>
        </>
    )
}

const Dropdown = styled.div`
    width:300px;       
    background:#008FB3;
    margin:0 auto;
    border-radius: 0 0 10px 10px;
    padding:5px;
    cursor:pointer;

    & p{
        text-align:center;
        color:#f0f0f0;
        font-size:0.8rem;
        font-weight:bold;
    }
`

const DetailsBox = styled.div`
    display:${(props) => props.visible ? "grid" : "none"};
    grid-template-columns:repeat(3, 1fr);
    background-color:#fff;
    width: 80%;
    margin: 0 auto;

    & p{
        font-size: 0.8rem;
        padding: 5px 10px;
    }
`

export default PaymentCollectionMethodDropdown