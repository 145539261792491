import { useState } from "react";
import { FaPen, FaTrash } from "react-icons/fa";

import styled from "styled-components";

import EditProductRowSizeBox from "./EditProductRowSizeBox";
import { getSizesByType } from "../../utils/productUtils";
import { useDispatch } from "react-redux";
import {
  adminDeleteProduct,
  updateProductCount,
} from "../../actions/inventoryActions";

const ProductTableRow = ({ item, index, productType, storeId }) => {
  const [isEditable, setIsEditable] = useState(false);
  const dispatch = useDispatch();

  const sizes = getSizesByType(productType);

  const handleDelete = async () => {
    if (window.confirm(`Delete product ${item.name} with id=${item.id}?`)) {
      dispatch(adminDeleteProduct(item.id, storeId));
    }
  };

  const handleChangeCount = (event, size, count) => {
    event.preventDefault();
    const product = {
      productId: item.id,
      [size]: count,
    };
    dispatch(updateProductCount(product));
    setIsEditable(false);
  };

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <img
          width="100"
          height="100"
          src={item.url}
          alt="SSS"
        />
      </td>
      <td>{item.id}</td>
      <td>{item.name}</td>
      <td>{item.mrp}</td>
      {sizes.map((k) => (
        <td key={k}>
          {isEditable ? (
            <EditProductRowSizeBox
              item={k}
              stock={item[k]}
              editCount={handleChangeCount}
            ></EditProductRowSizeBox>
          ) : (
            <>{item[k]}</>
          )}
        </td>
      ))}
      <td>{item.total}</td>
      <td>
        <EditConatiner>
          <FaPen onClick={() => setIsEditable(true)} />
          <FaTrash onClick={() => handleDelete()} />
        </EditConatiner>
      </td>
    </tr>
  );
};

const EditConatiner = styled.div`
  display: flex;
  justify-content: center;
  gap: 20%;

  & > svg {
    font-size: 1.3rem;
    cursor: pointer;
  }
`;

export default ProductTableRow;
