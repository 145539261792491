export const productTypes = ["clothing", "footwear", "imported", "accessories"];

export const clothingSizes = ["SF", "XS", "S", "M", "L", "XL", "LF", "FR"];

export const footwearSizes = [
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
];

export const importedSizes = ["FR", "04", "06", "08", "10", "12", "14"];

export const accessoriesSizes = ["FR"];

// export const rawmaterialsSizes = [""]

export const convertAllToTypeWise = (products) => ({
  clothing: products.filter((p) => p.type === "clothing"),
  footwear: products.filter((p) => p.type === "footwear"),
  imported: products.filter((p) => p.type === "imported"),
  accessories: products.filter((p) => p.type === "accessories"),
});

export const getSizesByType = (type) => {
  if (type === "clothing") return clothingSizes;
  if (type === "footwear") return footwearSizes;
  if (type === "imported") return importedSizes;
  if (type === "accessories") return accessoriesSizes;
  // if (type=== "rawmaterials") return rawmaterialsSizes;
};
