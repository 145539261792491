import { useState } from "react";
import styled from "styled-components";
import Calendar from "react-calendar";
import Layout from "../../Layout";
import Breadcrump from "../../Breadcrump";
import NewExpense from "../../store/NewExpense";
import ExpensesReport from "../../store/ExpensesReport";
import { useSelector } from "react-redux";
import "react-calendar/dist/Calendar.css";
import RedirectLogin from "../../RedirectLogin";

const StoreExpensesPage = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const auth = useSelector((state) => state.auth);
    const widgets = [];

    return (
        <>
            {!!auth ? (
                <Layout title="Store Expenses | LITTLEBOX">
                    <Breadcrump
                        path="/store/Store Expenses"
                        widgets={widgets}
                    />
                    <Heading>Store Expenses</Heading>
                    <Divider />
                    <Container>
                        <Col>
                            <BorderBox>
                                <NewExpense />
                            </BorderBox>
                        </Col>
                        <Col>
                            <Calendar
                                className="center"
                                onChange={setSelectedDate}
                                value={selectedDate}
                                view="year"
                                onClickMonth={setSelectedDate}
                            />
                            <h2>Records</h2>
                            <ExpensesReport date={selectedDate} />
                        </Col>
                    </Container>
                </Layout>
            ) : (
                <RedirectLogin />
            )}
        </>
    );
};

const Heading = styled.h1`
    margin: 20px 0;
    text-align: center;
`;
const Divider = styled.div`
    margin: 10px auto 50px auto;
    width: 50ch;
    height: 2px;
    background: rgba(100, 100, 100, 0.3);
`;

const Container = styled.div`
    margin: 0 30px;
    display: flex;
`;

const Col = styled.div`
    flex: 1;
`;

const BorderBox = styled.div`
    max-width: 500px;
    padding: 20px;
    border: 1px dashed grey;
    border-radius: 5px;
`;

export default StoreExpensesPage;
