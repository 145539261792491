import { useSelector } from "react-redux";
import NavItem from "./NavItem";
import {
  Dashboard,
  Sales,
  Purchase,
  Inventory,
  Reports,
  Company,
  Store,
} from "../../navItems/managerNav";
import {
  ADashboard,
  ASales,
  APurchase,
  AInventory,
  AReports,
  ACompany,
  AStore,
} from "../../navItems/adminNav";
import {
  FDashboard,
  FPurchase,
  FInventory,
  FReports,
} from "../../navItems/franchiseOwnerNav";
import {
  VDashboard,
  VSales,
  VPurchase,
  VInventory,
  VReports,
  Vendor,
  VStore,
} from "../../navItems/vendorNav";

const NavList = () => {
  const auth = useSelector((state) => state.auth);
  const getAdminNav = () => (
    <>
      <NavItem title="Dashboard" url={ADashboard.url} bold data={ADashboard} />
      <NavItem title="Sales" url={ASales.url} bold data={ASales} />
      <NavItem title="Purchase" url={APurchase.url} bold data={APurchase} />
      <NavItem title="Inventory" url={AInventory.url} bold data={AInventory} />
      <NavItem title="Reports" url={AReports.url} bold data={AReports} />
      <NavItem title="Company" url={ACompany.url} bold data={ACompany} />
      <NavItem title="Store (Add On)" url={AStore.url} bold data={AStore} />
    </>
  );

  const getFranchiseAdminNav = () => (
    <>
      <NavItem title="Dashboard" url={FDashboard.url} bold data={FDashboard} />
      <NavItem title="Purchase" url={FPurchase.url} bold data={FPurchase} />
      <NavItem title="Inventory" url={FInventory.url} bold data={FInventory} />
      <NavItem title="Reports" url={FReports.url} bold data={FReports} />
    </>
  );

  const getManagerNav = () => (
    <>
      <NavItem title="Dashboard" url={Dashboard.url} bold data={Dashboard} />
      <NavItem title="Sales" url={Sales.url} bold data={Sales} />
      <NavItem title="Purchase" url={Purchase.url} bold data={Purchase} />
      <NavItem title="Inventory" url={Inventory.url} bold data={Inventory} />
      <NavItem title="Reports" url={Reports.url} bold data={Reports} />
      <NavItem title="Company" url={Company.url} bold data={Company} />
      <NavItem title="Store (Add On)" url={Store.url} bold data={Store} />
    </>
  );

  const getVendorNav = () => (
    <>
      <NavItem title="Dashboard" url={VDashboard.url} bold data={VDashboard} />
      <NavItem title="Vendor" url={Vendor.url} bold data={Vendor} />
    </>
  );

  return (
    <div className="menu">
      {auth.action.data.role === "manager"
        ? getManagerNav()
        : auth.action.data.role === "vendor"
          ? getVendorNav()
          : auth.action.data.role === "franchiseowner"
            ? getFranchiseAdminNav()
            : getAdminNav()}
    </div>
  );
};

export default NavList;
