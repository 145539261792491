import { useState, useEffect } from "react";
import styled from "styled-components";
import CardTable from "../CardTable";
import CategorySalesPieChart from "./CategorySalesPieChart";
import { Card } from "@mui/material";
import ComboSalesPieChart from "./ComboSalesPieChart";
import { getMonthlyBillsByStoreId } from "../../actions/billsAction";
import { useSelector, useDispatch } from "react-redux";
const DetailedSummary = () => {
  const { billLoading, todayBills } = useSelector((state) => state.bills);
  const [soldSneakers, setSoldSneakers] = useState([]);
  const [soldFlats, setSoldFlats] = useState([]);
  const [soldBoots, setSoldBoots] = useState([]);
  const [soldHeels, setSoldHeels] = useState([]);
  const [soldTops, setSoldTops] = useState([]);

  const [clothCount, setClothCount] = useState(0);
  const [footwearCount, setFootwearCount] = useState(0);
  const [importedCount, setImportedCount] = useState(0);

  const [clothDiscount1, setClothDiscount1] = useState(0);
  const [clothDiscount2, setClothDiscount2] = useState(0);
  const [noDiscount, setNoDiscount] = useState(0);
  const [footwearDiscount, setFootwearDiscount] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!todayBills.length > 0) {
      dispatch(getMonthlyBillsByStoreId());
    }
  }, []);
  useEffect(() => {
    const arr = [];
    const todayProducts = todayBills
      ?.map((p) => p.products)
      .forEach((x) => x.map((y) => arr.push(y)));

    const clothing = arr
      .filter((p) => p.type === "clothing")
      .reduce((sum, p) => sum + p.count, 0);
    const footwear = arr
      .filter((p) => p.type === "footwear")
      .reduce((sum, p) => sum + p.count, 0);
    const imported = arr
      .filter((p) => p.type === "imported")
      .reduce((sum, p) => sum + p.count, 0);

    const noDisc = arr
      .filter((p) => p.comboUsed === "None")
      .reduce((sum, p) => sum + p.count, 0);
    const clothDisc1 = arr
      .filter((p) => p.comboUsed === "3 for 1199")
      .reduce((sum, p) => sum + p.count, 0);
    const clothDisc2 = arr
      .filter((p) => p.comboUsed === "3 for 799")
      .reduce((sum, p) => sum + p.count, 0);
    const shoeDisc = arr
      .filter((p) => p.comboUsed === "3 for 999")
      .reduce((sum, p) => sum + p.count, 0);

    setClothCount(clothing);
    setFootwearCount(footwear);
    setImportedCount(imported);

    setNoDiscount(noDisc);
    setClothDiscount1(clothDisc1);
    setClothDiscount2(clothDisc2);
    setFootwearDiscount(shoeDisc);

    const lTop = arr
      .filter((p) => p.type === "clothing")
      .map((p) => ({
        id: p.id,
        name: p.name,
        count: p.count,
        vendor: p.vendor,
      }));
    setSoldTops(lTop);

    const lSneakers = arr
      .filter((p) => p.categories[0] === "105")
      .map((p) => ({
        id: p.id,
        name: p.name,
        count: p.count,
        vendor: p.vendor,
      }));
    setSoldSneakers(lSneakers);

    const lBoot = arr
      .filter((p) => p.categories[0] === "106")
      .map((p) => ({
        id: p.id,
        name: p.name,
        count: p.count,
        vendor: p.vendor,
      }));
    setSoldBoots(lBoot);

    const lFlat = arr
      .filter((p) => p.categories[0] === "104")
      .map((p) => ({
        id: p.id,
        name: p.name,
        count: p.count,
        vendor: p.vendor,
      }));
    setSoldFlats(lFlat);

    const lHeel = arr
      .filter((p) => p.categories[0] === "103")
      .map((p) => ({
        id: p.id,
        name: p.name,
        count: p.count,
        vendor: p.vendor,
      }));
    setSoldHeels(lHeel);
  }, [todayBills]);

  return (
    <>
      {billLoading ? (
        ""
      ) : (
        <>
          <div style={{ display: "flex" }}>
            <Card
              style={{
                maxWidth: "fit-content",
                backgroundColor: "white",
                borderRadius: "10px",
                marginRight: "20px",
              }}
            >
              <CategorySalesPieChart
                clothData={clothCount}
                footData={footwearCount}
                importedData={importedCount}
              />
            </Card>
            <Card
              style={{
                maxWidth: "fit-content",
                backgroundColor: "white",
                borderRadius: "10px",
              }}
            >
              <ComboSalesPieChart
                noDiscount={noDiscount}
                clothDiscount1={clothDiscount1}
                clothDiscount2={clothDiscount2}
                footwearDiscount={footwearDiscount}
              />
            </Card>
          </div>
          <br />
          <br />
          <GridRow>
            <CardTable title="FLATS SOLD TODAY" data={soldFlats} />
            <CardTable title="BOOTS SOLD TODAY" data={soldBoots} />
            <CardTable title="HEELS SOLD TODAY" data={soldHeels} />
            <CardTable title="SNEAKERS SOLD TODAY" data={soldSneakers} />
            <CardTable title="CLOTHING SOLD TODAY" data={soldTops} />
          </GridRow>
        </>
      )}
    </>
  );
};

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export default DetailedSummary;
