const initialState = {
  storeChallans: [],
  challanLoading: false,
};

const challanReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_CHALLANS":
      return {
        ...state,
        storeChallans: action.payload,
      };
    case "SHOW_CHALLAN_LOADING":
      return {
        ...state,
        challanLoading: true,
      };
    case "HIDE_CHALLAN_LOADING":
      return {
        ...state,
        challanLoading: false,
      };
    default:
      return state;
  }
};

export default challanReducer;
