import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactExport from "react-data-export";
import styled from "styled-components";
import { CircularProgress, Button, TextField, MenuItem } from "@mui/material";
import { FaFileExcel } from "react-icons/fa";
import {
  adminGetAllCustomerByStore,
  adminGetCustomerInfoByDate,
} from "../../actions/billsAction";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const types = [
  {
    value: "",
    label: "None",
  },
  {
    value: "1",
    label: "Haus Khas",
  },
  {
    value: "2",
    label: "Borah Service",
  },
  {
    value: "9",
    label: "Shillong",
  },
];

const ExportCustomers = ({ customers, month, choice, store }) => {
  return (
    <div>
      <ExcelFile
        filename={`Customers_${
          choice === "Export All"
            ? `storeId_${store}`
            : `${monthNames[month]}_store_${store}`
        }`}
        element={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<FaFileExcel />}
          >
            {choice === "Export All"
              ? `Export All Customers For Store ${store}`
              : `EXPORT CUSTOMERS FOR MONTH ${monthNames[month]} store ${store}`}
          </Button>
        }
      >
        <ExcelSheet data={customers} name="Customers">
          {Object.keys(customers[0]).map((item, index) => (
            <ExcelColumn key={index} label={item} value={item} />
          ))}
        </ExcelSheet>
      </ExcelFile>
    </div>
  );
};

const ExportCustomerInfo = (props) => {
  const { billLoading, customersFromBills } = useSelector(
    (state) => state.bills
  );
  const [store, setStore] = useState("");

  const [customers, setCustomers] = useState([]);
  const startDate = props.period;
  const choice = props.choice;
  const month = startDate.getMonth();
  const year = startDate.getFullYear();
  const dispatch = useDispatch();

  useEffect(() => logCustomers(), [startDate, choice, store]);
  useEffect(() => {
    if (customersFromBills.length > 0) setCustomers(customersFromBills);
  }, [customersFromBills]);
  const logCustomers = () => {
    if (!store) {
      return;
    }
    if (choice === "Export Monthly") {
      const date = year + String(month + 1).padStart(2, "0");
      dispatch(adminGetCustomerInfoByDate(date, store));
    }
    if (choice === "Export All" && store !== "") {
      dispatch(adminGetAllCustomerByStore(store));
    }
  };

  return (
    <>
      {billLoading && (
        <LoadingScreen>
          <CircularProgress size={200} />
        </LoadingScreen>
      )}

      {choice === "" ? (
        <></>
      ) : !store ? (
        <Container>
          <h3>Choose Store</h3>
          <br />
          <TextField
            select
            label="Select Store Location"
            value={store}
            onChange={(e) => setStore(e.target.value)}
            variant="outlined"
            className="select"
            fullWidth
          >
            {types.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Container>
      ) : (
        <h4>
          Store: <Red>{types.filter((t) => t.value === store)[0].label}</Red>
          <Button
            style={{ marginLeft: "10px" }}
            variant="outlined"
            color="secondary"
            size="small"
            onClick={(e) => setStore("")}
          >
            change
          </Button>
        </h4>
      )}
      <br />
      <br />
      {customers.length > 0 && store !== "" ? (
        <ExportCustomers
          customers={customers}
          month={month}
          choice={choice}
          store={store}
        />
      ) : (
        ""
      )}
    </>
  );
};

const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(256, 256, 256, 0.7);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Container = styled.div`
  width: 100%;
  margin: 20px auto;
`;
const Red = styled.span`
  color: red;
`;

export default ExportCustomerInfo;
