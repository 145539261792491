import NavList from "./navigation/NavList"
import Logo from './Logo'
import RightHeaderList from "./RightHeaderList"

const Header = () => {
    return (
        <div className="header">
            <Logo />
            <NavList />
            <RightHeaderList />
        </div>
    )
}

export default Header
