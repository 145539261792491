import React from "react";

const manufactured = () => {
    return <div>manufactured</div>;
};

export default manufactured;
// import { useEffect, useContext } from "react";
// import Layout from "../../components/Layout";
// import Breadcrump from "../../components/Breadcrump";
// import AuthContext from "../../context/AuthContext";
// import MarkForChallan from "../../components/purchase/MarkForChallan";

// const Manufactured = ({ user }) => {
//   const { setUser } = useContext(AuthContext);

//   useEffect(() => {
//     if (user) setUser(user);
//   }, []);

//   const widgets = [];

//   return (
//     <Layout title="Generate Challan | LITTLEBOX">
//       <Breadcrump path="/Vendor/Mark For Challan" widgets={widgets} />
//       <MarkForChallan />
//     </Layout>
//   );
// };

// export async function getServerSideProps(ctx) {
//   const API_URL = `${process.env.API_URL}/auth/user`;

//   // Check cookie
//   if (ctx.req.headers.cookie) {
//     const token = ctx.req.headers.cookie.split("=")[1];

//     const response = await fetch(API_URL, {
//       method: "GET",
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     const user = await response.json();

//     if (response.ok) {
//       if (user.role !== "vendor") {
//         return {
//           redirect: {
//             destination: "/",
//             permanent: false,
//           },
//         };
//       }
//       return { props: { user } };
//     }
//   }

//   // Else
//   return {
//     redirect: {
//       destination: "/login",
//       permanent: false,
//     },
//   };
// }

// export default Manufactured;
