import { useSelector } from "react-redux";
import styled from "styled-components";
import Layout from "../../Layout";
import Breadcrump from "../../Breadcrump";
import AddCatalogueForm from "../../forms/AddCatalogueForm";
import RedirectLogin from "../../RedirectLogin";

const ManageCatalogue = () => {
    const auth = useSelector((state) => state.auth);

    return (
        <>
            {!!auth ? (
                <Layout title="Add Catalogue Products | LITTLEBOX">
                    <Breadcrump path="/Catalogue/Add Products" widgets={[]} />
                    <Content className="content-container">
                        <AddCatalogueForm />
                    </Content>
                </Layout>
            ) : (
                <RedirectLogin />
            )}
        </>
    );
};

const Content = styled.div`
    width: 100%;
`;

export default ManageCatalogue;
