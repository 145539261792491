const initialState = null;

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LOADED":
    case "SIGN_IN":
      return {
        ...initialState,
        action,
      };
    case "SIGN_IN_INVALID":
      return state;
    case "REFRESH_CREDENTIALS":
      return {
        ...initialState,
        action,
      };
    default:
      return state;
  }
};

export default authReducer;
