import { useState, useEffect } from "react";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import Card from "../Card";
import BarcodeScanner from "./BarcodeScanner";
import BillingTable from "./BillingTable";
import BarcodeText from "./BarcodeText";
import { useSelector, useDispatch } from "react-redux";
import { getAllCoupons } from "../../actions/billsAction";
import {
  getAllDiscountCategories,
  getInventoryByStoreId,
} from "../../actions/inventoryActions";

const BillingItems = () => {
  const bills = useSelector((state) => state.bills);
  const { inventoryLoading, inventory, discountCategories } = useSelector(
    (state) => state.inventory
  );
  const { billLoading } = bills;
  const { coupons } = bills.billing;

  const [pageLoading, setPageLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!inventory.length > 0) dispatch(getInventoryByStoreId());
    if (!discountCategories.length > 0) dispatch(getAllDiscountCategories());
    if (!coupons.length > 0) dispatch(getAllCoupons());
  }, []);

  useEffect(() => {
    if (inventory.length > 0 && discountCategories.length > 0) {
      setPageLoading(false);
    } else {
      setPageLoading(true);
    }
  }, [inventory, discountCategories]);

  return (
    <Card style={{ flexDirection: "column", height: "max-content" }}>
      {(billLoading || inventoryLoading || pageLoading) && (
        <LoadingScreen>
          <CircularProgress size={200} />
        </LoadingScreen>
      )}
      <Scanner>
        <BarcodeScanner />
        <BarcodeText />
      </Scanner>
      <BillingTable />
    </Card>
  );
};

const Scanner = styled.div`
  margin: 10px;
  height: max-content;
  display: flex;
  gap: 30px;
`;

const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(256, 256, 256, 0.7);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default BillingItems;
