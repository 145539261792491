import React, { useState, useEffect } from "react";
import { useBarcode } from "next-barcode";
import { useSelector } from "react-redux";
import styled from "styled-components";

const NO_GST_STORES = ["4", "5"];

const Barcode = ({ code }) => {
    const { inputRef } = useBarcode({
        value: code,
        options: {
            background: "#fff",
            width: 1.5,
            height: 25,
            fontSize: 10,
        },
    });

    return <svg ref={inputRef} />;
};

const BillRow = ({ index, product }) => {
    return (
        <tr>
            <td>{index + 1}</td>
            <td className="left">{product.name.substring(0, 20)}</td>
            <td>{product.mrp}</td>
            <td>{product.count}</td>
            <td>{product.discountAmt}</td>
            <td>{product.total}</td>
        </tr>
    );
};

const BillPDFComponent = React.forwardRef((props, ref) => {
    const { lastBill } = useSelector((state) => state.bills.billing);
    const { store, storeId } = useSelector((state) => state.auth.action.data);

    const [isGSTAvailable, setIsGSTAvailable] = useState(
        !NO_GST_STORES.find((id) => id === storeId)
    );

    useEffect(() => {
        setIsGSTAvailable(!NO_GST_STORES.find((id) => id === storeId));
    }, [storeId]);

    const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    const tDate = new Date().toLocaleDateString(undefined, options);
    const tTime = new Date().toLocaleTimeString();

    return (
        <div ref={ref}>
            <CenteredHeading>LITTLEBOX INDIA</CenteredHeading>
            <CenteredHeading>Managed By -Santana Folks LLP</CenteredHeading>

            {lastBill && (
                <>
                    <CenteredPara>
                        Store Location: {store["storeAddress"]}
                    </CenteredPara>
                    {isGSTAvailable && (
                        <CenteredPara>
                            Store GST No: {store["storeGST"]}
                        </CenteredPara>
                    )}
                    <br />
                    <CenteredPara>Billed To: {lastBill.customer}</CenteredPara>
                    <br />
                    <CenteredPara>Bill Date: {tDate}</CenteredPara>
                    <CenteredPara>Bill Time: {tTime}</CenteredPara>
                    <br />
                    <TableBox>
                        <table>
                            <thead>
                                <tr>
                                    <td>No.</td>
                                    <td className="left">Name</td>
                                    <td>M.R.P.</td>
                                    <td>Count</td>
                                    <td>Discount</td>
                                    <td>Total</td>
                                </tr>
                            </thead>
                            <tbody>
                                {lastBill.products.map((item, index) => (
                                    <BillRow
                                        key={index}
                                        index={index}
                                        product={item}
                                    />
                                ))}
                                {lastBill.coupon && (
                                    <tr>
                                        <td></td>
                                        <td className="left">Coupon</td>
                                        <td>{lastBill.coupon.name}</td>
                                        <td colSpan="2"></td>
                                        <td>
                                            {lastBill.coupon.type === "amount"
                                                ? lastBill.coupon.value + ".00"
                                                : lastBill.coupon.value + "%"}
                                        </td>
                                    </tr>
                                )}
                                {lastBill.bags && (
                                    <tr>
                                        <td></td>
                                        <td className="left">BAGS</td>
                                        <td>{lastBill.bags.quantity}</td>
                                        <td colSpan="2"></td>
                                        <td>{lastBill.bags.price}</td>
                                    </tr>
                                )}
                                <tr>
                                    <td></td>
                                    <td className="left">Discounts</td>
                                    <td colSpan="3"></td>
                                    <td>{lastBill.grandTotalDiscount}</td>
                                </tr>
                                {isGSTAvailable && (
                                    <tr>
                                        <td></td>
                                        <td className="left">Total Cost</td>
                                        <td colSpan="3"></td>
                                        <td>{lastBill.cost}</td>
                                    </tr>
                                )}
                                {isGSTAvailable && (
                                    <tr>
                                        <td></td>
                                        <td className="left">G.S.T.</td>
                                        <td colSpan="3"></td>
                                        <td>{lastBill.gst}</td>
                                    </tr>
                                )}
                                <tr>
                                    <td></td>
                                    <td className="left">
                                        <strong>TOTAL</strong>
                                    </td>
                                    <td colSpan="3"></td>
                                    <td>
                                        <strong>{lastBill.grandTotal}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </TableBox>
                    <CenteredPara>Payment Method</CenteredPara>
                    <p>Cash: {lastBill.paymentMethod.cash}</p>
                    <p>Card: {lastBill.paymentMethod.card}</p>
                    <p>Cheque: {lastBill.paymentMethod.cheque}</p>
                    <p>UPI: {lastBill.paymentMethod.wallet}</p>

                    <br />
                    {lastBill.noteToCustomer ? (
                        <CenteredPara>
                            NOTE: {lastBill.noteToCustomer}
                        </CenteredPara>
                    ) : (
                        ""
                    )}
                    <hr />
                    <CenteredPara>
                        <Barcode code={lastBill.barcode} />
                    </CenteredPara>
                    <br />
                    <hr />
                    <CenteredPara>Terms & Conditions</CenteredPara>
                    <Bullets>
                        <li>
                            'All products bought in physical stores of LITTLEBOX
                            will be exchanged with only the different size of
                            same style.'
                        </li>
                        <li>
                            'The bar code sticker has to be intact as same as
                            during the time of sales.'
                        </li>
                        <li>
                            'Any wear & tear or any product found in used
                            condition won’t be exchanged.'
                        </li>
                        <li>
                            'Any item to be exchanged has to be bought to store
                            within 3 days of shopping from the physical store.'
                        </li>
                        <li>
                            'Only if same product in size asked by customer
                            during the exchange is not available & once
                            inspected by store team for the quality, a store
                            credit will be provided which can be redeemed in any
                            retail outlets in India & not e-commerce store.'
                        </li>
                        <li>
                            'All the hang tags has to be intact with the apparel
                            in order to proceed for exchange . Please be
                            informed that without the tags & labels intact
                            during the time of sales won’t be accepted for
                            exchange.'
                        </li>
                        <li>
                            'Please be informed that we do not have any money
                            refund policy. Thanks for your kind cooperation.'
                        </li>
                        <li>
                            'All the legal disputes will be handled under the
                            law of govt of india.'
                        </li>
                    </Bullets>
                </>
            )}
        </div>
    );
});

const CenteredHeading = styled.h3`
    text-align: center;
    padding: 30px 10px 10px 10px;
`;
const CenteredPara = styled.p`
    text-align: center;
    padding: 0 10px;
`;
const TableBox = styled.div`
    padding: 0 5px;

    & table {
        border-spacing: 5px;
    }

    & td {
        text-align: right;
        font-size: 0.7rem;
    }

    & .left {
        text-align: left;
    }
`;
const Bullets = styled.ol`
    padding: 10px;
    & > li {
        font-size: 0.8rem;
    }
`;

export default BillPDFComponent;
