

const CatalogueTableRow = ({ item, productType }) => {


  const { url, name, id, index, costPrice, mrp, ...restInfo } = item;

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <img width="100" height="100" src={url} alt="SSS" />
      </td>
      <td>{id}</td>
      <td>{name}</td>
      <td>{costPrice}</td>
      <td>{mrp}</td>
      {(productType === "clothing"
        ? Object.keys(restInfo).sort().reverse()
        : Object.keys(restInfo).sort()
      ).map((k) => (
        <td key={k}>{restInfo[k]}</td>
      ))}
    </tr>
  );
};

export default CatalogueTableRow;
