import AWS from "aws-sdk";
const identitypool = new AWS.CognitoIdentity({ region: "ap-south-1" });
export const getId = async (token) => {
  var params = {
    IdentityPoolId:
      "ap-south-1:09101cb4-e45f-404e-a1e9-a18d4613651b" /* required */,
    Logins: {
      "cognito-idp.ap-south-1.amazonaws.com/ap-south-1_zV5d4xCKw": token,
      /* '<IdentityProviderName>': ... */
    },
  };
  return await new Promise((resolve, reject) => {
    identitypool.getId(params, function (err, data) {
      if (err) {
        console.log(err, err.stack); // an error occurred
        reject();
      } else {
        getCredentials(data.IdentityId, token).then((userkeys) => {
          resolve({
            status: 200,
            data: userkeys.data.Credentials,
          });
        });
      } // successful response
    });
  });
};

export const getCredentials = async (id, token) => {
  var params = {
    IdentityId: id /* required */,
    // IdentityPoolId: "ap-south-1:f513555b-da6a-4189-a7ab-ecf9a48df1a1",
    Logins: {
      "cognito-idp.ap-south-1.amazonaws.com/ap-south-1_zV5d4xCKw": token,
      /* '<IdentityProviderName>': ... */
    },
  };
  return await new Promise((resolve, reject) => {
    identitypool.getCredentialsForIdentity(params, function (err, data) {
      if (err) {
        console.log(err, err.stack);
        reject();
      } // an error occurred
      else {
        resolve({
          status: 200,
          data,
        });
      } // successful response
    });
    //   AWS.config.credentials = new AWS.CognitoIdentityCredentials(params, {
    //     region: "ap-south-1",
    //   });
  });
};
