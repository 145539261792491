import { useSelector } from "react-redux";
import styled from "styled-components";
import BillingRow from "./BillingRow";

const BillingTable = () => {
  const { billedProducts } = useSelector((state) => state.bills.billing);

  return (
    <Container>
      <TableBox>
        <Table>
          <Thead>
            <tr>
              <td>
                <CountSpan>{billedProducts?.length || "0"}</CountSpan>Item
              </td>
              <td>Image</td>
              <td>Id</td>
              <td>Barcode</td>
              <td>Name</td>
              <td>Size</td>
              <td>Stock</td>
              <td>M.R.P</td>
              <td>Combo Offer</td>
              <td>Count</td>
              <td>Discount Amt.</td>
              <td>Total</td>
              <td>Edit Item</td>
            </tr>
          </Thead>
          {billedProducts && billedProducts.length > 0 && (
            <tbody>
              {billedProducts.map((item, index) => (
                <BillingRow key={index} item={item} index={index} />
              ))}
            </tbody>
          )}
        </Table>
      </TableBox>
    </Container>
  );
};

const Container = styled.div`
  padding: 10px;
`;

const Table = styled.table`
  border-spacing: 0px;
  & td {
    padding: 5px 0 5px 5px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-align: left;
  }
`;

const Thead = styled.thead`
  & > tr > * {
    padding: 8px 0 8px 5px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-align: left;
    background: #ccc;
  }
`;

const TableBox = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 10px #ddd;

  & > * {
    flex: 1;
    font-size: 1.05rem;
  }
`;

const CountSpan = styled.span`
  padding: 5px 8px;
  height: 30px;
  border-radius: 50%;
  background: #73c24c;
  font-size: 0.8rem;
  font-weight: bold;
  color: #fefefe;
  margin-right: 10px;
`;

export default BillingTable;
