
import { getSizesByType } from '../../utils/productUtils'

const StockTableRow = ({ item, index, productType }) => {

    const sizes = getSizesByType(productType)

    return (
        <tr>
            <td>{index + 1}</td>
            <td><img width="100" height="100" src={item.url} alt="SSS" /></td>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.mrp}</td>
            {sizes.map(k => <td key={k}>{item[k]}</td>)}
            <td>{item.total}</td>
        </tr>
    )
}

export default StockTableRow