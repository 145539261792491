import { formatCurrency } from ".";
const FOOTWEAR_GST_RATE = 1.12;
const APARREL_GST_RATE = 1.05;
const BAG_GST_RATE = 1.18;

const getTotalDays = (date) => {
    const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const month = date.getMonth();
    const year = date.getFullYear();
    const isLeap = year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
    return isLeap && month === 1 ? monthDays[month] + 1 : monthDays[month];
};

const getDayWiseBill = (bills, date) => {
    const count = getTotalDays(date);
    const dayWiseBills = [];
    for (let i = 0; i < count; i++) {
        const dayBills = bills.filter(
            (d) => new Date(d.soldAt).getUTCDate() === i + 1
        );
        dayWiseBills.push(dayBills);
    }
    return dayWiseBills;
};

export const createGSTReport = (bills, date, inventory) => {
    console.log("date", date);
    let nextDay = new Date(date);

    const data = getDayWiseBill(bills, date).map((day) => {
        let footwearQnty = 0,
            footwearSales = 0,
            aparrelQnty = 0,
            aparrelSales = 0,
            importedQnty = 0,
            importedSales = 0,
            accQnty = 0,
            accSales = 0,
            otherSales = 0;

        const today =
            day.length > 0 ? new Date(day[0].soldAt) : new Date(nextDay);
        const bdate =
            day.length > 0
                ? today.toDateString()
                : new Date(nextDay).toDateString();
        nextDay = today.setUTCDate(today.getUTCDate() + 1);

        day.forEach((bill) => {
            let total = 0;
            bill.products.forEach((p) => {
                total = total + parseFloat(p.total);
                let category;
                if (p.type) {
                    category = p.type;
                } else {
                    // Match from inventory
                    const product = inventory.filter((i) => i._id === p._id)[0];
                    category = product?.type;
                }
                switch (category) {
                    case "footwear":
                        footwearQnty += p.count;
                        footwearSales += parseFloat(p.total);
                        break;
                    case "clothing":
                        aparrelQnty += p.count;
                        aparrelSales += parseFloat(p.total);
                        break;
                    case "imported":
                        console.log("imported", bill.barcode);
                        importedQnty += p.count;
                        importedSales += parseFloat(p.total);
                        break;
                    case "accessories":
                        console.log("accessories", bill.barcode);
                        accQnty += p.count;
                        accSales += parseFloat(p.total);
                        break;
                    default:
                        otherSales += p.count;
                        console.log("category failed", category);
                        break;
                }
            });
            if (total !== bill.total) {
                console.log("total", total);
                console.log("bill total", bill.total);
                console.log("error", bill.barcode);
                console.log("other sales", otherSales);
            }
        });
        const bag = formatCurrency(
            day.reduce((sum, i) => sum + parseFloat(i.bags.price), 0)
        );
        const coupon = formatCurrency(
            day.reduce((sum, i) => {
                if (!i.coupon) {
                    return sum + 0;
                }
                return sum + parseFloat(i.coupon.value);
            }, 0)
        );
        const cash = formatCurrency(
            day.reduce((sum, i) => sum + parseFloat(i.paymentMethod.cash), 0)
        );
        const card = formatCurrency(
            day.reduce((sum, i) => sum + parseFloat(i.paymentMethod.card), 0)
        );
        const payTM = formatCurrency(
            day.reduce((sum, i) => sum + parseFloat(i.paymentMethod.wallet), 0)
        );
        const sales = formatCurrency(
            day.reduce((sum, i) => sum + parseFloat(i.total), 0)
        );

        return {
            BillingDate: bdate,
            FootwearQuantity: footwearQnty,
            AparrelQuantity: aparrelQnty,
            ImportedQuantity: importedQnty,
            AccessoriesQuantity: accQnty,
            FootwearValue: formatCurrency(footwearSales),
            AparrelValue: formatCurrency(aparrelSales),
            ImportedValue: formatCurrency(importedSales),
            AccessoriesValue: formatCurrency(accSales),
            Coupon: coupon,
            bag: bag,
            Cash: cash,
            Card: card,
            PAYTM: payTM,
            TotalSales: sales,
            FootwearGST: formatCurrency(
                footwearSales * (1 - 1 / FOOTWEAR_GST_RATE)
            ),
            AparrelGST: formatCurrency(
                aparrelSales * (1 - 1 / APARREL_GST_RATE)
            ),
            AccessoriesGST: formatCurrency(accSales * (1 - 1 / BAG_GST_RATE)),

            ImportedGST: formatCurrency(
                importedSales * (1 - 1 / APARREL_GST_RATE)
            ),
        };
    });
    return data;
};
