import { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import DisplaySummary from "./DisplaySummary";
import ConfirmAlert from "../ConfirmAlert";
import PaymentCollectionMethodDropdown from "./PaymentCollectionMethodDropdown";
import { formatCurrency } from "../../utils";
import { CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getMonthlyBillsByStoreId } from "../../actions/billsAction";
import {
  getyesterdayclosingbalance,
  postDailyCash,
} from "../../actions/expensesActions";
import { getExpensesBystoreId } from "../../actions/expensesActions";

const ReportSummaryV1 = () => {
  const { todayBills, billLoading, todayBillEmpty } = useSelector(
    (state) => state.bills
  );
  const { openingBalance, expenses, expenseLoading, todayExpenses } =
    useSelector((state) => state.expenses);

  const [cashSale, setCashSale] = useState(0);
  const [todayCash, setTodayCash] = useState("0.00");
  const [openDialogue, setOpenDialogue] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [dropdownMenuData, setDropdownMenuData] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!todayBills.length > 0) {
      dispatch(getMonthlyBillsByStoreId());
    }
    if (!expenses.length > 0) {
      dispatch(getExpensesBystoreId());
    }
    if (!openingBalance.length > 0) {
      dispatch(getyesterdayclosingbalance());
    }
  }, []);

  useEffect(() => {
    if (
      !billLoading &&
      !expenseLoading &&
      (todayBillEmpty || todayBills.length > 0)
    ) {
      setCashSale(parseFloat(getTodayCash()));

      const todayExpensesAmt = todayExpenses.reduce(
        (acc, b) => acc + parseFloat(b.amount),
        0
      );

      setTodayCash(
        formatCurrency(
          parseFloat(openingBalance) +
          parseFloat(getTodayCash()) -
          todayExpensesAmt
        )
      );

      return;
    }
  }, [
    billLoading,
    expenseLoading,
    expenses,
    openingBalance,
    todayBills,
    todayBillEmpty,
  ]);

  useEffect(() => {
    if (!billLoading && !expenseLoading)
      setDialogData(
        `Save Closing Balance of Rs. ${todayCash} on ${new Date().toDateString()}`
      );
  }, [todayCash, billLoading, expenseLoading]);

  useEffect(() => {
    const data = todayBills?.map((item) => ({
      cash: item.paymentMethod.cash,
      card: item.paymentMethod.card,
      upi: item.paymentMethod.wallet,
      cheque: item.paymentMethod.cheque,
    }));
    setDropdownMenuData(data);
  }, [todayBills]);

  const cancelChanges = () => setOpenDialogue(false);

  const handleSubmit = () => {
    dispatch(postDailyCash(cashSale, todayCash));
    setOpenDialogue(false);
  };

  const getTodaySales = () =>
    formatCurrency(
      todayBills?.reduce((acc, b) => acc + parseFloat(b.total), 0)
    );

  const getTodayCash = () =>
    formatCurrency(
      todayBills.reduce((acc, b) => acc + parseFloat(b.paymentMethod.cash), 0)
    );

  return (
    <>
      {(billLoading ||
        expenseLoading ||
        !(todayBillEmpty || todayBills.length > 0)) && (
          <LoadingScreen>
            <CircularProgress size={200} />
          </LoadingScreen>
        )}

      <Container>
        <Column>
          <h4>Today's</h4>
          <DisplaySummary
            loading={billLoading || expenseLoading}
            sale={getTodaySales()}
            saleR={todayCash}
            left
          />
          <PaymentCollectionMethodDropdown data={dropdownMenuData} />
        </Column>
      </Container>
      {!billLoading && !expenseLoading && (
        <ClosingBalance>
          <Warning>Save Closing Balance* </Warning>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => setOpenDialogue(true)}
          >
            Save
          </Button>
        </ClosingBalance>
      )}
      <ConfirmAlert
        data={dialogData}
        open={openDialogue}
        handleCancel={cancelChanges}
        handleConfirm={handleSubmit}
      />
      <br />
      <br />
    </>
  );
};

const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(256, 256, 256, 0.7);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const Column = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;

  & > h4 {
    text-align: center;
    margin-bottom: 10px;
  }
`;
const ClosingBalance = styled.div`
  display: flex;
  padding: 10px;
  border: 1px dashed red;
  gap: 20px;
`;

const Warning = styled.span`
  color: green;
  font-size: 1.5rem;
`;

export default ReportSummaryV1;
