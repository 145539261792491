import { useState } from "react";
import styled from "styled-components";
import Calendar from "react-calendar";
import Layout from "../../../Layout";
import Breadcrump from "../../../Breadcrump";
import GSTReport from "../../../reports/GSTReport";
import RedirectLogin from "../../../RedirectLogin";
import { useSelector } from "react-redux";
import "react-calendar/dist/Calendar.css";

const GSTReportPage = ({ user }) => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const auth = useSelector((state) => state.auth);

    const widgets = [];

    return (
        <>
            {!!auth ? (
                <Layout title="G.S.T. Report | LITTLEBOX">
                    <Breadcrump
                        path="/Reports/Sales/GST Report"
                        widgets={widgets}
                    />

                    <Heading>Select Month</Heading>
                    <Calendar
                        className="center calender-zoom"
                        onChange={setSelectedDate}
                        value={selectedDate}
                        view="year"
                        maxDate={new Date()}
                        minDate={new Date("2021-09-03")}
                        onClickMonth={setSelectedDate}
                    />
                    <GSTReport period={selectedDate} />
                </Layout>
            ) : (
                <RedirectLogin />
            )}
        </>
    );
};

const Heading = styled.h1`
    margin: 20px 0;
    text-align: center;
`;

export default GSTReportPage;
