const initialState = {
  authLoading: false,
};

const loadReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_AUTH_LOADING":
      return {
        ...state,
        authLoading: true,
      };
    case "HIDE_AUTH_LOADING":
      return {
        ...state,
        authLoading: false,
      };
    default:
      return state;
  }
};

export default loadReducer;
