import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../utils/UserPool";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
import { getId, getCredentials } from "../utils/IdentityPool";
import { appReducer } from "../reducers/rootReducer";
const identitypool = new AWS.CognitoIdentity({ region: "ap-south-1" });

export const showLoading = () => {
  return {
    type: "SHOW_AUTH_LOADING",
  };
};

export const hideLoading = () => {
  return {
    type: "HIDE_AUTH_LOADING",
  };
};

export const signIn = (Username, Password) => {
  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool,
      });

      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          const token = data.idToken.jwtToken;
          const user = {
            storeId: data.idToken.payload["custom:storeId"],
            role: data.idToken.payload["custom:role"],
            name: data.idToken.payload["name"],
            state: data.idToken.payload["custom:state"],

            store: {
              storeNumber: data.idToken.payload["custom:storeNumber"],
              storeGST: data.idToken.payload["custom:storeGST"],
              storeName: data.idToken.payload["custom:storeName"],
              storeAddress: data.idToken.payload["custom:storeAddress"],
            },
          };

          getId(data.idToken.jwtToken).then((id) => {
            resolve({
              data: user,
              verified: true,
              credentials: id.data,
              token,
            });
          });
          toast("Successfully Logged In");
        },
        onFailure: (err) => {
          console.error("Error", err);
          reject(err);
          toast.error("Incorrect Username or Password", {
            theme: "colored",
          });
        },
        newPasswordRequired: (userAttributes) => {
          user.completeNewPasswordChallenge(Password);
          resolve({ userAttributes, verified: false });
        },
      });
    });
  };

  return (dispatch) => {
    authenticate(Username, Password).then(
      ({ data, verified, credentials, token }) => {
        verified
          ? dispatch({
              type: "SIGN_IN",
              data,
              credentials,
              token,
            })
          : dispatch({
              type: "SIGN_IN_INVALID",
            });
      }
    );
  };
};

export const loadUser = () => {
  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err, data) => {
          if (err) {
            toast("Cannot Logged In");
            reject();
          } else {
            const token = data.idToken.jwtToken;
            const user = {
              storeId: data.idToken.payload["custom:storeId"],
              role: data.idToken.payload["custom:role"],
              name: data.idToken.payload["name"],
              state: data.idToken.payload["custom:state"],

              store: {
                storeNumber: data.idToken.payload["custom:storeNumber"],
                storeGST: data.idToken.payload["custom:storeGST"],
                storeName: data.idToken.payload["custom:storeName"],
                storeAddress: data.idToken.payload["custom:storeAddress"],
              },
            };
            getId(data.idToken.jwtToken).then((id) => {
              resolve({
                data: user,
                credentials: id.data,
                token,
              });
            });
          }
        });
      } else {
        reject();
      }
    });
  };
  return (dispatch) => {
    getSession().then(({ data, credentials, token }) => {
      dispatch({
        type: "USER_LOADED",
        data,
        credentials,
        token,
      });
    });
  };
};

export const logOut = () => (dispatch) => {
  const user = Pool.getCurrentUser();
  if (user) {
    user.signOut();
    toast("User Logged Out");
    // Clear session, localStorage
    localStorage.clear();
    dispatch({
      type: "LOG_OUT",
    });
  } else {
    toast("User Not Found");
  }
};
