import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Card from "../Card";
import { formatCurrency } from "../../utils";
import { CircularProgress } from "@mui/material";
import { getInventoryByStoreId } from "../../actions/inventoryActions";
import {
    getMonthlyBillsByStoreId,
    getPrevMonthBills,
} from "../../actions/billsAction";

const ProfitReport = ({ date }) => {
    const {
        monthBills,
        billLoading,
        lastMonthBillFecthed: fetched,
    } = useSelector((state) => state.bills);
    const { inventory, inventoryLoading } = useSelector(
        (state) => state.inventory
    );
    const [loading, setLoading] = useState(true);
    const [totalCost, setTotalCost] = useState(0);
    const [totalSale, setTotalSale] = useState(0);
    const [profit, setProfit] = useState("0.00");
    const dispatch = useDispatch();
    useEffect(() => {
        if (fetched) return;
        const month = date.getMonth();
        const currentMonth = new Date().getMonth();
        console.log("current month", currentMonth);
        console.log("month", month);
        if (currentMonth > month || (currentMonth === 0 && month === 11)) {
            dispatch(getPrevMonthBills());
        }
        // eslint-disable-next-line
    }, [date]);

    useEffect(() => {
        if (!inventory.length > 0) {
            dispatch(getInventoryByStoreId());
        }
        if (!monthBills.length > 0) {
            dispatch(getMonthlyBillsByStoreId());
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const bills = monthBills.filter(
            (b) =>
                new Date(b.soldAt).toLocaleDateString() ===
                new Date(date).toLocaleDateString()
        );
        const totalCP = bills.reduce(
            (acc, bill) =>
                bill.products.reduce((sum, p) => {
                    const product = inventory.filter(
                        (pro) => pro._id === p._id
                    )[0];
                    const cp = product
                        ? parseFloat(product.costPrice) * p.count
                        : 0;
                    return cp + sum;
                }, 0) + acc,
            0
        );

        setTotalCost(formatCurrency(totalCP));

        const total = bills.reduce((acc, b) => acc + parseFloat(b.total), 0);
        setTotalSale(formatCurrency(total));

        setProfit(formatCurrency(total - totalCP));
        setLoading(false);
    }, [date, inventory, monthBills]);

    return (
        <Container>
            {(loading || inventoryLoading || billLoading) && (
                <LoadingScreen>
                    <CircularProgress size={200} />
                </LoadingScreen>
            )}
            {!loading && (
                <Card>
                    <Col>
                        <h3>Total Revenue</h3>
                        <p>{totalSale}</p>
                    </Col>
                    <Col>
                        <h3>Total Cost</h3>
                        <p>{totalCost}</p>
                    </Col>
                    <Col>
                        <h3>Total Profit</h3>
                        <p>{profit}</p>
                    </Col>
                </Card>
            )}
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
`;

const Col = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
`;

const LoadingScreen = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(256, 256, 256, 0.7);
    z-index: 1100;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default ProfitReport;
