import { getAwsAuthorization } from "../utils/Signature";
import { formatCurrency, calculateCombo } from "../utils";
import { toast } from "react-toastify";
import { getId } from "../utils/IdentityPool";
const FOOTWEAR_GST_RATE = 1.12;
const REST_GST_RATE = 1.05;
const BAG_GST_RATE = 1.18;

export const showBillLoading = () => {
  return {
    type: "SHOW_BILL_LOADING",
  };
};

export const hideBillLoading = () => {
  return {
    type: "HIDE_BILL_LOADING",
  };
};

export const todayBillEmpty = () => {
  return {
    type: "TODAY_BILL_EMPTY",
  };
};

export const monthBillEmpty = () => {
  return {
    type: "MONTH_BILL_EMPTY",
  };
};

export const getMonthlyBillsByStoreId = () => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSgetmonthlybillsbystoreid";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const { storeId } = state.auth.action.data;
  let payload = {
    storeId,
    LastEvaluatedKey: null,
  };

  dispatch(showBillLoading());
  let fetching = true;
  let allMonthlyBills = [];
  try {
    while (fetching) {
      let authorizationHeaders = getAwsAuthorization(
        AccessKeyId,
        SecretKey,
        SessionToken,
        payload,
        URL
      );
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.statusCode === 200) {
        const monthBills = data.data;
        allMonthlyBills.push(...monthBills);
        payload.LastEvaluatedKey = data.LastEvaluatedKey;
        if (!payload.LastEvaluatedKey) {
          fetching = false;
        }
      } else {
        allMonthlyBills = [];
        fetching = false;
        toast.error("Error Fetching Bills", {
          theme: "colored",
        });
      }
    }
    dispatch(hideBillLoading());
    const todayBills = allMonthlyBills.filter(
      (b) =>
        new Date(b.soldAt).toLocaleDateString() ===
        new Date().toLocaleDateString()
    );
    dispatch({
      type: "SHOW_MONTH_BILLS",
      payload: allMonthlyBills,
    });
    dispatch({
      type: "SHOW_TODAY_BILLS",
      payload: todayBills,
    });
    if (!allMonthlyBills.length > 0) {
      dispatch(monthBillEmpty());
    }
    if (!todayBills.length > 0) {
      dispatch(todayBillEmpty());
    }
  } catch (err) {
    dispatch(hideBillLoading());
    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const getPrevMonthBills = () => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSadmingetlastmonthbills";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const { storeId } = state.auth.action.data;
  const { monthBills } = state.bills;
  let payload = {
    storeId,
    LastEvaluatedKey: null,
  };

  dispatch(showBillLoading());
  let fetching = true;
  let allLastMonthBills = [];
  try {
    while (fetching) {
      let authorizationHeaders = getAwsAuthorization(
        AccessKeyId,
        SecretKey,
        SessionToken,
        payload,
        URL
      );
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.statusCode === 200) {
        const lastMonthBills = data.data;
        allLastMonthBills.push(...lastMonthBills);
        payload.LastEvaluatedKey = data.LastEvaluatedKey;
        if (!payload.LastEvaluatedKey) {
          fetching = false;
        }
      } else {
        allLastMonthBills = [];
        fetching = false;
        toast.error("Error", {
          theme: "colored",
        });
      }
    }
    dispatch(hideBillLoading());
    const twoMonthBills = monthBills.concat(allLastMonthBills);

    dispatch({
      type: "SHOW_MONTH_BILLS",
      payload: twoMonthBills,
    });
    dispatch({
      type: "FETCHED_LAST_MONTH_BILL",
      payload: true,
    });
    // if (!allMonthlyBills.length > 0) {
    //   dispatch(monthBillEmpty());
    // }
  } catch (err) {
    dispatch(hideBillLoading());
    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

// no need
export const getGstReports = () => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSgetoldgstreports";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const { storeId } = state.auth.action.data;
  let payload = {
    storeId: storeId,
    LastEvaluatedKey: null,
  };
  dispatch(showBillLoading());
  let fetching = true;
  let allGstReports = [];
  try {
    while (fetching) {
      let authorizationHeaders = getAwsAuthorization(
        AccessKeyId,
        SecretKey,
        SessionToken,
        payload,
        URL
      );
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.statusCode === 200) {
        const gstReports = data.data;
        allGstReports.push(...gstReports);
        payload.LastEvaluatedKey = data.LastEvaluatedKey;
        if (!payload.LastEvaluatedKey) {
          fetching = false;
        }
      } else {
        allGstReports = [];
        fetching = false;
        toast.error("Error fetching gst reports", {
          theme: "colored",
        });
      }
    }
    dispatch(hideBillLoading());
    dispatch({
      type: "SHOW_GST_REPORTS",
      payload: allGstReports,
    });
  } catch (err) {
    dispatch(hideBillLoading());

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const getThreeMonthBills = () => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSgetthreemonthbills";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const { storeId } = state.auth.action.data;
  let payload = {
    storeId,
    LastEvaluatedKey: null,
  };
  dispatch(showBillLoading());
  let fetching = true;
  let allThreeMonthBills = [];
  try {
    while (fetching) {
      let authorizationHeaders = getAwsAuthorization(
        AccessKeyId,
        SecretKey,
        SessionToken,
        payload,
        URL
      );
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.statusCode === 200) {
        const threeMonthBills = data.data;
        allThreeMonthBills.push(...threeMonthBills);
        payload.LastEvaluatedKey = data.LastEvaluatedKey;
        if (!payload.LastEvaluatedKey) {
          fetching = false;
        }
      } else {
        allThreeMonthBills = [];
        fetching = false;
        toast.error("Error fetching all bills", {
          theme: "colored",
        });
      }
    }
    dispatch(hideBillLoading());
    dispatch({
      type: "SHOW_ALL_BILLS",
      payload: allThreeMonthBills,
    });
  } catch (err) {
    dispatch(hideBillLoading());

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const getAllCustomers = () => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSgetallcustomers";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  let payload = {
    company: "SSS",
    LastEvaluatedKey: null,
  };
  let fetching = true;
  let allCustomers = [];
  try {
    while (fetching) {
      dispatch(showBillLoading());
      let authorizationHeaders = getAwsAuthorization(
        AccessKeyId,
        SecretKey,
        SessionToken,
        payload,
        URL
      );
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.statusCode === 200) {
        const customers = data.data;
        allCustomers.push(...customers);
        payload.LastEvaluatedKey = data.LastEvaluatedKey;
        if (!payload.LastEvaluatedKey) {
          fetching = false;
        }
      } else {
        allCustomers = [];
        fetching = false;

        toast.error("Error fetching customers data", {
          theme: "colored",
        });
      }
    }
    dispatch(hideBillLoading());
    dispatch({
      type: "SHOW_ALL_CUSTOMERS",
      payload: allCustomers,
    });
  } catch (err) {
    dispatch(hideBillLoading());

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const addCustomer = (person) => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSaddnewcustomer";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const { storeId } = state.auth.action.data;
  const payload = person;
  payload.stores = [storeId];
  const authorizationHeaders = getAwsAuthorization(
    AccessKeyId,
    SecretKey,
    SessionToken,
    payload,
    URL
  );
  dispatch(showBillLoading());
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        ...authorizationHeaders,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    dispatch(hideBillLoading());
    if (data.statusCode === 200) {
      dispatch({
        type: "SET_CUSTOMER",
        payload: data.data,
      });
      toast("customer saved successfully");
    } else {
      if (data.statusCode === 400)
        toast.error("Customer already exists", {
          theme: "colored",
        });
      else {
        toast.error("Error saving customer details", {
          theme: "colored",
        });
      }
      dispatch({
        type: "SET_CUSTOMER",
        payload: null,
      });
    }
  } catch (err) {
    dispatch(hideBillLoading());
    dispatch({
      type: "SET_CUSTOMER",
      payload: null,
    });

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

//not done
export const getAllCoupons = () => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/getallcoupons";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const payload = {
    company: "SSS",
  };
  const authorizationHeaders = getAwsAuthorization(
    AccessKeyId,
    SecretKey,
    SessionToken,
    payload,
    URL
  );
  dispatch(showBillLoading());
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        ...authorizationHeaders,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    dispatch(hideBillLoading());
    if (data.statusCode === 200) {
      dispatch({
        type: "SHOW_COUPONS",
        payload: data.data,
      });
    } else {
      toast.error("Error fetching coupons", {
        theme: "colored",
      });
    }
  } catch (err) {
    dispatch(hideBillLoading());

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const addBilledProducts = (product) => {
  return (dispatch, getState) => {
    const state = getState();
    const billedProducts = state.bills.billing.billedProducts;
    const { discountCategories } = state.inventory;

    if (product.stock < 1) {
      // Create a toast with warning
      dispatch({
        type: "SET_CURRENT_PRODUCT",
        payload: null,
      });
      return;
    }

    dispatch({
      type: "SET_CURRENT_PRODUCT",
      payload: product,
    });
    const repeat =
      billedProducts.filter((p) => p.barcode === product.barcode).length > 0;
    let newBill = [];
    if (repeat) {
      newBill = billedProducts.map((p) => {
        if (p.barcode === product.barcode) {
          const total = parseFloat(p.total) + parseFloat(product.total);
          p.total = formatCurrency(total);
          p.count = p.count + 1;
          return { ...p };
        } else {
          return p;
        }
      });
    } else {
      newBill = [...billedProducts, product];
    }

    // Calculate discount if combo
    const products = calculateCombo(newBill, discountCategories);
    dispatch({
      type: "SET_BILLED_PRODUCTS",
      payload: products,
    });
  };
};

export const changeLastCode = (barcode) => {
  return {
    type: "SET_LAST_CODE",
    payload: barcode,
  };
};

export const searchProduct = (barcode) => {
  return (dispatch, getState) => {
    dispatch(changeLastCode(barcode));
    const state = getState();
    const { inventory } = state.inventory;
    const selectedProduct = inventory.filter((p) => p.barcode === barcode)[0];

    if (!!selectedProduct) {
      selectedProduct.count = 1;
      selectedProduct.comboDiscounted = false;
      selectedProduct.discountAmt = formatCurrency(0);
      selectedProduct.total = selectedProduct.mrp;
      selectedProduct.addedAt = Date.now();
      dispatch(addBilledProducts(selectedProduct));
    }
    dispatch({
      type: "SET_REPEAT_COUNT",
      payload: Math.random(),
    });
  };
};

export const searchCustomer = (mobileNo) => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSsearchcustomerbyphonenumber";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const payload = {
    mobileNo: mobileNo,
  };
  const authorizationHeaders = getAwsAuthorization(
    AccessKeyId,
    SecretKey,
    SessionToken,
    payload,
    URL
  );
  dispatch(showBillLoading());
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        ...authorizationHeaders,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    dispatch(hideBillLoading());
    if (data.statusCode === 200) {
      dispatch({
        type: "SET_CUSTOMER",
        payload: data.data,
      });
    } else {
      dispatch({
        type: "SET_CUSTOMER",
        payload: "",
      });
      toast.error("Error searching customer", {
        theme: "colored",
      });
    }
  } catch (err) {
    dispatch(hideBillLoading());
    dispatch({
      type: "SET_CUSTOMER",
      payload: null,
    });

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const changeLastBill = (text) => {
  return {
    type: "SET_LAST_BILL",
    payload: text,
  };
};

export const changeBagPrice = (price) => {
  return {
    type: "SET_BAG_PRICE",
    payload: price,
  };
};

export const changeSuggestions = (text) => {
  return {
    type: "SET_SUGGESTIONS",
    payload: text,
  };
};

export const changeAppliedCoupon = (coupon) => {
  return {
    type: "SET_APPLIED_COUPON",
    payload: coupon,
  };
};

export const searchByNameId = (text) => {
  return (dispatch, getStore) => {
    const store = getStore();
    const { inventory } = store.inventory;
    const likeProducts = inventory.filter(
      (p) =>
        (p.name.toUpperCase().match(text.toUpperCase()) ||
          p.productId.toString().match(text.toUpperCase())) &&
        p.stock > 0
    );
    dispatch(changeSuggestions(likeProducts.slice(0, 10)));
  };
};

export const calculateAll = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { billedProducts, appliedCoupon, bagPrice } = state.bills.billing;
    // Get the total
    const total = billedProducts.reduce((sum, current) => {
      return sum + parseFloat(current.total);
    }, 0);

    let couponDiscount = 0;
    if (appliedCoupon) {
      if (appliedCoupon.type === "percentage") {
        const nonComboProducts = billedProducts.filter(
          (p) => p.comboDiscounted === false
        );
        const nonCT = nonComboProducts.reduce(
          (sum, current) => sum + parseFloat(current.total),
          0
        );
        couponDiscount = parseFloat(nonCT) * (appliedCoupon.value / 100);
        dispatch({
          type: "SET_NON_COMBO_TOTAL",
          payload: nonCT,
        });
      } else if (appliedCoupon.type === "amount") {
        couponDiscount = appliedCoupon.value;
      }
    }
    dispatch({
      type: "SET_GRAND_TOTAL",
      payload: formatCurrency(Math.floor(total - couponDiscount) + bagPrice),
    });
    dispatch({
      type: "SET_COUPON_DISC",
      payload: couponDiscount,
    });

    // Get total quantity
    const qnty = billedProducts.reduce((sum, current) => {
      return sum + parseFloat(current.count);
    }, 0);

    dispatch({
      type: "SET_QNTY_COUNT",
      payload: qnty,
    });

    // Total Discount
    const disc = billedProducts.reduce((sum, current) => {
      return sum + parseFloat(current.discountAmt);
    }, 0);

    dispatch({
      type: "SET_GRAND_TOTAL_DISCOUNT",
      payload: formatCurrency(disc + couponDiscount),
    });
  };
};

export const calculateGST = (total, products) => {
  return (dispatch, getState) => {
    // Separately Calculate GST for clothing and footwear
    const footwearTotal = products
      .filter((p) => p.type === "footwear")
      .reduce((sum, i) => sum + parseFloat(i.total), 0);
    const bagAccessoryTotal = products
      .filter((p) => p.categories && p.categories[0] === "110")
      .reduce((sum, i) => sum + parseFloat(i.total), 0);
    const restTotal = products
      .filter((p) => p.type !== "footwear")
      .filter((p) => !(p.categories && p.categories[0] === "110"))
      .reduce((sum, i) => sum + parseFloat(i.total), 0);
    const productTotal = products.reduce(
      (sum, i) => sum + parseFloat(i.total),
      0
    );
    const bagPrice = parseFloat(total) - productTotal;
    const price =
      footwearTotal / FOOTWEAR_GST_RATE +
      bagAccessoryTotal / BAG_GST_RATE +
      restTotal / REST_GST_RATE +
      bagPrice / BAG_GST_RATE;
    const gst = parseFloat(total) - price;
    dispatch({
      type: "SET_COST",
      payload: formatCurrency(price),
    });
    dispatch({
      type: "SET_GST",
      payload: formatCurrency(gst),
    });
  };
};

export const deleteBilledProducts = (p) => {
  return (dispatch, getState) => {
    const state = getState();
    const { billedProducts } = state.bills.billing;
    const { discountCategories } = state.inventory;
    const newList = billedProducts.filter((item) => item.barcode !== p.barcode);

    // Calculate discount if combo
    const products = calculateCombo(newList, discountCategories);
    dispatch({
      type: "SET_BILLED_PRODUCTS",
      payload: products,
    });
  };
};

export const editProductCount = (p, count) => {
  return (dispatch, getState) => {
    if (p.stock < count) {
      // Create warning Toast
      return;
    }
    const state = getState();
    const { billedProducts } = state.bills.billing;
    const { discountCategories } = state.inventory;

    const newList = billedProducts.map((item) => {
      if (item.barcode === p.barcode) {
        const total = formatCurrency(
          (parseFloat(item.total) / item.count) * count
        );
        return {
          ...item,
          count: count,
          total: total,
        };
      }
      return item;
    });

    // Calculate discount if combo
    const products = calculateCombo(newList, discountCategories);
    dispatch({
      type: "SET_BILLED_PRODUCTS",
      payload: products,
    });
  };
};
export const printBill = (status) => {
  return {
    type: "PRINT_BILL",
    payload: status,
  };
};

export const warmupBillLambda = () => async (dispatch, getState) => {
  console.log("WARMING...");
  const state = getState();
  const payload = {};

  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSbillingtoS3bucket";
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const authorizationHeaders = getAwsAuthorization(
    AccessKeyId,
    SecretKey,
    SessionToken,
    payload,
    URL
  );

  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        ...authorizationHeaders,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    console.log("WarmUp", data);
  } catch (err) {
    console.error(err);
  }
};

export const refreshSession = () => async (dispatch, getState) => {
  console.log("Refreshing...");
  const state = getState();
  const auth = state.auth;
  console.log("auth", auth);
  const { Expiration } = auth.action.credentials;
  const { token } = auth.action;
  const currentTime = new Date();
  console.log("expiration", Expiration);
  if (currentTime < Expiration) return;
  try {
    const data = await getId(token);
    dispatch({
      type: "REFRESH_CREDENTIALS",
      ...auth.action,
      credentials: data.data,
    });
  } catch (err) {
    console.error(err);
  }
};

export const submitBill = (billdetails, shouldPrint) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { storeId, store } = state.auth.action.data;
    const { monthBills, allBills, monthBillEmpty, todayBillEmpty, todayBills } =
      state.bills;
    const {
      billedProducts,
      bagPrice,
      grandTotal,
      grandTotalDiscount,
      cost,
      gst,
      appliedCoupon,
      customer,
    } = state.bills.billing;
    const { discountCategories, inventory } = state.inventory;

    const payload = {
      store: {
        gst: store["storeGST"],
        address: store["storeAddress"],
      },
      storeId,
      products: billedProducts.map((p) => {
        let comboUsed = "None";
        if (p.comboDiscounted) {
          comboUsed = discountCategories.filter(
            (c) => c.categoryId === p.comboCategories[0]
          )[0].name;
        }
        return {
          id: p.productId,
          barcode: p.barcode,
          name: p.name,
          count: p.count,
          size: p.size,
          type: p.type,
          categories: p.categories,
          vendor: p.vendor,
          comboUsed,
          mrp: p.mrp,
          discountAmt: p.discountAmt,
          total: p.total,
        };
      }),
      bags:
        bagPrice === 0
          ? { quantity: 0, price: "0.00" }
          : { quantity: bagPrice / 10, price: formatCurrency(bagPrice) },
      grandTotal,
      grandTotalDiscount,
      cost,
      gst,
      paymentMethod: {
        cash: formatCurrency(billdetails.cash),
        card: formatCurrency(billdetails.card),
        cheque: formatCurrency(billdetails.cheque),
        wallet: formatCurrency(billdetails.wallet),
        creditNote: formatCurrency(billdetails.credit),
        unpaid: formatCurrency(billdetails.unpaid),
      },
      coupon: appliedCoupon,
      mobileNo: customer ? customer.mobileNo : null,
      noteToCustomer: billdetails.note,
      internalNote: billdetails.internalUse,
    };
    const URL =
      "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSbillingtoS3bucket";
    const { AccessKeyId, SecretKey, SessionToken } =
      state.auth.action.credentials;
    const authorizationHeaders = getAwsAuthorization(
      AccessKeyId,
      SecretKey,
      SessionToken,
      payload,
      URL
    );
    dispatch(showBillLoading());
    try {
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      dispatch(hideBillLoading());
      if (data.statusCode === 200) {
        toast("Bill saved successfully");
        dispatch({
          type: "SET_LAST_BILL",
          payload: data.data.bill,
        });
        dispatch({
          type: "SET_CUSTOMER",
          payload: null,
        });
        const updatedMonthBills = monthBills;
        const updatedTodayBills = todayBills;
        if (monthBills.length > 0 || monthBillEmpty) {
          updatedMonthBills.push(data.data.bill);

          dispatch({
            type: "SHOW_MONTH_BILLS",
            payload: updatedMonthBills,
          });
          if (todayBills.length > 0 || todayBillEmpty) {
            updatedTodayBills.push(data.data.bill);
            dispatch({
              type: "SHOW_TODAY_BILLS",
              payload: updatedTodayBills,
            });
          }
          const updatedAllBills = allBills;
          if (allBills.length > 0) {
            updatedAllBills.push(data.data.bill);
            dispatch({
              type: "SHOW_ALL_BILLS",
              payload: updatedAllBills,
            });
          }
        }
        const updatedProducts = inventory.map((p) => {
          const uProduct = data.data.products.filter(
            (item) => item.barcode === p.barcode
          )[0];
          if (uProduct) {
            return {
              ...p,
              stock: parseInt(uProduct.stock),
            };
          } else {
            return p;
          }
        });
        dispatch({
          type: "SHOW_INVENTORY",
          payload: updatedProducts,
        });
        if (shouldPrint) dispatch(printBill(true));
        dispatch({
          type: "CLEAR_BILLING",
        });
      } else {
        toast.error("Error saving bill", {
          theme: "colored",
        });
      }
    } catch (err) {
      dispatch(hideBillLoading());

      toast.error("Something Went Wrong With Connecting To Database", {
        theme: "colored",
      });
    }
  };
};

export const adminGetBills = (storeId) => async (dispatch, getState) => {
  const URL =
    "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSadmingetmonthlybills";
  const state = getState();
  const { AccessKeyId, SecretKey, SessionToken } =
    state.auth.action.credentials;
  const payload = {
    store_id: storeId,
  };
  const authorizationHeaders = getAwsAuthorization(
    AccessKeyId,
    SecretKey,
    SessionToken,
    payload,
    URL
  );
  dispatch(showBillLoading());
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        ...authorizationHeaders,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    dispatch(hideBillLoading());
    if (data.statusCode === 200) {
      dispatch({
        type: `SHOW_BILLS_STORE_${storeId}`,
        payload: data.data,
      });
    } else {
      toast.error("Error fetching bills", {
        theme: "colored",
      });
    }
  } catch (err) {
    dispatch(hideBillLoading());

    toast.error("Something Went Wrong With Connecting To Database", {
      theme: "colored",
    });
  }
};

export const adminGetBill =
  (storeId, barcode) => async (dispatch, getState) => {
    const URL =
      "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSadmingetsinglebill";
    const state = getState();
    const { AccessKeyId, SecretKey, SessionToken } =
      state.auth.action.credentials;
    const payload = {
      store_id: storeId,
      barcode,
    };
    const authorizationHeaders = getAwsAuthorization(
      AccessKeyId,
      SecretKey,
      SessionToken,
      payload,
      URL
    );
    dispatch(showBillLoading());
    try {
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      dispatch(hideBillLoading());
      if (data.statusCode === 200) {
        if (!data.data) {
          toast("Bill Doesnt Exist");
        } else if (data.data.markedCancelled) {
          toast("Bill Doesnt Exist");
        } else {
          dispatch({
            type: `SHOW_SINGLE_BILL`,
            payload: data.data,
          });
        }
      } else {
        toast.error("Error fetching bill", {
          theme: "colored",
        });
      }
    } catch (err) {
      dispatch(hideBillLoading());

      toast.error("Something Went Wrong With Connecting To Database", {
        theme: "colored",
      });
    }
  };

//not done
export const adminUpdateBill =
  (storeId, barcode, productsArray) => async (dispatch, getState) => {
    const URL =
      "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSdeleteduplicatebills";
    const state = getState();
    const { AccessKeyId, SecretKey, SessionToken } =
      state.auth.action.credentials;
    const { inventory } = state.inventory;
    const { monthBills, todayBills, allBills } = state.bills;

    const payload = {
      storeId,
      barcode,
      products: inventory
        .filter(
          (row) =>
            !!productsArray.find(
              (i) => i.id === row.productId && i.size === row.size
            )
        )
        .map((product) => {
          return {
            barcode: product.barcode,
            count: productsArray.find(
              (i) => i.id === product.productId && i.size === product.size
            ).count,
          };
        }),
    };

    const authorizationHeaders = getAwsAuthorization(
      AccessKeyId,
      SecretKey,
      SessionToken,
      payload,
      URL
    );
    dispatch(showBillLoading());
    try {
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      dispatch(hideBillLoading());
      if (data.statusCode === 200) {
        const updatedProducts = data.products;
        const updatedInventory = inventory.map((item) => {
          const found = updatedProducts.find((p) => p.barcode === item.barcode);
          if (!!found) {
            item.stock = found.stock;
          }
          return item;
        });
        const updatedBill = data.bill;
        const updatedMonthBills = monthBills.filter(
          (b) => b.barcode !== updatedBill.barcode
        );
        const updatedTodayBills = todayBills.filter(
          (b) => b.barcode !== updatedBill.barcode
        );
        const updatedAllBills = allBills.filter(
          (b) => b.barcode !== updatedBill.barcode
        );
        dispatch({
          type: "SHOW_MONTH_BILLS",
          payload: updatedMonthBills,
        });
        dispatch({
          type: "SHOW_TODAY_BILLS",
          payload: updatedTodayBills,
        });
        dispatch({
          type: "SHOW_ALL_BILLS",
          payload: updatedAllBills,
        });
        dispatch({
          type: "SHOW_INVENTORY",
          payload: updatedInventory,
        });
        dispatch({
          type: `SHOW_SINGLE_BILL`,
          payload: {},
        });
        toast("bill deleted successfully");
      } else {
        toast.error("Error Deleting Bill", {
          theme: "colored",
        });
      }
    } catch (err) {
      dispatch(hideBillLoading());

      toast.error("Something Went Wrong With Connecting To Database", {
        theme: "colored",
      });
    }
  };

// not done
export const adminGetCustomerInfoByDate =
  (date, storeId) => async (dispatch, getState) => {
    const URL =
      "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSadmingetcustomerinfobymonth";
    const state = getState();
    const { AccessKeyId, SecretKey, SessionToken } =
      state.auth.action.credentials;
    const payload = {
      date,
      storeId,
    };

    const authorizationHeaders = getAwsAuthorization(
      AccessKeyId,
      SecretKey,
      SessionToken,
      payload,
      URL
    );
    dispatch(showBillLoading());
    try {
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      dispatch(hideBillLoading());

      if (data.statusCode === 200) {
        dispatch({
          type: "SHOW_CUSTOMERS_FROM_BILL",
          payload: data.data,
        });
      } else {
        toast.error("Error fetching customers", {
          theme: "colored",
        });
      }
    } catch (err) {
      dispatch(hideBillLoading());

      toast.error("Something Went Wrong With Connecting To Database", {
        theme: "colored",
      });
    }
  };

export const adminGetAllCustomerByStore =
  (storeId) => async (dispatch, getState) => {
    const URL =
      "https://6yl581hi40.execute-api.ap-south-1.amazonaws.com/beta/SSSadmingetallcustomersfrombillbystore";
    const state = getState();
    const { AccessKeyId, SecretKey, SessionToken } =
      state.auth.action.credentials;
    const payload = {
      storeId,
    };

    const authorizationHeaders = getAwsAuthorization(
      AccessKeyId,
      SecretKey,
      SessionToken,
      payload,
      URL
    );
    dispatch(showBillLoading());
    try {
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...authorizationHeaders,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      dispatch(hideBillLoading());

      if (data.statusCode === 200) {
        dispatch({
          type: "SHOW_CUSTOMERS_FROM_BILL",
          payload: data.data,
        });
      } else {
        toast.error("Error fetching customers", {
          theme: "colored",
        });
      }
    } catch (err) {
      dispatch(hideBillLoading());

      toast.error("Something Went Wrong With Connecting To Database", {
        theme: "colored",
      });
    }
  };
