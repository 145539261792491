import { useSelector, useDispatch } from "react-redux";
import { FaPowerOff, FaUserTie } from "react-icons/fa";
import styled from "styled-components";
import NavItem from "./navigation/NavItem";
import Search from "./Search";
import { logOut } from "../actions/authActions";

const RightHeaderList = () => {
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch()

  const name = auth ? auth.action.data.name : "";
  return (
    <div className="rightMenu">
      <Search />
      <IconItem>
        <FaUserTie />
        <NavItem title={name} />
      </IconItem>
      <IconItem onClick={() => dispatch(logOut())}>
        <FaPowerOff />
        <NavItem title="Logout" />
      </IconItem>
    </div>
  );
};

const IconItem = styled.div`
  height: 100%;
  color: #666;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    background: #008fb3;
    color: #eee;
  }
`;

export default RightHeaderList;
