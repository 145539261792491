export const types = [
    {
        value: "",
        label: "None",
    },
    {
        value: "staff",
        label: "Staff Welfare & Tea",
    },
    {
        value: "stationary",
        label: "Stationary & Promotion",
    },
    {
        value: "housekeeping",
        label: "House Keeping",
    },
    {
        value: "transport",
        label: "Transport & Loading",
    },
    {
        value: "wifi",
        label: "Wifi Bill",
    },
    {
        value: "electricity",
        label: "Electricity Bill",
    },
    {
        value: "maintanance",
        label: "Maintanance & IT Equipment",
    },
    {
        value: "marketing",
        label: "Marketing Expenses",
    },
    {
        value: "deposit",
        label: "Bank Deposit",
    }
];