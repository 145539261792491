import { useState, useEffect } from "react";
import ReactExport from "react-data-export";
import { Button } from "@mui/material";
import { FaFileExcel } from "react-icons/fa";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportDSR = ({ date, tableData, cashReport, bills, productList, categoryList }) => {
    const [categoryWiseData, setcategoryWiseData] = useState([])
    const [dataSet2, setDataSet2] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => mapBilledProductsToCategories(), [bills, productList])

    const mapBilledProductsToCategories = () => {
        const catwiseTable = []
        bills.forEach(bill => {
            const uncomboProducts = bill.products.filter(p => p.discountAmt === "0.00")
            const uncomboAmt = uncomboProducts.reduce((sum, p) => sum + parseFloat(p.total), 0)
            const couponDiscount = bill.coupon ? (
                bill.coupon.type === 'amount' ?
                    bill.coupon.value :
                    bill.coupon.value * 0.01 * uncomboAmt
            ) : 0

            // Divide coupon discount among uncombo products
            uncomboProducts.forEach(p => {
                const ratio = parseFloat(p.total) / uncomboAmt
                p.cDiscount = ratio * couponDiscount
            })

            const footwear = []
            const clothing = []
            const imported = []
            const accessories = []
            bill.products?.forEach(i => {
                const uncombo = uncomboProducts.filter(u => u._id === i._id)
                const adjustedDiscount = uncombo.length > 0 ? uncombo[0].cDiscount : 0
                const p = productList?.filter(pro => pro._id === i._id)[0]
                const category = categoryList?.filter(cat => cat.categoryId === p?.categories[0])[0] // TO be used later
                switch (p?.type) {
                    case "footwear":
                        footwear.push({
                            count: i.count,
                            mrp: parseFloat(i.mrp),
                            discount: parseFloat(i.discountAmt),
                            total: parseFloat(i.total) - adjustedDiscount
                        })
                        break;
                    case "clothing":
                        clothing.push({
                            count: i.count,
                            mrp: parseFloat(i.mrp),
                            discount: parseFloat(i.discountAmt),
                            total: parseFloat(i.total) - adjustedDiscount
                        })
                        break;
                    case "imported":
                        imported.push({
                            count: i.count,
                            mrp: parseFloat(i.mrp),
                            discount: parseFloat(i.discountAmt),
                            total: parseFloat(i.total) - adjustedDiscount
                        })
                        break;
                    case "accessories":
                        accessories.push({
                            count: i.count,
                            mrp: parseFloat(i.mrp),
                            discount: parseFloat(i.discountAmt),
                            total: parseFloat(i.total) - adjustedDiscount
                        })
                        break;
                    default:
                        break;
                }
            })

            const row = {
                billNo: bill.barcode,
                footwearCount: footwear.reduce((sum, i) => sum + i.count, 0),
                footwearAmount: footwear.reduce((sum, i) => sum + i.total, 0),
                clothingCount: clothing.reduce((sum, i) => sum + i.count, 0),
                clothingAmount: clothing.reduce((sum, i) => sum + i.total, 0),
                importedCount: imported.reduce((sum, i) => sum + i.count, 0),
                importedAmount: imported.reduce((sum, i) => sum + i.total, 0),
                accessoriesCount: accessories.reduce((sum, i) => sum + i.count, 0),
                accessoriesAmount: accessories.reduce((sum, i) => sum + i.total, 0),
                couponDiscount
            }
            catwiseTable.push(row)
        })
        const dataSet = [{
            totalQnty: tableData.reduce((sum, i) => sum + i.items, 0),
            footwearCount: catwiseTable.reduce((sum, i) => sum + i.footwearCount, 0),
            footwearAmount: catwiseTable.reduce((sum, i) => sum + i.footwearAmount, 0),
            clothingCount: catwiseTable.reduce((sum, i) => sum + i.clothingCount, 0),
            clothingAmount: catwiseTable.reduce((sum, i) => sum + i.clothingAmount, 0),
            importedCount: catwiseTable.reduce((sum, i) => sum + i.importedCount, 0),
            importedAmount: catwiseTable.reduce((sum, i) => sum + i.importedAmount, 0),
            accessoriesCount: catwiseTable.reduce((sum, i) => sum + i.accessoriesCount, 0),
            accessoriesAmount: catwiseTable.reduce((sum, i) => sum + i.accessoriesAmount, 0),
            couponDiscount: catwiseTable.reduce((sum, i) => sum + i.couponDiscount, 0),
            numberOfBills: tableData.length
        }]
        setcategoryWiseData(catwiseTable)
        setDataSet2(dataSet)
        setLoading(false)
    }

    const dataSet1 = tableData.map(i => ({
        billNo: i.barcode,
        totalItems: i.items,
        coupon: i.coupon || '',
        discount: i.discount,
        cash: i.cash,
        card: i.card,
        UPI: i.UPI,
        total: i.total
    }))

    const dataSet3 = [{
        cash: bills.reduce((sum, i) => {
            let c = parseFloat(i.paymentMethod?.cash)
            if (Number.isNaN(c)) {
                c = 0
            }
            return sum + parseFloat(c)
        }, 0),
        card: bills.reduce((sum, i) => {
            let c = parseFloat(i.paymentMethod?.card)
            if (Number.isNaN(c)) {
                c = 0
            }
            return sum + parseFloat(c)
        }, 0),
        cheque: bills.reduce((sum, i) => {
            let c = parseFloat(i.paymentMethod?.cheque)
            if (Number.isNaN(c)) {
                c = 0
            }
            return sum + parseFloat(c)
        }, 0),
        payTM: bills.reduce((sum, i) => {
            let c = parseFloat(i.paymentMethod?.wallet)
            if (Number.isNaN(c)) {
                c = 0
            }
            return sum + parseFloat(c)
        }, 0),
        creditNote: bills.reduce((sum, i) => {
            let c = parseFloat(i.paymentMethod?.creditNote)
            if (Number.isNaN(c)) {
                c = 0
            }
            return sum + parseFloat(c)
        }, 0),
        total: tableData.reduce((sum, i) => sum + parseFloat(i.total), 0)
    }]

    return (
        <div>
            {!loading && <ExcelFile
                filename={`DSR${new Date(date).toLocaleDateString()}`}
                element={
                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        startIcon={<FaFileExcel />}
                    >
                        EXPORT
                    </Button>}>
                <ExcelSheet data={dataSet1} name="Daily Sales Report">
                    {Object.keys(dataSet1[0]).map((item, index) => <ExcelColumn key={index} label={item} value={item} />)}
                </ExcelSheet>
                {categoryWiseData.length > 0 && <ExcelSheet data={categoryWiseData} name="Daily Category Wise Sales">
                    {Object.keys(categoryWiseData[0]).map((item, index) => <ExcelColumn key={index} label={item} value={item} />)}
                </ExcelSheet>}
                {dataSet2 && <ExcelSheet data={dataSet2} name="Total Sales By Category">
                    {Object.keys(dataSet2[0]).map((item, index) => <ExcelColumn key={index} label={item} value={item} />)}
                </ExcelSheet>}
                <ExcelSheet data={dataSet3} name="Daily Sales By Payment Method">
                    {Object.keys(dataSet3[0]).map((item, index) => <ExcelColumn key={index} label={item} value={item} />)}
                </ExcelSheet>
                <ExcelSheet data={cashReport} name="Daily Cash Report">
                    {Object.keys(cashReport[0]).map((item, index) => <ExcelColumn key={index} label={item} value={item} />)}
                </ExcelSheet>
            </ExcelFile>}
        </div>
    )
}

export default ExportDSR
