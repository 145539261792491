import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Calendar from "react-calendar";
import Layout from "../../Layout";
import Breadcrump from "../../Breadcrump";
import { Button } from "@mui/material";
import ExportCustomerInfo from "../../reports/ExportCustomerInfo";

import "react-calendar/dist/Calendar.css";
import RedirectLogin from "../../RedirectLogin";

const EXPORTCUSTOMER = () => {
    const auth = useSelector((state) => state.auth);

    const [selectedDate, setSelectedDate] = useState(new Date());

    const widgets = [];

    const [choice, setChoice] = useState("");

    return (
        <>
            {!!auth ? (
                <Layout title="export customer info | LITTLEBOX">
                    <Breadcrump
                        path="/Reports/Export Customer Info"
                        widgets={widgets}
                    />
                    <ButtonContainer>
                        <Button
                            onClick={() => setChoice("Export All")}
                            color="primary"
                            variant="contained"
                        >
                            Export All Customers
                        </Button>
                        <Button
                            onClick={() => setChoice("Export Monthly")}
                            color="primary"
                            variant="contained"
                        >
                            Export Montly Customer Info
                        </Button>
                    </ButtonContainer>
                    {choice === "Export Monthly" ? (
                        <>
                            <Heading>Select Month</Heading>
                            <Calendar
                                className="center calender-zoom"
                                onChange={setSelectedDate}
                                value={selectedDate}
                                view="year"
                                maxDate={new Date()}
                                minDate={new Date("2021-09-03")}
                                onClickMonth={setSelectedDate}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                    {auth.action.data.role === "administrator" && (
                        <ExportCustomerInfo
                            period={selectedDate}
                            choice={choice}
                        />
                    )}
                </Layout>
            ) : (
                <RedirectLogin />
            )}
        </>
    );
};

const Heading = styled.h1`
    margin: 20px 0;
    text-align: center;
`;

const ButtonContainer = styled.div`
    display: flex;
    max-width: 550px;
    margin: 50px auto;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export default EXPORTCUSTOMER;
