import { useState, useEffect } from "react";
import styled from "styled-components";
import { TextField, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { changeAppliedCoupon } from "../../actions/billsAction";

const CheckoutDiscount = () => {
  const { grandTotal, grandTotalDiscount, coupons, appliedCoupon } =
    useSelector((state) => state.bills.billing);
  const [couponCode, setCouponCode] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!appliedCoupon) setCouponCode("");
  }, [appliedCoupon]);

  const couponOptions = [
    { value: "", label: "NA" },
    ...coupons.map((c) => ({ value: c.name, label: c.name })),
  ];

  const handleCouponSubmit = (e) => {
    e.preventDefault();
    setCouponCode(e.target.value);
    const coupon = coupons.filter((c) => c.name === e.target.value)[0];
    let alertText = "";

    if (!coupon) {
      alertText = `Coupon Not Applicable`;
      dispatch(changeAppliedCoupon(null));
      toast(alertText);
      return;
    } else if (coupon.type === "amount" && coupon.value >= grandTotal) {
      alertText = `Coupon Amount exceeded. Please try a different code.`;
      dispatch(changeAppliedCoupon(null));
      toast(alertText);
      return;
    } else {
      alertText = `Coupon ${coupon.name} applied. ${coupon.type === "percentage" ? coupon.value + "%" : "₹" + coupon.value
        } discounted`;
      dispatch(changeAppliedCoupon(coupon));
    }
    toast(alertText);
    return;
  };

  return (
    <Container>
      <Column>
        <Title>Apply Coupon</Title>
        <TextField
          select
          label=""
          value={couponCode}
          onChange={handleCouponSubmit}
          variant="outlined"
          className="select"
          size="small"
          fullWidth
        >
          {couponOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Column>
      <Column>
        <Title>Total Discounts</Title>
        <TextField
          id="standard-read-only-input"
          value={`₹ ${grandTotalDiscount}`}
          variant="filled"
          size="small"
          InputProps={{
            readOnly: true,
          }}
          inputProps={{
            style: { paddingTop: "14px" },
          }}
        />
      </Column>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100;
  margin-bottom: 10px;
`;
const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 10px;
`;
const Title = styled.h4`
  color: #73c24c;
`;

export default CheckoutDiscount;
