import { useEffect } from "react";
import Layout from "../Layout";
import Breadcrump from "../Breadcrump";
import ReportSummaryV1 from "../dashboard/ReportSummaryV1";
import DetailedSummary from "../dashboard/DetailedSummary";
import ReportAdmin from "../dashboard/ReportAdmin";
import ReportFranchise from "../dashboard/ReportFranchise";
import { useSelector, useDispatch } from "react-redux";
import { loadUser } from "../../actions/authActions";
import LoginForm from "../forms/LoginForm";

function Home() {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!auth) dispatch(loadUser());
        // eslint-disable-next-line
    }, [dispatch]);
    console.log(auth);
    return (
        <>
            {!!auth ? (
                <Layout title="Dashboard | LITTLEBOX">
                    <Breadcrump />
                    {auth.action.data.role === "manager" ? (
                        <div className="content-container">
                            <ReportSummaryV1 />
                            <DetailedSummary />
                        </div>
                    ) : auth.action.data.role === "administrator" ? (
                        <>
                            <h1>Hello ADMIN</h1>
                            <ReportAdmin />
                        </>
                    ) : (
                        <ReportFranchise />
                    )}
                </Layout>
            ) : (
                <LoginForm />
            )}
        </>
    );
}

export default Home;
