import { useState, useEffect } from "react";
import styled from "styled-components";
import { TextField, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import { changeBagPrice } from "../../actions/billsAction";
import { useDispatch } from "react-redux";
const SINGLE_BAG_PRICE = 10;

const AddBag = () => {
  const [noOfBags, setNoOfBags] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    const price = noOfBags * SINGLE_BAG_PRICE;

    dispatch(changeBagPrice(price));
  }, [noOfBags]);

  const handleBagChange = (e) => {
    setNoOfBags(parseInt(e.target.value));
    const alertText = `${e.target.value} bag(s) added to bill.`;
    toast(alertText);
  };

  const bagOptions = [
    { value: 0, label: "NA" },
    { value: 1, label: "One" },
    { value: 2, label: "Two" },
    { value: 3, label: "Three" },
    { value: 4, label: "Four" },
    { value: 5, label: "Five" },
  ];

  return (
    <Container>
      <Column>
        <Title>Add Bags</Title>
      </Column>
      <Column>
        <TextField
          select
          label=""
          value={noOfBags}
          onChange={handleBagChange}
          variant="outlined"
          className="select"
          size="small"
          fullWidth
        >
          {bagOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Column>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100;
`;
const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0 10px;
`;
const Title = styled.h4`
  color: #73c24c;
`;

export default AddBag;
